import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { COLUMNS } from './columns'

const TabPendiente = ({ setTab, servicePendientes }) => {
  const { id } = useParams()

  const { obtenerPendientesPedido } = servicePendientes
  const [pendientes, setPendientes] = useState([])

  const handleObtenerPendientesPedido = useCallback(async () => {
    try {
      const response = await obtenerPendientesPedido(id)
      const data = response.data.result

      setPendientes(data)
    } catch (error) {
      setPendientes([])
      // Se encarga la función obtenerPendientesPedido
    }
  }, [obtenerPendientesPedido])

  useEffect(() => {
    handleObtenerPendientesPedido()
  }, [])

  useEffect(() => {
    setTab('pendiente')
  })

  useEffect(() => {
    console.log(pendientes)
  }, [pendientes])

  return (
    <TableContainer>
      <CustomTable columns={COLUMNS} data={pendientes} />
    </TableContainer>
  )
}

export default TabPendiente