export const nombreEvento = (pedido = false, event_type_name) => {
  switch (event_type_name) {
    case 'IN_REQUEST_NUEVO':
      return 'Creación'
    case 'IN_REQUEST_PENDIENTE_AUTORIZACION':
      return 'Pendiente de autorización'
    case 'IN_REQUEST_AUTORIZADO':
      return 'Autorizado'
    case 'IN_REQUEST_RECHAZADO':
      return 'Rechazado'
    case 'IN_REQUEST_CANCELADO':
      return 'Cancelado'
    case 'IN_ORDER_NUEVO':
      return 'Creación'
    case 'IN_ORDER_INGRESADA_STOCK':
      return 'Ingresada a stock'
    case 'IN_ORDER_CON_ERROR_TRAZA':
      return 'Error en la trazabilidad'
    case 'IN_ORDER_INGRESADA_STOCK_FORZOSAMENTE':
      return 'Ingresada a stock sin traza (forzosamente)'
    case 'SALES_ORDER_NUEVO':
      return 'Creación'
    case 'PENDIENTE_AUTORIZACION_RENTA':
      return 'En autorización de rentabilidad'
    case 'PENDIENTE_AUTORIZACION_CREDITO':
      return 'En autorización de crédito'
    case 'FALTA_STOCK':
      return 'Sin stock suficiente'
    case 'NO_AUTORIZADO':
      return 'No autorizado'
    case 'CANCELADO':
      return 'Cancelado'
    case 'PENDIENTE_PREPARACION':
      return 'Pendiente de preparación'
    case 'EN_PREPARACION':
      return 'En preparación'
    case 'PENDIENTE_DESPACHO':
      return 'Pendiente de despacho'
    case 'AFIP_ERROR':
      return 'Error AFIP'
    case 'TRAZA_ERROR':
      return 'Error en trazabilidad'
    case 'REMITO_CREADO':
      return 'Remito creado'
    case 'FACTURA_CREADA':
      return 'Factura creada'
    case 'EN_DESPACHO':
      return 'En despacho'
    case 'EN_DISTRIBUCION':
      return 'En distribución'
    case 'EN_REVISION':
      return 'En revisión'
    case 'COORDINACION_ENTREGA':
      return 'En coordinación de entrega'
    case 'ENTREGADA':
      return `Entregad${pedido ? 'a' : 'o'}`
    case 'NUEVA_MENCION':
      return 'Nueva mención'
    case 'RESERVA_PARCIAL':
      return 'Reserva parcial'
    default:
      return event_type_name
  }
}