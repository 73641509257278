import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'
import { AccountBoxOutlined, AssignmentOutlined, Inventory2Outlined, FileOpenOutlined, LocalShippingOutlined, ReceiptLongOutlined, PendingActionsOutlined, TextsmsOutlined } from '@mui/icons-material'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'
import { Tooltip } from 'components'

import { labelColor } from '../List'
import { Actions, GeneralTab, ArticlesTab, AddresseeTab, FilesTab, OutOrdersTab, TransactionalDocumentsTab, PendingsTab, CommentsTab } from './partials'
import { EventosModal } from 'pages/Sells/common/components'
import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'

import { OrderTypeI, SalesOrderStatusI } from 'interfaces'

const SalesOrderDetail = () => {
  const { id } = useParams()
  const { currentUser } = useAuth()
  const [title, setTitle] = useState("")

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const [eventosModal, setEventosModal] = useState(false)

  const { loading, handleGetSalesOrder, handleUpdateSalesOrder } = useSalesOrders()

  const [salesOrder, setSalesOrder] = useState(initialValues)
  const [editing, setEditing] = useState(false)

  const getSalesOrder = useCallback(async () => {
    try {
      const response = await handleGetSalesOrder(id, { getDetail: true }, 'token')
      const data = dataAdapter({ ...initialValues, ...response.data.result })

      let enrichedData = {
        ...data,
        subtotal : data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.subtotal), 0 ),
        iva : data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.vat_amount), 0 ),
        total_calc: data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.total), 0 ) + (data.iibb?.total || 0),
        total_profitability_rate: (1 - (data.detail.reduce( (acc, el) => parseFloat(acc) + (parseFloat(el.list_cost) * +el.qty), 0 ) /
          data.detail.reduce( (acc, el) => parseFloat(acc) + (parseFloat(el.price) * +el.qty), 0 ))) * 100,
      }

      /* Cambio Ciudad Autónoma de Buenos Aires por CABA para que no quede tan largo el texto*/
      enrichedData = {
        ...enrichedData,
        iibb: {
          ...enrichedData.iibb,
          percepciones:enrichedData.iibb.percepciones?.map(p => ({ ...p, provincia: p.provincia == 'Ciudad Autónoma de Buenos Aires' ? 'CABA' : p.provincia }))
        }
      }

      setSalesOrder(enrichedData)
      if(data.order_type_id==OrderTypeI.PEDIDO_VENTA){
        setTitle(`Pedido de venta #${id}`)
      }
      else if(data.order_type_id==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO){
        setTitle(`Pedido con almacenamiento propio #${id}`)
      }
      else if(data.order_type_id==OrderTypeI.PEDIDO_EGRESO){
        setTitle(`Pedido de egreso #${id}`)
      }

    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetSalesOrder])

  const handleUpdate = async (values) => {
    try {
      const data = updateDataAdapter({
        ...values,
        updated_by_user_id: currentUser?.id ? currentUser.id : currentUser?.user?.id,
      })

      if (values.order_type_id != OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO && (!data.address_id_traza || data.address_id_traza === '')) {
        toast.error("El domicilio de entrega es obligatorio 1")
        return
      }
      await handleUpdateSalesOrder(id, data, 'token')
      toast.success("Registro actualizado correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setEditing(false)
      getSalesOrder()
    }
  }

  useEffect(() => {
    getSalesOrder()
  }, [getSalesOrder])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <EventosModal show={eventosModal} onClose={() => setEventosModal(false)} pedido />

      <DetailContainer
        title={title}
        statusV2={salesOrder &&
          <>
            <span className={`badge ${labelColor(salesOrder?.sales_order_status_id)} justify-content-center fw-normal me-4`} style={{ width: "180px" }}>
              {salesOrder.sales_order_status_label}
            </span>

            {Boolean(salesOrder.cancelado_parcial) &&
              <Tooltip text='Pedido cancelado parcialmente'>
                <i className='bi bi-code-square text-danger' />
              </Tooltip>
            }

            <Tooltip text='Ver eventos'>
              <Button variant='primary' className='px-2 py-1' onClick={() => setEventosModal(true)}>
                <span className='bi bi-clock-history fs-3' />
              </Button>
            </Tooltip>

            <div className='mt-7'>
              <div className='mt-7'>
                <div className='mt-7'>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "100px" }}>Cliente:</td>
                        <td><span className='fw-bold ps-5'>{salesOrder?.customer_supplier_id ? `[${salesOrder?.customer_supplier_id}] ` : ''}{salesOrder?.customer_supplier_business_name}</span></td>
                      </tr>

                      {Boolean(salesOrder?.remito_para_terceros) && salesOrder?.id_tercero_para_remitir &&
                        <tr>
                          <td style={{ width: '100px' }}>Remitir a:</td>
                          <td><span className='fw-bold ps-5'>{salesOrder?.tercero_para_remitir}</span></td>
                        </tr>
                      }

                      <tr>
                        <td style={{ width: "100px" }}>F. autorización:</td>
                        <td><span className='fw-bold ps-5'>{salesOrder?.profitability_auth_sent_date ? moment(salesOrder?.profitability_auth_sent_date).format('DD/MM/YYYY HH:mm') : '-'}</span></td>
                      </tr>

                      <tr>
                        <td style={{ width: "100px" }}>Cond. de pago:</td>
                        <td><span className='fw-bold ps-5'>{salesOrder?.payment_condition_label}</span></td>
                      </tr>

                      {(salesOrder.sales_order_status_id == SalesOrderStatusI.CANCELADO || salesOrder.sales_order_status_id == SalesOrderStatusI.NO_AUTORIZADO) &&
                        <tr>
                          <td style={{ width: "100px" }}>Motivo:</td>
                          <td><span className='fw-bold ps-5'>{salesOrder?.revocation_notes}</span></td>
                        </tr>
                      }

                      <tr>
                        <td style={{ width: "100px" }}>Observaciones:</td>
                        <td><span className='fw-bold ps-5'>{salesOrder?.observations}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
        buttons={<Actions values={salesOrder} editing={editing} handleEditing={setEditing} submitRef={submitRef} cancelRef={cancelRef} handleGetSalesOrder={getSalesOrder} loading={loading} />}
      >
        <Formik
          initialValues={salesOrder}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              if (values.to_patient && (!values.patient_id || values.patient_id === '')) {
                toast.error("Debe completar paciente en solapa 'Destinatario'")
                return;
              }
              if ( values.order_type_id!=OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO &&
                (!values.address_id_ship || ! values.address_id_traza || values.address_id_ship === '' || values.address_id_traza === '')) {
                toast.error("Debe completar domicilios en solapa 'Destinatario'")
                return;
              }
              handleUpdate(values)
            } catch (error) {
              actions.handleReset()
            }
          }}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>

                {(loading || !salesOrder?.id) && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

                <TabsContainer
                  initialValue='general'
                  // handleChange={(newValue) => console.log(newValue, values)}
                  tabs={[
                    { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true,  disabled: loading },
                    // { value: 'articles', label: 'Artículos', icon: <Inventory2Outlined />, show: true,  disabled: loading, outdated_pvp: values?.detail.some(d => Boolean(d.outdated_pvp)) },
                    { value: 'articles', label: 'Artículos', icon: <Inventory2Outlined />, show: true,  disabled: loading },
                    { value: 'addressee', label: 'Destinatario', icon: <AccountBoxOutlined />, show: salesOrder?.order_type_id!=OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO,  disabled: loading },
                    { value: 'files', label: 'Documentos', icon: <FileOpenOutlined />, show: true,  disabled: loading },
                    { value: 'out-orders', label: 'Órdenes', icon: <LocalShippingOutlined />, show: true,  disabled: loading },
                    { value: 'transactional', label: 'Comprobantes', icon: <ReceiptLongOutlined />, show: true, disabled: loading },
                    { value: 'pending', label: 'Pendiente', icon: <PendingActionsOutlined />, show: salesOrder.sales_order_status_id == SalesOrderStatusI.RESERVA_PARCIAL || salesOrder.sales_order_status_id == SalesOrderStatusI.FALTA_STOCK, disabled: loading },
                    { value: 'comments', label: 'Comentarios', icon: <TextsmsOutlined />, show: true, disabled: loading },
                  ]}
                  tabsContent={[
                    <GeneralTab editing={editing} handleGetSalesOrder={getSalesOrder} />,
                    <ArticlesTab editing={editing} handleEditing={setEditing} handleGetSalesOrder={getSalesOrder} salesOrder={salesOrder} />,
                    <AddresseeTab editing={editing} handleGetSalesOrder={getSalesOrder} />,
                    <FilesTab editing={editing} handleEditing={setEditing} handleGetSalesOrder={getSalesOrder} />,
                    <OutOrdersTab handleGetSalesOrder={getSalesOrder} />,
                    <TransactionalDocumentsTab />,
                    <PendingsTab />,
                    <CommentsTab />,
                  ]}
                />

                {editing && (
                  <div className='d-flex align-items-center justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false);
                        handleReset();
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default SalesOrderDetail