import { Link } from 'react-router-dom'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const eventName = (event) => {
  switch (event) {
    case 'CANCELACION_INGRESO':
      return 'CANCELACIÓN INGRESO'

    case 'CANCELACION_NA':
      return 'CANCELACION NA'

    case 'CANCELACION_RESERVA':
      return 'CANCELACIÓN RESERVA'

    case 'CANCELACION_PICKING':
      return 'CANCELACIÓN PICKING'

    case 'CANCELACION_EGRESO':
      return 'CANCELACIÓN EGRESO'

    case 'EGRESO_CIPRES':
      return 'EGRESO CIPRES'

    case 'EGRESO_INTERNO':
      return 'EGRESO INTERNO'

    case 'INGRESO_INTERNO':
      return 'INGRESO INTERNO'

    case 'CAMBIO_LOTE':
      return 'CAMBIO LOTE'

    default:
      return event
  }
}

const eventColor = (event) => {
  switch (event) {
    case 'INGRESO':
    case 'INGRESO_INTERNO':
      return '#064E3B'

    case 'RESERVA':
      return '#FFD700'

    case 'CANCELACION':
    case 'CANCELACION_INGRESO':
    case 'CANCELACION_PICKING':
    case 'CANCELACION_NA':
    case 'CANCELACION_RESERVA':
    case 'CANCELACION_EGRESO':
      return '#B0B0B0'

    case 'PICKING':
      return '#60A5FA'

    case 'EGRESO':
    case 'EGRESO_INTERNO':
    case 'EGRESO_CIPRES':
      return '#1E3A8A'

    case 'NA':
      return '#E0E0E0'

    case 'DEVOLUCION':
      return '#4CAF50'

    case 'CAMBIO_LOTE':
      return '#FFA726'

    default:
      return ''
  }
}

const COLUMNS = [
  {
    Header: 'Evento',
    id: 'event_type',
    accessor: (row) => (
      <strong className='m-0 p-0' style={{ color: eventColor(row.event_type) }}>
        {eventName(row.event_type)}
      </strong>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Fecha</p>,
    id: 'created_at',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{moment(row?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
    ),
    hasOrder: true,
  },
  {
    Header: 'Descripción',
    id: 'descripcion',
    accessor: (row) => (
      <>
        {/* Bloque para INGRESO, INGRESO_INTERNO y CANCELACION_INGRESO */}
        {(row.event_type == 'INGRESO' || row.event_type == 'INGRESO_INTERNO' || row.event_type == 'CANCELACION_INGRESO') &&
          <p className="m-0 p-0">
            <strong>{row.event_type == 'CANCELACION_INGRESO' ? 'Cancelación de ingreso ' : 'Ingresó '}</strong>

            al depósito

            <strong> {row.storage_space_name} </strong>

            por {row.event_type == 'INGRESO'
              ?
                <>
                  <Link to={`${routes.LOGISTIC_INCOME}/${row?.in_order_id}`} state={{ in_request_id: row?.in_request_id }}>
                    Orden de ingreso #{row.in_order_id}
                  </Link> con el remito <strong>{row?.waybill_number}</strong>
                </>
              :
                <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.depot_movement_id}`}>
                  Mov. interno de stock #{row.depot_movement_id}
                </Link>
            }
          </p>
        }

        {/* Bloque para RESERVA y CANCELACION_RESERVA */}
        {(row.event_type == 'RESERVA' || row.event_type == 'CANCELACION_RESERVA') &&
          <p className="m-0 p-0">
            <strong>{row.event_type == 'RESERVA' ? 'Reserva' : 'Cancelación de reserva'}</strong>

            <> hecha por </>

            {row?.out_order_id && !row?.depot_movement_id
              ? <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>Orden de egreso #{row.out_order_id}</Link>
              : <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.depot_movement_id}`}>Mov. interno de stock #{row.depot_movement_id}</Link>
            }

            {row.event_type == 'CANCELACION_RESERVA' && <>. El stock volvió a estar disponible en el sistema.</>}
          </p>
        }

        {/* Bloque para PICKING y CANCELACION_PICKING */}
        {(row.event_type == 'PICKING' || row.event_type == 'CANCELACION_PICKING') &&
          <p className="m-0 p-0">
            <strong>{row.event_type == 'PICKING' ? 'Pickeo' : 'Cancelación de picking'}</strong>

            <> hecho por </>

            {row?.out_order_id && !row?.depot_movement_id
              ? <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>Orden de egreso #{row.out_order_id}</Link>
              : <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.depot_movement_id}`}>Mov. interno de stock #{row.depot_movement_id}</Link>
            }

            {row.event_type == 'CANCELACION_PICKING' && <>. El stock volvió a estar reservado por la misma.</>}
          </p>
        }

        {/* Bloque para EGRESO, EGRESO_INTERNO, EGRESO_CIPRES y CANCELACION_EGRESO */}
        {(row.event_type == 'EGRESO' || row.event_type == 'EGRESO_INTERNO' || row.event_type == 'EGRESO_CIPRES' || row.event_type == 'CANCELACION_EGRESO') &&
          <p className='m-0 p-0'>
            <strong>
              {row.event_type == 'EGRESO' || row.event_type == 'EGRESO_INTERNO' || row.event_type == 'EGRESO_CIPRES'
                ? 'Egresó'
                : 'Cancelación de egreso'
              }
            </strong>

            {(row.event_type == 'EGRESO_INTERNO' || row.event_type == 'EGRESO_CIPRES') && <> del depósito <strong>{row.storage_space_name}</strong></>}

            {(row.event_type == 'EGRESO' || row.event_type == 'EGRESO_INTERNO' || row.event_type == 'EGRESO_CIPRES') && <> por {row.event_type == 'EGRESO'  ? 'la' : 'el'} </>}
            {row.event_type == 'CANCELACION_EGRESO' && <> hecha en </>}

            {row?.out_order_id && !row?.depot_movement_id
              ? <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>Orden de egreso #{row.out_order_id}</Link>
              : <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.depot_movement_id}`}>Mov. interno de stock #{row.depot_movement_id}</Link>
            }

            {row.event_type == 'CANCELACION_EGRESO' && <>. El stock volvió a estar disponible en el sistema.</>}
          </p>
        }

        {/* Bloque para CANCELACION */}
        {row.event_type == 'CANCELACION' &&
          <p className='m-0 p-0'>
            <strong>{row?.out_order_id ? 'Cancelación de egreso' : 'Cancelación de ingreso'}</strong>

            {row?.out_order_id && <> hecha en <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>Orden de egreso #{row.out_order_id}</Link></>}
            {row?.in_order_id && <> hecha en <Link to={`${routes.LOGISTICS_ACTIVATION}/${row.in_order_id}`} state={{ in_request_id: row.in_request_id }}>Orden de ingreso #{row.in_order_id}</Link></>}
          </p>
        }

        {/* Bloque para NA y CANCELACION_NA */}
        {(row.event_type == 'NA' || row.event_type == 'CANCELACION_NA') &&
          <p className='m-0 p-0'>
            El stock pasó a <strong>{row.event_type == 'NA' ? 'No disponible' : 'Disponible'}</strong> en el depósito {row.storage_space_name}
          </p>
        }

        {/* Bloque para DEVOLUCION */}
        {row.event_type == 'DEVOLUCION' &&
          <p className='m-0 p-0'>
            El stock estaba

            <strong>
              {
                row?.a == -1
                  ? ' Disponible'
                  : row?.na == -1
                    ? ' No disponible'
                    : ' Sin definir'
              }
            </strong>

            y se <strong>Devolvió</strong>
          </p>
        }

        {/* Bloque para CAMBIO_LOTE */}
        {row.event_type == 'CAMBIO_LOTE' &&
          <p className='m-0 p-0'>
            Lote: {row?.lot_number || '-'} | Vencimiento: {row?.expiration_date ? moment(row.expiration_date).format('MM/YYYY') : '-'}
          </p>
        }
      </>
    )
  },
]

export { COLUMNS }