import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { InputBS, SelectField } from 'components'; 
import { useGetProductDetail, useDepots } from 'hooks/Inventory';
import moment from "moment";

const WarehouseForm = () => {
  const { setFieldValue, values } = useFormikContext();
  const { handleGetCustomerSupplier, handleGetDepotName, handleGetBusinessUnitName, handleGetWarehouseName, handleGetCoolTypeName } = useDepots();
  const { productFamilies, productMakers } = useGetProductDetail();
  const [customerSupplierOptions, setCustomerSupplierOptions] = useState([]);
  const [depotOptions, setDepotOptions] = useState([]);
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [coolTypeOptions, setCoolTypeOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomerSupplierOptions = async () => {
      try {
        const options = await handleGetCustomerSupplier();
        if (Array.isArray(options)) {
          const formattedOptions = options.map(option => ({
            label: option.business_name,
            value: option.business_name
          }));
          setCustomerSupplierOptions(formattedOptions);
          console.log("customerSupplierOptions", formattedOptions);
        } else {
          console.error('handleGetCustomerSupplier did not return an array');
        }
      } catch (error) {
        console.error('Error fetching customer supplier options:', error);
      }
    };

    const fetchDepotOptions = async () => {
      try {
        const options = await handleGetDepotName();
        if (Array.isArray(options)) {
          const formattedOptions = options.map(option => ({
            label: option.name,
            value: option.name
          }));
          setDepotOptions(formattedOptions);
          console.log("depotOptions", formattedOptions);
        } else {
          console.error('handleGetDepotName did not return an array');
        }
      } catch (error) {
        console.error('Error fetching depot options:', error);
      }
    };

    const fetchBusinessUnitOptions = async () => {
      try {
        const options = await handleGetBusinessUnitName();
        if (Array.isArray(options)) {
          const formattedOptions = options.map(option => ({
            label: option.name,
            value: option.name
          }));
          setBusinessUnitOptions(formattedOptions);
          console.log("businessUnitOptions", formattedOptions);
        } else {
          console.error('handleGetBusinessUnitName did not return an array');
        }
      } catch (error) {
        console.error('Error fetching business unit options:', error);
      }
    };

    const fetchWarehouseOptions = async () => {
      try {
        const options = await handleGetWarehouseName();
        if (Array.isArray(options)) {
          const formattedOptions = options.map(option => ({
            label: option.name,
            value: option.name
          }));
          setWarehouseOptions(formattedOptions);
          console.log("warehouseOptions", formattedOptions);
        } else {
          console.error('handleGetWarehouseName did not return an array');
        }
      } catch (error) {
        console.error('Error fetching warehouse options:', error);
      }
    };

    const fetchCoolTypeOptions = async () => {
      try {
        const options = await handleGetCoolTypeName();
        if (Array.isArray(options)) {
          const formattedOptions = options.map(option => ({
            label: option.name,
            value: option.name
          }));
          setCoolTypeOptions(formattedOptions);
          console.log("coolTypeOptions", formattedOptions);
        } else {
          console.error('handleGetCoolTypeName did not return an array');
        }
      } catch (error) {
        console.error('Error fetching cool type options:', error);
      }
    };

    const fetchData = async () => {
      await fetchCustomerSupplierOptions();
      await fetchDepotOptions();
      await fetchBusinessUnitOptions();
      await fetchWarehouseOptions();
      await fetchCoolTypeOptions();
      setLoading(false);
    };

    fetchData();
  }, [handleGetCustomerSupplier, handleGetDepotName, handleGetBusinessUnitName, handleGetWarehouseName, handleGetCoolTypeName]);

  useEffect(() => {
    if (values.product_type_id === undefined) setFieldValue('u_negocio', '');
    if (values.space === undefined) setFieldValue('space', '');
    if (values.owner === undefined) setFieldValue('owner', '');
    if (values.deposit === undefined) setFieldValue('deposit', '');
    if (values.warehouse === undefined) setFieldValue('warehouse', '');
    if (values.temperature === undefined) setFieldValue('temperature', '');
    if (values.ab_link === undefined) setFieldValue('ab_link', false);
  }, [setFieldValue, values]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="row mt-4">
        <div className='col-3'>
          <SelectField name="u_negocio" label="U. negocio" options={businessUnitOptions} marginTop='3px' />
        </div>
        <div className='col'>
          <InputBS id='space' name='space' label="Espacio" disabled={values.ab_link} />
        </div>
        <div className='col'>
          <SelectField label="Dueño" name="owner" options={customerSupplierOptions} marginTop='3px' />
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className='col'>
          <SelectField label="Depósito" name="deposit" options={depotOptions} marginTop='3px' />
        </div>
        <div className='col'>
          <SelectField label="Almacén" name="warehouse" options={warehouseOptions} marginTop='3px' />
        </div>
        <div className='col'>
          <SelectField label="Temperatura" name="temperature" options={coolTypeOptions} marginTop='3px' />
        </div>
      </div>
    </>
  );
};

export default WarehouseForm;