import * as Yup from 'yup'
import { currentDate } from 'utilities';

const formikInitialValues = {
  id:null,
  supplier_id: null,
  distributor_id: null,
  payment_condition_id: "",
  issue_date: "",
  income_date: "",
  estimated_in_date: '',
  notes: '',
  internal_notes:'',
  order_status_id:null,
  order_type_id:null,
  supplier_name:'',
  payment_condition_label:'',
  waybill_number:'',
  carrier:'',
  supplier_invoice:'',
  supplier_invoice_date:"",
  in_request_id:null,
  storage_space_id:null,
  // default_storage_space_id:undefined,
  shipment_address_id:null,
  detail:[],
  detail_grouped:[],
  request_items:[],
  addDetail:()=>{},
  deleteDetail:()=>{},
  deleteSerialDetail:()=>{},
  updateDetail:()=>{},
  updateLotDetail:()=>{},
  duplicateDetail:()=>{},
  refreshIncomeOrder:()=>{},
  waybill_prefix:'',
  waybill_sufix:'',
}

const schema = Yup.object().shape({
  supplier_name: Yup.string(),
  supplier_invoice: Yup.string(),
  supplier_invoice_date: Yup.string(),
  distributor_name: Yup.string(),
  issue_date: Yup.string(),
  waybill_number: Yup.string().required("Ingresar nro. Remito"),
  notes: Yup.string(),
  internal_notes: Yup.string()
});

export const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


const order_status = [
  { id: 1, name: 'GENERADO' },
  { id: 2, name: 'PENDIENTE_INGRESO' },
  { id: 3, name: 'INGRESO_PARCIAL' },  
  { id: 4, name: 'VENCIDO' }, 
  { id: 5, name: 'CANCELADO' }, 
  { id: 6, name: 'COMPLETO' }, 
  { id: 7, name: 'BORRADOR' }, 
  { id: 8, name: 'AUTORIZADO' }, 
  { id: 13, name: 'ERROR_TRAZA' },
];


export {
  formikInitialValues,
  schema,
  order_status
}