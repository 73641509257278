import { useCallback, useState } from 'react'
import { OutOrders } from 'services'

const useOutOrders = () => {
  const [loading, setLoading] = useState(false)

  const handleGetOutOrders = useCallback(async (params) => {
    try {
      setLoading(true)

      const response = await OutOrders.getOutOrders(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetOutOrder = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await OutOrders.getOutOrder(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetOutStockStatus = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await OutOrders.getOutOrderStockStatus(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetOutOrderStatus = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.getOutOrderStatus(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateOutOrder = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await OutOrders.updateOutOrder(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateOutOrderCoordination = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await OutOrders.updateOutOrderCoordination(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateOutOrderStatus = useCallback(async (id, status_id) => {
    try {
      setLoading(true)
      const response = await OutOrders.updateOutOrderStatus(id, status_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDetail = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await OutOrders.addDetail(id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handleLotDetail = useCallback(async (id, data) => {
    try {
      setLoading(true)
      console.log(id, data)
      const response = await OutOrders.lotDetail(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])


  const handleUpdateDetail = useCallback(async (id, detail_id, data, token) => {
    try {
      setLoading(true)
      const response = await OutOrders.updateDetail(id, detail_id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDetail = useCallback(async (id, detail_id, token) => {
    try {
      setLoading(true)
      const response = await OutOrders.deleteDetail(id, detail_id, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleSendWaybill = useCallback(async (id, params) => {
    try {
      setLoading(true);
      const response = await OutOrders.sendWaybill(id, params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRegenerateWaybill = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await OutOrders.regenerateWaybill(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetTalonarios = useCallback(async () => {
    try {
      setLoading(true)
      const response = await OutOrders.getTalonarios()

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handlePrintWaybill = useCallback(async (id, copias) => {
    try {
      setLoading(true);
      const response = await OutOrders.printWaybill(id, copias);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePrintRemitoFa = useCallback(async (data, copias) => {
    try {
      setLoading(true);
      const response = await OutOrders.printRemitoFa(data, copias);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  
  const handlePrintTraza = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await OutOrders.printTraza(id);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetOutOrderSimulation = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.outOrderSimulation(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleOutOrderGenerateInvoice = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.outOrderGenerateInvoice(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  const handleMarkOrderAsReady = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.markOrderAsReady(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetNotes = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.getNotes(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleInsertNotes = useCallback(async (id, params) => {
    try {
      setLoading(true);
      const response = await OutOrders.insertNotes(id, params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetSimulateCancel = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.getSimulateCancel(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelOrder = useCallback(async (id, params) => {
    try {
      setLoading(true);
      const response = await OutOrders.cancelOrder(id, params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleQuitarSerialDelPicking = useCallback(async (id, params) => {
    try {
      setLoading(true);
      const response = await OutOrders.quitarSerialDelPicking(id, params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetComprobantesAsociados = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.getComprobantesAsociados(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCoordinationStatus = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.getCoordinationStatus(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handleGetAnmatValidate = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.anmatValidate(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handleGetAnmatValidateV2 = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.anmatValidateV2(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelarTraza = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.cancelarTraza(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleRetrazar = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.retrazar(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCodigosEventos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await OutOrders.getCodigosEventos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetFacturacion = useCallback(async (params) => {
    try {
      setLoading(true)

      const response = await OutOrders.getFacturacion(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleLiberarReserva = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.liberarReserva(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleLiberarReservaArticulo = useCallback(async (id, detail_id, data) => {
    try {
      setLoading(true)
      const response = await OutOrders.liberarReservaArticulo(id, detail_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerEventosOrdenEgreso = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await OutOrders.obtenerEventosOrdenEgreso(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetOutOrders,
    handleGetOutOrder,
    handleUpdateOutOrder,
    handleUpdateOutOrderCoordination,
    handleGetOutOrderStatus,
    handleUpdateOutOrderStatus,
    handleAddDetail,
    handleUpdateDetail,
    handleDeleteDetail,
    handleGetOutStockStatus,
    handleSendWaybill,
    handleLotDetail,
    handleGetTalonarios,
    handlePrintWaybill,
    handlePrintRemitoFa,
    handlePrintTraza,
    handleGetOutOrderSimulation,
    handleOutOrderGenerateInvoice,
    handleMarkOrderAsReady,
    handleRegenerateWaybill,
    handleGetNotes,
    handleInsertNotes,
    handleGetSimulateCancel,
    handleCancelOrder,
    handleQuitarSerialDelPicking,
    handleGetComprobantesAsociados,
    handleGetCoordinationStatus,
    handleGetAnmatValidate,
    handleGetAnmatValidateV2,
    handleCancelarTraza,
    handleRetrazar,
    handleGetCodigosEventos,
    handleGetFacturacion,
    handleLiberarReserva,
    handleLiberarReservaArticulo,
    handleObtenerEventosOrdenEgreso,
  }
}

export default useOutOrders