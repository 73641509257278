import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useWaves } from 'hooks'

import { SelectField } from 'components'

const TransportSelectionModal = ({ loading, show, onHide, selectedOrders }) => {
  const [items, setItems] = useState([])

  const { handleGetShippingCarrierServices } = useWaves()
  const [shippingCarrierServices, setShippingCarrierServices] = useState([])

  const getShippingCarrierServices = useCallback(async () => {
    try {
      const response = await handleGetShippingCarrierServices()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `${d.shipping_carrier_name} - ${d.shipping_carrier_service}${d?.shipping_code ? ` (${d.shipping_code})` : ''}`,
      }))

      setShippingCarrierServices(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShippingCarrierServices])


  useEffect(() => {
    if (show) {
      getShippingCarrierServices()
    }
  }, [show, getShippingCarrierServices])

  if (!show) return null

  return (
    <Modal size='lg' show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Asignar transportista</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            shipping_carrier_service_id: ''
          }}
          onSubmit={(shipping_carrier_service_id) => {
            onHide(shipping_carrier_service_id)}
          }
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col'>
                    <SelectField
                      id='shipping_carrier_service_id'
                      name='shipping_carrier_service_id'
                      label='Transportista'
                      options={shippingCarrierServices}
                      placeholder='Seleccione un servicio de transporte'
                      required
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => onHide(null)}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button variant='primary' type='submit' disabled={loading}>
                      <i className='bi bi-save me-2' />
                      Asignar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default TransportSelectionModal