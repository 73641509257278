import { useField } from 'formik'
import { FormLabel, FormGroup, FormControl } from 'react-bootstrap'

const InputV2 = ({ col, label, className = '', disabled = false, ...props }) => {
  const [field, meta] = useField(props)
  const showError = meta.touched && meta.error

  return (
    <div className={col}>
      <FormGroup className={props.className}>
        <FormLabel>{label}</FormLabel>
        <FormControl type="text"  autoComplete="off" {...field} className={`form-control ${className} ${showError ? 'border-danger' : ''}`} disabled={disabled} {...props} />
      </FormGroup>

      {showError && <div className='text-danger'>{meta.error}</div>}
    </div>
  )
}

export default InputV2;