import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { usePriceUpdate, useClickOutside } from 'hooks'
import { CustomTable, SelectField, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import TotalSummaryTable from 'pages/Sells/Orders/Detail/partials/TotalSummaryTable'
import LiberarReservaArticuloModal from './LiberarReservaArticuloModal'

import { columnsSellOrder } from 'pages/Sells/Orders/Detail/constants'
import { OrderTypeI, OutOrderStatusI, RoleI } from 'interfaces'

const ArticlesTab = ({ editing, handleEditing, handleGetOutOrder }) => {
  const { currentUser } = useAuth()
  const { values, handleReset } = useFormikContext()

  const [modal, setModal] = useState(false)

  const { handleGetPriceUpdates } = usePriceUpdate()
  const [priceUpdate, setPriceUpdate] = useState([])

  const getPriceUpdate = useCallback(async () => {
    try {
      const response = await handleGetPriceUpdates()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label,
      }))

      setPriceUpdate(data)
    } catch (error) {
      setPriceUpdate([])
      toast.error(error.message)
    }
  }, [handleGetPriceUpdates])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if ([OutOrderStatusI.EN_REVISION, OutOrderStatusI.COORDINACION_ENTREGA, OutOrderStatusI.PENDIENTE_PREPARACION].includes(values.out_order_status_id)) {
      actions.push({
        label: "Liberar reserva",
        icon: "bi bi-box-arrow-up text-warning",
        action: () => setModal(true)
      })
    }

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => {
    const cols = [
      ...columnsSellOrder.filter(col => {
        if (col.id == 'actual_pvp' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'list_cost' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'discount' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'price' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'profitability_rate' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'subtotal' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'vat' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        if (col.id == 'total' && values.order_type_id == OrderTypeI.PEDIDO_EGRESO) return false
        return true
      }),
    ]

    cols.push({
      Header: "",
      id: 'aviso',
      accessor: (row) => (
        <div className='d-flex'>
          {Boolean(row.returned_qty > 0) &&
            <Tooltip text={`Cant. devuelta: ${row.returned_qty}`}>
              <span className='bi bi-exclamation-circle text-danger fs-3 me-2' />
            </Tooltip>
          }
        </div>
      )
    })

    if (
      currentUser.roles.some(r => r.id == RoleI.ADMIN) &&
      [OutOrderStatusI.EN_REVISION, OutOrderStatusI.COORDINACION_ENTREGA, OutOrderStatusI.PENDIENTE_PREPARACION].includes(values.out_order_status_id)
    ) {
      cols.push({
        Header: '',
        id: 'actions',
        className: 'col-icons',
        accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
      })
    }

    return cols
  }, [columnsSellOrder, currentUser])

  useEffect(() => {
    getPriceUpdate()
  }, [getPriceUpdate])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label='Actualización de precios'
            options={priceUpdate}
            // disabled={!editing}
            disabled={true}
          />
        </div>
      </div>

      <div className=' mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8' >
          <label className=' form-label m-0'>Artículos</label>
        </div>

        <CustomTable columns={columns} data={values?.detail} handleContextMenu={handleContextMenu} />

        <ContextMenu
          ref={contextRef}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
        />

        <LiberarReservaArticuloModal
          show={modal}
          onClose={() => {
            setModal(false)
            handleGetOutOrder()
          }}
          articulo={currentRow}
        />

        {values.detail.length > 0 && values.order_type_id != OrderTypeI.PEDIDO_EGRESO &&
          <div className='mt-8'>
            <TotalSummaryTable editing={editing} />
          </div>
        }
      </div>
    </>
  )
}

export default ArticlesTab