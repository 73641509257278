import { useNavigate } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'

import { Loading, Tooltip } from 'components'

const DetailContainerV2 = ({ loading = false, creacion = false, title, statusColor, status, handleEventosModal, subheader, actions, children }) => {
  const navigate = useNavigate()

  return (
    <Card className="mb-8">
      <Card.Header className='pt-8 pb-5'>
        <div className='w-70'>
          <div>
            <h3 className='me-5 d-inline' style={{ verticalAlign: 'middle' }}>{title}</h3>

            <span className={`badge ${statusColor} justify-content-center fw-normal me-4`} style={{ width: '180px' }}>
              {status}
            </span>

            {!creacion &&
              <Tooltip text='Ver eventos'>
                <Button variant='primary' className='px-2 py-1' onClick={() => handleEventosModal(true)}>
                  <span className='bi bi-clock-history fs-3' />
                </Button>
              </Tooltip>
            }
          </div>

          <div>
            {subheader}
          </div>
        </div>

        <div className='w-30'>
          {creacion
            ?
              <div className='col text-end'>
                <Button type='button' variant='secondary' onClick={() => navigate(-1)}>
                  <i className='bi bi-chevron-left text-gray-900' />
                  Volver
                </Button>
              </div>
            : actions
          }
        </div>
      </Card.Header>

      <Card.Body className='pt-0'>
        {loading ? <Loading /> : children}
      </Card.Body>
    </Card>
  )
}

export default DetailContainerV2