export const COLUMNS = [
  {
    Header: 'Artículo',
    id: 'product_var_id',
    accessor: (row) => (
      <p className="m-0 p-0">[{row.product_var_id}] {row.product_var_name}</p>
    )
  },
  {
    Header: <p className="m-0 p-0">Pedido</p>,
    id: 'qty_sales_order',
    accessor: (row) => (
      <p className="m-0 p-0">{row.qty_sales_order}</p>
    )
  },
  {
    Header: <p className="m-0 p-0">Reservado</p>,
    id: 'qty_reserved',
    accessor: (row) => (
      <p className="m-0 p-0">{row.qty_reserved}</p>
    )
  },
  {
    Header: <p className="m-0 p-0">Utilizado</p>,
    id: 'qty_used',
    accessor: (row) => (
      <p className="m-0 p-0">{row.qty_used}</p>
    )
  },
  {
    Header: <p className="m-0 p-0">Faltante</p>,
    id: 'qty_remainin',
    accessor: (row) => (
      <p className="m-0 p-0">{row.qty_remaining}</p>
    )
  },
]