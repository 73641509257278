import * as Yup from 'yup'
import moment from 'moment'

import { formatInputDate } from 'utilities'

const initialValues = {
  id: '',
  name: '',
  orders: [],
  created_at: '',
}

const schema = Yup.object().shape({

})

const ordersAdapter = (orders) => {
  let values = orders.map((order) => {
    return {
      ...order,
      created_at: formatInputDate(order.created_at),
      canceled_at: formatInputDate(order.canceled_at),
    }
  })

  return values
}

const dataAdapter = (data) => {
  let values = {
    ...data,
    created_at: moment(data.created_at).format('YYYY-MM-DD'),
  }

  return values
}

export {
  initialValues,
  schema,
  dataAdapter,
}