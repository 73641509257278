import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useAuth } from 'app/modules/auth'
import { useDispatchOrders, useOutOrders, useWaves } from 'hooks'
import useTransactionalDocuments from 'hooks/useTransactionalDocuments'
import { OrderTypeI, OutOrderStatusI, RoleI } from 'interfaces'
import './article.css'
import { OutOrders } from 'services'

const groupData = (orderDetail, alreadyScanned) => {
  const stockAgrupado = Object.values(
    alreadyScanned.reduce((acc, el) => {
      const key = `${el.product_var_id}-${el.product_var_name}-${el.gtin}-${el.lot_number}`
      if (!acc[key]) {
        acc[key] = { product_var_id: el.product_var_id, product_var_name: el.product_var_name, gtin:el.gtin, lot_number:el.lot_number, stock: 0 }
      }
      acc[key].stock++
      return acc
    }, {})
  )

  // Combinar stock agrupado con cantidades esperadas y calcular faltantes
  const resultadoFinal = orderDetail.map(elemento => {
    const stockInfo = stockAgrupado
      .filter(item => item.product_var_id === elemento.product_var_id)
      .reduce((acc, item) => {return acc + item.stock}, 0)

    const pending = elemento.qty - stockInfo

    return {
      product_var_id: elemento.product_var_id,
      product_var_name: elemento.product_var_desc,
      gtin: elemento.gtin,
      qty: elemento.qty,
      stock: stockInfo,
      traced:elemento.traced,
      pending: pending >= 0 ? pending : 0 // faltantes no pueden ser negativos
    }
  })

  return resultadoFinal
}

const MenuActions = ({ values, simulation, handleGetOutOrder, setShowWaybillModal, setShowRegenerateWaybillModal, disabled}) => {
  const { currentUser } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const [talonarios, setTalonarios] = useState([])
  const { handleGetDispatchOrderDetail } = useDispatchOrders();
  const { handleAddShippingCarrierService } = useWaves();
  const [tienePendientes, setTienePendientes] = useState(true)
  const {
    handleUpdateOutOrderStatus, handleGetTalonarios, handlePrintWaybill,
    handleRegenerateWaybill, handleMarkOrderAsReady, handleOutOrderGenerateInvoice,
    handlePrintTraza, handleGetOutStockStatus, handleLiberarReserva,
    handleSendWaybill, loading
  } = useOutOrders()
  const { handleDeleteWaveOrder } = useWaves()
  const { handlePrintFactura, handleEmitirDiferido } = useTransactionalDocuments()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const puedeFacturar = ()=>{
    if( values && 
        (values.afip_error || values.afip_codigo_interno_tipo_comprobante==3 || simulation?.datos_factura) &&
        values.out_order_status_id!=OutOrderStatusI.CANCELADO && 
        values.out_order_status_id!=OutOrderStatusI.COORDINACION_ENTREGA && 
        values.out_order_status_id!=OutOrderStatusI.EN_REVISION && 
        values.out_order_status_id!=OutOrderStatusI.EN_PREPARACION && 
        values.out_order_status_id!=OutOrderStatusI.PENDIENTE_PREPARACION && 
        values.out_order_status_id!=OutOrderStatusI.RESERVA_PARCIAL )
      return true
    else
      return false
  }
  const getTalonarios = async () => {
    try{
      const resp = await handleGetTalonarios();
      setTalonarios(resp?.data?.result)
    }
    catch(err){
      toast.error("Error trayendo talonarios de remitos")

    }
  }
  const updateOutOrderStatus = async (newStatus) => {
    try {
      const response = await handleUpdateOutOrderStatus(values.id, newStatus)

      toast.success("Orden actualizada correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetOutOrder()
      handleClose()
    }
  }

  const reloadOrder = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a enviar a preparar la orden #${values.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar a armado",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleUpdateOutOrderStatus(values.id, OutOrderStatusI.PENDIENTE_PREPARACION)
        toast.success("La órden se envió a armado", { theme: "colored" })
        handleGetOutOrder()
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const sendToRevision = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a enviar a revisión la orden #${values.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar a revisión",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleUpdateOutOrderStatus(values.id, OutOrderStatusI.EN_REVISION)
        toast.success("La órden se envió a revisión", { theme: "colored" })
        handleGetOutOrder()
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const removeOutOrderFromWave = async (wave_id, wave_order_id, out_order_id, out_order_status_id) => {
    try {
      const values = {
        out_order_status_id: out_order_status_id
      }
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Desea enviar la Orden de egreso #${out_order_id} a revisión? Esto también eliminará la orden de egreso de la Ola #${wave_id}`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: 'Sí, enviar a revisión',
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp?.isConfirmed) {
        const response = await handleDeleteWaveOrder(wave_id, wave_order_id, values)
        toast.success(`Orden de egreso #${out_order_id} eliminada satisfactoriamente de la Ola #${wave_id} y enviada a revisión`)
        handleGetOutOrder()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const swalRemitoInterno = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Confirma la generación del remito interno para la órden ${values.id} ?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, remitir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const {wave, shipping_carrier_service_name} = values;
        let auxData = {id_talonario:null};
        //TODO por ahora mandamos hardcodeado transporte interno (shipping_carrier_service_id: 1)
        if(!shipping_carrier_service_name){
          await handleAddShippingCarrierService(wave.id, wave.wave_order_id, { shipping_carrier_service_id: 1 })          
        }
          
        await handleSendWaybill(values.id, auxData); 
        toast.success(`La órden de egreso #${values.id} fue procesada`, {theme: "colored"})
        handleGetOutOrder()
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const sendWaybill = async () => {
    // antes de remitir, valida nuevamente que no haya pendientes
    if(tienePendientes){
      if(values && values.id){
        const resp = await handleGetOutStockStatus(values.id, {})
        const data = resp?.data?.result

        if (values && data) {
          const data_grouped = groupData(values.detail, data)
          const pendientes = data_grouped.reduce((acc, el)=> +acc + +el.pending, 0)

          if(pendientes==0){
            setTienePendientes(false)
            if(values?.order_type_id==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO){
              swalRemitoInterno()
            }
            else{
              setShowWaybillModal(true)
            }
            
          }
          else{
            setTienePendientes(true)
            toast.error("La órden tiene artículos sin pickear")
          }        
        }
      }
    }
    else{
      if(values?.order_type_id==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO){
        swalRemitoInterno()
      }
      else{
        setShowWaybillModal(true)
      }
    }
  }

  const printWaybill = async () => {
    try {
      let copias = 2;
      const response = await handlePrintWaybill(values.id, copias)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${values.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(1, values.afip_id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('Factura descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `factura-${values.afip_id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar la factura')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const printTraza = async () => {
    try {
      const response = await handlePrintTraza(values.id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `traza-${values.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante de traza')
      }
    } catch (error) {
      toast.error(error.message)
    }

  }
  const regenerateWaybill = async () => {
    setShowRegenerateWaybillModal(true)
    // try {
    //   const resp = await Swal.fire({
    //     title: "Advertencia",
    //     text: `Va a regenerar el remito de la orden de egreso #${values.id}, ¿confirma?`,
    //     icon: "warning",
    //     showDenyButton: true,
    //     denyButtonText: "No, cancelar",
    //     confirmButtonText: "Si, regenerar",
    //     customClass: { popup:'popup-centrado', input:'form-control select-remito', confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
    //     reverseButtons: true,
    //   })

    //   if (resp.isConfirmed) {
    //     await handleRegenerateWaybill(values.remito_id)
    //     toast.success("Se regeneró el remito", { theme: "colored" })
    //   }
    // } catch (err) {
    //   toast.error(err.message, { theme: "colored" })
    // } finally {
    //   handleGetOutOrder()
    // }
  }

  const actionMarkAsReady = async () => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Está seguro de marcar como lista la orden #${values?.id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, marcar como lista`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleMarkOrderAsReady(values?.id)
        toast.success(`La órden ${values?.id} fue marcada como lista`, {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const refacturar = async ()=>{
      try {
        const resp = await Swal.fire({
          title: 'Advertencia',
          text: `¿Va a intentar facturar la orden #${values?.id}, confirma?`,
          icon: 'warning',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: `Si, facturar`,
          customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleOutOrderGenerateInvoice(values?.id)
          toast.success(`Se generó la factura de la órden ${values?.id}`, {theme: "colored"})
        }
      } catch (error) {
        toast.error(error.message)
      }
  }

  const refacturarInmediato = async ()=>{
      try {
        const resp = await Swal.fire({
          title: 'Advertencia',
          text: `¿Va a intentar facturar de forma inmediata la orden #${values?.id}, confirma?`,
          icon: 'warning',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: `Si, facturar`,
          customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleOutOrderGenerateInvoice(values?.id)
          const oo = await OutOrders.getOutOrder(values?.id, { getDetail: true })

          if(oo.data.result.afip_estado=='DEFERRED' && oo.data.result.afip_id!=null){              
            const response = await handleEmitirDiferido(oo.data.result.afip_id)
            toast.success(`Se generó la factura de la órden ${values?.id}`, {theme: "colored"})
          }
          else{
            toast.error("No se pudo generar la factura")
          }

          handleGetOutOrder()

        }
      } catch (error) {
        toast.error(error.message)
      }
  }

  const liberarReserva = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Liberar reserva de la Orden de egreso #${values?.id}?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, liberar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleLiberarReserva(values?.id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetOutOrder()
    }
  }

  const availableActions = () => {
    let actions = []

    if (values) {
      if (values.out_order_status_id === OutOrderStatusI.EN_REVISION) {
        actions.push({
          label: "Enviar a armado",
          icon: "bi bi-boxes text-primary",
          action: async () => {
            try {
              const resp = await Swal.fire({
                title: "Advertencia",
                text: `Va a enviar a preparar la orden #${values.id}, ¿confirma?`,
                icon: "warning",
                showDenyButton: true,
                denyButtonText: "No",
                confirmButtonText: "Si, enviar a armado",
                customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
                reverseButtons: true,
              })

              if (resp.isConfirmed) {
                await handleUpdateOutOrderStatus(values.id, OutOrderStatusI.PENDIENTE_PREPARACION)
                toast.success("La órden se envió a armado", { theme: "colored" })
              }
            } catch (err) {
              toast.error(err.message, { theme: "colored" })
            } finally {
              handleGetOutOrder()
            }
          }
        })

        if (Boolean(values.requires_coordination)) {
          actions.push({
            label: "Coordinar entrega",
            icon: "bi bi-check-circle text-success",
            action: async () => {
              try {
                const resp = await Swal.fire({
                  title: "Advertencia",
                  text: `Va a enviar a coordinar la órden #${values.id}, ¿confirma?`,
                  icon: "warning",
                  showDenyButton: true,
                  denyButtonText: "No",
                  confirmButtonText: "Si, enviar a coordinar",
                  customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
                  reverseButtons: true,
                })

                if (resp.isConfirmed) {
                  await updateOutOrderStatus(OutOrderStatusI.COORDINACION_ENTREGA)
                }
              } catch (error) {
                toast.error(error.message)
              }
            }
          })
        }
      }

      if (values?.out_order_status_id === OutOrderStatusI.COORDINACION_ENTREGA) {
        // actions.push({
        //   label: "Enviar a revisión",
        //   icon: "bi bi-x-circle text-danger",
        //   action: async () => {
        //     try {
        //       const resp = await Swal.fire({
        //         title: "Advertencia",
        //         text: `Va a enviar a revisión a la órden #${values.id}, ¿confirma?`,
        //         icon: "warning",
        //         showDenyButton: true,
        //         denyButtonText: "No",
        //         confirmButtonText: "Si, enviar a revisión",
        //         customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        //         reverseButtons: true,
        //       })

        //       if (resp.isConfirmed) {
        //         await updateOutOrderStatus(OutOrderStatusI.EN_REVISION)
        //       }
        //     } catch (error) {
        //       toast.error(error.message)
        //     } finally {
        //       handleGetOutOrder()
        //     }
        //   }
        // })

        actions.push({
          label: "Preparar orden",
          icon: "bi bi-boxes text-success",
          action: reloadOrder
        })
      }

      if (values?.out_order_status_id === OutOrderStatusI.EN_PREPARACION && !values?.remito_id) {
        actions.push({
          label: "Remitir",
          icon: "bi bi-truck text-success",
          action:() => sendWaybill()
        })
      }

      if (values?.out_order_status_id === OutOrderStatusI.EN_PREPARACION && values?.remito_id) {
        actions.push({
          label: "Marcar como lista",
          icon: "bi bi-check-lg text-success",
          action: actionMarkAsReady
        })
      }

      if (
        values?.out_order_status_id === OutOrderStatusI.COORDINACION_ENTREGA ||
        values?.out_order_status_id === OutOrderStatusI.PENDIENTE_PREPARACION ||
        values?.out_order_status_id === OutOrderStatusI.PENDIENTE_DESPACHO
      ) {
        if (values?.out_order_status_id === OutOrderStatusI.PENDIENTE_DESPACHO) {
          actions.push({
            label: 'Enviar a revisión',
            icon: 'bi bi-clipboard-check text-warning',
            action: () => removeOutOrderFromWave(values?.wave?.id, values?.wave?.wave_order_id, values?.id, OutOrderStatusI.EN_REVISION)
          })
        } else {
          actions.push({
            label: "Enviar a revisión",
            icon: "bi bi-clipboard-check text-primary",
            action: sendToRevision
          })
        }
      }

      if (
        currentUser.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
        values?.remito_id == null &&
        (
          values?.out_order_status_id == OutOrderStatusI.EN_REVISION ||
          values?.out_order_status_id == OutOrderStatusI.COORDINACION_ENTREGA ||
          values?.out_order_status_id == OutOrderStatusI.PENDIENTE_PREPARACION
        )
      ) {
        actions.push({
          label: 'Liberar reserva',
          icon: 'bi bi-box-arrow-up text-warning',
          action: () => liberarReserva()
        })
      }

      if (values?.out_order_status_id != OutOrderStatusI.CANCELADO && values?.remito_id) {
        actions.push({
          label: "Regenerar Remito",
          icon: "bi bi-arrow-clockwise text-danger",
          action: () => regenerateWaybill()
        })
      }

      if (puedeFacturar() ) {
        actions.push({
          label: "Facturar" + (values.billing_type_id==2?" diferida":""),
          icon: "bi bi-receipt-cutoff text-danger",
          action: () => refacturar()
        })
      }

      if (puedeFacturar() && values.billing_type_id==2) {
        actions.push({
          label: "Facturar inmediato",
          icon: "bi bi-receipt text-warning",
          action: () => refacturarInmediato()
        })
      }

      if (values?.out_order_status_id === OutOrderStatusI.EN_DISTRIBUCION) {
        actions.push({
          label: "Enviar a revisión",
          icon: "bi bi-clipboard-check text-warning",
          action: sendToRevision
        })

        actions.push({
          label: "Orden entregada",
          icon: "bi bi-box-arrow-in-up-right text-success",
          action: () => updateOutOrderStatus(OutOrderStatusI.ENTREGADA)
        })
      }

    }
    return actions
  }

  const getStatus = async()=>{
    if(values && values.id){
      const resp = await handleGetOutStockStatus(values.id, {})
      const data = resp?.data?.result

      if (values && data) {
        const data_grouped = groupData(values.detail, data)
        const pendientes = data_grouped.reduce((acc, el) => +acc + +el.pending, 0)

        if (pendientes == 0) {
          setTienePendientes(false)
        } else {
          setTienePendientes(true)
        }
      }
    }
  }

  useEffect(() => {
    if (values.id) {
      getStatus()
    }
  }, [values])

  useEffect(() => {
    getTalonarios()
  }, [])

  return (
    <>
      {availableActions().length > 0 &&
        <DropdownButton title="Acciones" disabled={disabled || loading}>
          {availableActions().map((data, index) => (
            <Dropdown.Item key={index} onClick={() => {data.action()}} disabled={disabled || loading}>
              <i className={`${data.icon} pe-3`} />{data.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      }
    </>
  )
}

const Actions = ({ values, simulation, editing, handleEditing, submitRef, cancelRef, handleGetOutOrder, setShowWaybillModal, setShowRegenerateWaybillModal, loading }) => {
  const { currentUser } = useAuth()
  const navigation = useNavigate()

  return (
    <>
      { (false ) && (
        <div className="custom-overlay-layer d-flex justify-content-center align-items-center">
          <div className="spinner-border text-success" role="status"></div>
        </div>
      )}

      <div className='d-flex align-items-center'>
        <div className='row'>
          {!editing
            ?
              <>
                <div className='d-flex mt-5'>
                  <Button className='me-3' type="button" variant="secondary" onClick={() => navigation(-1)}>
                    <i className="ki-duotone ki-left" />
                    Volver
                  </Button>

                  {currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.COORDINACION) &&
                    (
                      values.out_order_status_id === OutOrderStatusI.COORDINACION_ENTREGA ||
                      values.out_order_status_id === OutOrderStatusI.EN_REVISION
                    ) &&
                    <button className='me-3 btn btn-outline btn-outline-primary' type="button" onClick={handleEditing}>
                      <i className="bi bi-pencil" />
                      Editar
                    </button>
                  }

                  {currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.COORDINACION) &&
                    <div className='col'>
                      <MenuActions values={values} simulation={simulation} handleGetOutOrder={handleGetOutOrder} setShowWaybillModal={setShowWaybillModal}
                        setShowRegenerateWaybillModal={setShowRegenerateWaybillModal}  disabled={loading} />
                    </div>
                  }
                </div>
              </>
            : (currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.COORDINACION)) &&
              <>
                <div className='d-flex mt-5'>
                  <Button className='me-3' type="button" variant="secondary" onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button className='me-3' type="button" variant="primary" onClick={() => submitRef.current.click()} ><i className="bi bi-save me-2" />Guardar</Button>
                  <MenuActions user={currentUser} values={values} handleGetOutOrder={handleGetOutOrder} setShowWaybillModal={setShowWaybillModal} 
                    setShowRegenerateWaybillModal={setShowRegenerateWaybillModal} disabled={loading} />
                </div>
              </>
          }
        </div>
      </div>
    </>
  )
}

export default Actions