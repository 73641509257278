import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useWaybill, useClickOutside, useDispatchOrders } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'

import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import useGetColumns from './columns'
import { initialValues } from './constants'
import { ESTADOS_ORDEN_DESPACHO, labelColor } from '../List'

import Header from './Header'
import Actions from './Actions'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
}

const DispatchOrderDetail = () => {
  const { id } = useParams()

  const { COLUMNS } = useGetColumns()

  const { loading: loadingDS, handleGetDispatchOrder, handleGetDispatchOrderDetail, eliminarOrdenEnvioDeDespacho } = useDispatchOrders()
  const { loading, handleRemoveOrderDeliveryFromDispatch } = useWaybill()

  const [data, setData] = useState(initialValues)
  const [detail, setDetail] = useState([])
  const [detailQuery, setDetailQuery] = useState(initialObQuery)
  const [detailCount, setDetailCount] = useState(0)


  const getData = useCallback(async () => {
    try {
      const response = await handleGetDispatchOrder(id)
      let data = response.data.result
      data.label_color = labelColor(data.dispatch_order_status_id)

      setData(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetDispatchOrder])

  const getDetail = useCallback(async () => {
    try {
      let params = {}

      if (detailQuery && detailQuery.pageNumber) {
        params = {
          ...params,
          ...detailQuery
        }
      }
      const response = await handleGetDispatchOrderDetail(id, params)

      const data = response.data.result
      const count = response.data.metadata.count

      setDetail(data)
      setDetailCount(count)
    } catch (error) {
      setDetail([])
      setDetailCount(0)
      toast.error(error.message)
    }
  }, [id, handleGetDispatchOrderDetail, detailQuery])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (data.dispatch_order_status_id == ESTADOS_ORDEN_DESPACHO.PENDIENTE) {
      actions.push({
        label: "Quitar del despacho",
        icon: "bi bi-box-arrow-up text-danger",
        action: actionQuitDispatch
      })
    }

    return actions
  }

  const actionQuitDispatch = async () => {
    if (currentRow) {
      try {
        const repetidos = detail.filter(d => d.order_delivery_id == currentRow.order_delivery_id).length;

        const result = await Swal.fire({
          icon: 'warning',
          title: 'Quitar del despacho',
          text: repetidos > 1
            ? `La orden de envío #${currentRow.order_delivery_id} tiene (${repetidos}) órdenes de egreso, se quitarán todas del despacho`
            : `¿Quitar orden de envío #${currentRow.order_delivery_id} del despacho #${id}?`,
          confirmButtonText: 'Si, quitar',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        })

        if (result.isConfirmed) {
          const response = await eliminarOrdenEnvioDeDespacho(id, currentRow.id)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        getData()
        getDetail()
      }
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: detailCount,
    obQuery: detailQuery,
    setObQuery: setDetailQuery
  }

  useEffect(() => {
    getData()
    getDetail()
  }, [getData, getDetail])

  return (
    <>
      <DetailContainer
        title={`Orden de despacho #${id}`}
        statusV2={<Header data={data} />}
        buttons={<Actions loading={loadingDS || loading} data={data} handleGetData={getData} handleGetDetail={getDetail} /> }
      >
        <TableContainer>
          <CustomTable columns={columns} data={detail} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
        </TableContainer>

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />
      </DetailContainer>
    </>
  )
}

export default DispatchOrderDetail