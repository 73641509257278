import Swal from "sweetalert2"

/**
 * Muestra una advertencia con SweetAlert2
 * @param {Object} params
 * @param {string} [params.text='¿Estás seguro?'] - Texto a mostrar (si no se especifica html)
 * @param {string} [params.html=null] - Contenido HTML a mostrar (opcional, reemplaza text)
 * @param {string} [params.title='Advertencia'] - Título de la alerta
 * @param {string} [params.icon='warning'] - Tipo de ícono ("warning", "error", "success", "info", "question")
 * @param {boolean} [params.showDenyButton=false] - Mostrar botón de "Deny"
 * @param {string} [params.denyButtonText='No, cerrar'] - Texto del botón "Deny"
 * @param {string} [params.confirmButtonText='Sí, confirmar'] - Texto del botón "Confirm"
 * @param {string} [params.input=null] - Tipo de input para SweetAlert (ej: 'textarea', 'text', etc.)
 * @param {string} [params.inputLabel=''] - Etiqueta para el input
 * @param {string} [params.inputValue=''] - Valor inicial del input
 * @param {Object} [params.inputAttributes={ autocomplete: 'off' }] - Atributos del input
 * @param {string} [params.inputErrorMessage='Por favor, completa el campo'] - Mensaje de error para validación de input
 */
export function mostrarAdvertencia({
  text = '¿Estás seguro?',
  html = null,
  title = 'Advertencia',
  icon = 'warning',
  showDenyButton = false,
  denyButtonText = 'No, cerrar',
  confirmButtonText = 'Sí, confirmar',
  input = null,
  inputLabel = '',
  inputValue = '',
  inputAttributes = { autocomplete: 'off' },
  inputErrorMessage = 'Por favor, completa el campo'
} = {}) {
  const opciones = {
    title,
    icon,
    customClass: {
      confirmButton: 'btn btn-primary',
      denyButton: 'btn btn-secondary'
    },
    reverseButtons: true,
    confirmButtonText
  }

  // Si se recibe html, se usa html; si no, se usa text
  if (html) {
    opciones.html = html
  } else {
    opciones.text = text
  }

  // Si se requiere botón "Deny"
  if (showDenyButton) {
    opciones.showDenyButton = true
    opciones.denyButtonText = denyButtonText
  }

  // Si se requiere input
  if (input) {
    opciones.input = input
    opciones.inputValue = inputValue
    opciones.inputLabel = inputLabel
    opciones.inputAttributes = inputAttributes
    opciones.inputValidator = (value) => {
      if (!value?.trim()) {
        return inputErrorMessage
      }
    }
  }

  return Swal.fire(opciones)
}