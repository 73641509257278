import { useState, useEffect } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";
import { helix } from "ldrs";
import moment from 'moment';

import { useAuth } from "app/modules/auth";

import { OrderTypeI, RoleI } from "interfaces";

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '39px',
    height: '39px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
}

function lotesDistintos(array) {
  return [
    ...new Map(
      array.map(objeto => [objeto["value"], {
        value: objeto["value"],
        label: objeto["value"],
        expiration_date: objeto["expiration_date"]
      }])
    ).values()
  ]
}

export function ArticleLotNumberModal({ show, onHide, requestArticles, loading, salesOrderTypeId, clientStorageSpacesOptions }) {
  const { currentUser } = useAuth()

  const [articleData, setArticleData] = useState({})
  const [selectedArticle, setSelectedArticle] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [errorQty, setErrorQty] = useState(false)
  const [errorExpDate, setErrorExpDate] = useState(false)
  const [lotAvailables, setLotAvailables] = useState([])
  const [optionsLotNumber, setOptionsLotNumber] = useState([])
  const [optionsStorageSpaces, setOptionsStorageSpaces] = useState([])
  const [optionsSerialNumber, setOptionsSerialNumber] = useState([])

  const handleChangeQty = (e) => {
    let { value } = e.target
    value = value.replace(/\D/g, '')
    setArticleData({ ...articleData, qty: value })
  }

  const onChangeArticle = (option) => {
    setSelectedArticle(option)
    setLotAvailables(option.options_lot_number)
    setOptionsLotNumber(lotesDistintos(option.options_lot_number))

    if (option.traced) {
      setArticleData({ ...articleData, product_var_id: option.product_var_id, gtin: option.gtin, qty: 1 })
    } else {
      setArticleData({ ...articleData, product_var_id: option.product_var_id, gtin: option.gtin })
    }
  }

  const onChangeLotNumber = (option) => {
    let expirationDate = moment(option?.expiration_date)
    const today = moment()
    let _display = ''
    let _isExpired = false

    if (expirationDate.isValid()) {
      const diffInDays = (expirationDate.startOf('day').valueOf() - today.startOf('day').valueOf()) / (1000 * 60 * 60 * 24)

      if (diffInDays < 0) {
        _display = "Lote vencido"
        _isExpired = true
        setErrorExpDate(true)
      } else if (diffInDays <= 90) {
        _display= `Lote vence en ${diffInDays} días`
        setErrorExpDate(true)
      } else {
        setErrorExpDate(false)
      }
    }

    setArticleData({
      ...articleData,
      lot_number: option.value,
      expiration_date: option.expiration_date ? moment(option.expiration_date).format("DD/MM/YYYY") : '',
      expiration_display: _display,
      isExpired: _isExpired
    })

    const _optionsLot = lotAvailables.filter(op => op.value.toString() == option.value.toString()) // Filtra por lot_number
    setOptionsSerialNumber(_optionsLot.map(d => ({ value: d.serial_number, label: d.serial_number })))
    setOptionsStorageSpaces(_optionsLot.map(d => ({ value: d.storage_space_id, label: d.storage_space_name })))
  }

  const onChangeStorageSpace = (option) => {
    setArticleData({ ...articleData, storage_space_id: option.value })
  }

  const onChangeStorageSpaceDestino = (option) => {
    setArticleData({ ...articleData, storage_space_id_destino: option.value })
  }

  const handleSubmit = () => {
    setSubmitted(true)

    const { qty, pending, lot_number, product_var_id, isExpired, ...rest } = articleData

    if (+qty > +pending) {
      setErrorQty(true)
      return
    }

    if (!qty || !lot_number || !product_var_id || qty == '' || lot_number == '' || product_var_id == '') {
      return
    }

    // 1. Verificamos si el usuario es admin
    const isAdmin = currentUser.roles.some(role => (role.id == RoleI.ADMIN || role.id == RoleI.OPERACIONES_ADMIN))

    // 2. Solo si NO es admin validamos la expiración
    if (!isAdmin && isExpired) {
      toast.error("El artículo se encuentra vencido")
      return
    }

    onHide(articleData)
  }

  useEffect(() => {
    if (show) {
      setArticleData({})
      setSelectedArticle({})
      setSubmitted(false)
      setErrorQty(false)
      setErrorExpDate(false)
      helix.register()
    }
  }, [show])

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop='static' size="md">
        <Modal.Body>
          <h3>Completar carga manual</h3>

          {loading
            ?
              <div className='text-center'>
                <l-helix color="var(--bs-primary)" style={{ marginTop: "50px" }} />
              </div>
            :
              <>
                <div className="row mt-10">
                  <div className="col">
                    <FormLabel style={labelStyle}>Artículo</FormLabel>

                    <Select
                      options={requestArticles}
                      onChange={onChangeArticle}
                      styles={customStyles}
                      className={`${submitted && (!selectedArticle.product_var_id || selectedArticle.product_var_id == '') ? "border border-danger" : ""}`}
                    />
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col">
                    <FormLabel style={labelStyle}>GTIN</FormLabel>

                    <input
                      id='gtin'
                      type="text"
                      className="form-control"
                      value={selectedArticle?.gtin || ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col">
                    <label htmlFor='qty' className='form-label'>Cantidad</label>

                    <input
                      id='qty'
                      name="qty"
                      type="text"
                      className={`form-control ${submitted && (!articleData.qty || articleData.qty == '') ? "border border-danger" : ""}`}
                      value={articleData.qty || ''}
                      onChange={handleChangeQty}
                      autoComplete="off"
                      maxLength={5}
                      disabled={Boolean(selectedArticle.traced)}
                    />

                    {errorQty && <div className='text-danger'>La cantidad no puede ser superior al pendiente</div>}
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col">
                    <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>

                    <Select
                      options={optionsLotNumber}
                      onChange={onChangeLotNumber}
                      styles={customStyles}
                      className={`${submitted && (!selectedArticle.product_var_id || selectedArticle.product_var_id == '') ? "border border-danger" : ""}`}
                    />

                    {(selectedArticle?.product_var_id && (!optionsLotNumber || optionsLotNumber.length == 0)) &&
                      <p className="text-danger fst-italic" style={{ fontSize: "0.9rem" }}>No hay lotes disponibles en stock</p>
                    }
                  </div>

                  <div className="col">
                    <label className='form-label'>Vencimiento</label>

                    <input
                      type="text"
                      className="form-control" value={articleData?.expiration_date || ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                {errorExpDate &&
                  <div className="row">
                    <div className="col">
                      <div className="mt-1 p-2 bg-danger text-white">{articleData.expiration_display}</div>
                    </div>
                  </div>
                }

                {!Boolean(selectedArticle.traced) &&
                  <div className="row mt-10">
                    <div className="col">
                      <label className='form-label' maxLength={30}>Almacenamiento origen</label>

                      <Select
                        options={optionsStorageSpaces}
                        onChange={onChangeStorageSpace}
                        styles={customStyles}
                        className={`${submitted && (!selectedArticle.product_var_id || selectedArticle.product_var_id == '') ? "border border-danger" : ""}`}
                      />
                    </div>
                  </div>
                }

                {salesOrderTypeId == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO &&
                  <div className="row mt-10">
                    <div className="col">
                      <label className='form-label' maxLength={30}>Almacenamiento destino</label>

                      <Select
                        options={clientStorageSpacesOptions}
                        onChange={onChangeStorageSpaceDestino}
                        styles={customStyles}
                        className={`${submitted && (!selectedArticle.product_var_id || selectedArticle.product_var_id == '') ? "border border-danger" : ""}`}
                      />
                    </div>
                  </div>
                }

                {Boolean(selectedArticle.traced) &&
                  <div className="row mt-10">
                    <div className="col">
                      <label htmlFor='serial_number' className='form-label' maxLength={30}>Nro de serie</label>

                      <Select
                        options={optionsSerialNumber}
                        onChange={(obj)=> setArticleData({ ...articleData, serial_number: obj.value })}
                        styles={customStyles}
                        className={`${submitted && (!selectedArticle.product_var_id || selectedArticle.product_var_id == '') ? "border border-danger" : ""}`}
                      />
                    </div>
                  </div>
                }
              </>
          }
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button variant="secondary" onClick={() => onHide(null)} disabled={loading}>Salir</Button>

          <Button type="button" variant="primary" onClick={handleSubmit} disabled={loading}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}