import { TableInstance, Column } from "react-table";

export interface LogisticIncomeI {
  id: number;
  order_id: number;
  customer_supplier_id: number;
  customer_supplier_name: string;
  income_date: string;
  lines: number;
  units: number;
  status_id: number;
  status_name: string;
}

export type LogisticIncomeTableI = TableInstance<LogisticIncomeI>

export type LogisticIncomeColumnI = Column<LogisticIncomeI>

export enum OrderStatusI {
  GENERADO = 1,
	PENDIENTE_INGRESO = 2,
	INGRESO_PARCIAL = 3,
	VENCIDO = 4,
	CANCELADO = 5,
	COMPLETO = 6,
	BORRADOR = 7,
	AUTORIZADO = 8,
	CONFIRMADO = 9,
	COMPLETO_PARCIAL = 10,
	INGRESADO = 11,
	RECHAZADO = 12,
	ERROR_TRAZA = 13,
}