import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PurchaseIncomeOrderColumnI } from 'interfaces'

import { currencyFormat, formatTableDate } from 'utilities'
import routes from 'app/routing/routes'

const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: "ID",
      id: 'id',
       accessor: (row) => (
         <Link to={`${routes.WAREHOUSE}/${row.space_id}`}  state={{ depot: row }}>
           {row.space_id}
         </Link>
       ),
    },  
    {
        Header: "U. negocio",
        accessor: 'business_unit_name',
    },     
    {
        Header: "Espacio",
        accessor: 'space_storage_name',
    },       
    {
        Header: "Dueño",
        accessor: 'owner',
    },  
    {
        Header: "Depósito",
        accessor: 'general_depot',
    },    
    {
        Header: "Almacén",
        accessor: 'wharehouse',
    }, 
    {
        Header: "Temperatura",
        accessor: 'warehouse_temp',
    }, 


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns