import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'

import { Loading } from 'components'

import { DetailContainerI } from './types'
import routes from 'app/routing/routes'
import { OrderTypeI } from 'interfaces'

const DetailContainer: FC<DetailContainerI> = ({ loading = false, title, status, statusV2, buttons, tipoOrdenV2, irVersion2 = false, children }) => {
  const navigate = useNavigate()

  return (
    <Card className="mb-8">
      <Card.Header>
        <div className='pt-8 pb-5'>
          <h3 className='me-5 d-inline' style={{verticalAlign:"middle"}}>{title}</h3>
          {status && <span className='fs-8 p-2 rounded ms-5' style={{ backgroundColor: 'yellow' }}>{status}</span>}
          {statusV2}
        </div>
        {/* <div className="badge estado-verde justify-content-center ms-5 fw-normal" style={{ width: '150px', marginTop: '5px' }}>
          Completo
        </div> */}

        {/* <Card.Title className="mb-0">
          {title}
          {status && <span className='fs-8 ms-4 p-2 rounded' style={{ backgroundColor: 'yellow' }}>{status}</span>}
          {statusV2}
        </Card.Title> */}

        {irVersion2 &&
          <div className='col text-end pt-5'>
            <Button
              type='button'
              variant='secondary'
              onClick={() => {
                let link = routes.VENTAS_PEDIDOS_CREAR

                if (tipoOrdenV2 == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO) {
                  link = routes.VENTAS_PEDIDOS_ALMACENAMIENTO_PROPIO
                } else if (tipoOrdenV2 == OrderTypeI.PEDIDO_EGRESO) {
                  link = routes.VENTAS_PEDIDOS_EGRESO
                } else {
                  link = routes.VENTAS_PEDIDOS_CREAR
                }

                navigate(link)
              }}
            >
              <i className='bi bi-2-square text-primary me-1' />
              Ir a V2
            </Button>
          </div>
        }
        {buttons}
      </Card.Header>
      <Card.Body className='pt-0'>
        {loading ? <Loading /> : children}
      </Card.Body>
    </Card>
  )
}

export default DetailContainer