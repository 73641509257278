import { useState, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useDepots, useLogisticStock, useOutOrders, useSalesOrders } from 'hooks'

import { CustomTable, SelectField, Tooltip } from 'components'
import { ArticleScanModal } from './ArticleScanModal'
import { ArticleLotNumberModal } from './ArticleLotNumberModal'
import { DetailEditModal } from './DetailEditModal'

import { OrderTypeI } from 'interfaces'

const groupData = (orderDetail, alreadyScanned) => {
  const stockAgrupado = Object.values(
    alreadyScanned.reduce((acc, el) => {
      const key = `${el.product_var_id}-${el.product_var_name}-${el.gtin}-${el.lot_number}`

      if (!acc[key]) {
        acc[key] = {
          product_var_id: el.product_var_id,
          product_var_name: el.product_var_name,
          gtin: el.gtin,
          lot_number: el.lot_number,
          stock: 0
        }
      }
      acc[key].stock++

      return acc
    }, {})
  )

  // Combinar stock agrupado con cantidades esperadas y calcular faltantes
  const resultadoFinal = orderDetail.map(elemento => {
    const stockInfo = stockAgrupado
      .filter(item => item.product_var_id === elemento.product_var_id)
      .reduce((acc, item) => { return acc + item.stock }, 0)

    const pending = elemento.qty - stockInfo

    return {
      product_var_id: elemento.product_var_id,
      product_var_name: elemento.product_var_desc,
      gtin: elemento.gtin,
      qty: elemento.qty,
      returned_qty: elemento.returned_qty,
      stock: stockInfo,
      traced:elemento.traced,
      pending: pending >= 0 ? pending : 0 // faltantes no pueden ser negativos
    }
  })

  return resultadoFinal
}

const PackagingTab = ({ editing, getOutOrder, outOrder }) => {
  const { values } = useFormikContext()

  const [groupedDetail, setGroupedDetail] = useState([])
  const [detailArticles, setDetailArticles] = useState([])
  const [showLotNumberModal, setShowLotNumberModal] = useState(false)
  const [showScanModal, setShowScanModal] = useState(false)
  const [stockArticles, setStockArticles] = useState([])

  const { loading, handleGetOutStockStatus, handleLotDetail, handleUpdateOutOrder } = useOutOrders()
  const { handleGetStockDetail } = useLogisticStock()
  const { handleGetSalesOrder } = useSalesOrders()
  const { handleGetStorageSpaces } = useDepots()
  const [showDetailEditModal, setShowDetailEditModal] = useState(false)
  const [rowForEdit, setRowForEdit] = useState(null)
  const [salesOrderTypeId, setSalesOrderTypeId] = useState(null)
  const [clientStorageSpacesOptions, setClientStorageSpacesOptions] = useState([])

  const fetchAuxData = async()=>{
    if (values?.sales_order_id) {
      const _so = await handleGetSalesOrder(values.sales_order_id)

      if (_so?.data?.result) {
        setSalesOrderTypeId(_so.data.result.order_type_id)

        const _storages = await handleGetStorageSpaces(_so.data.result.customer_supplier_id)
        setClientStorageSpacesOptions(_storages.map(s => ({ ...s, value: s.id, label: s.name })))
      }
    }
  }

  const loadAlreadyScanned = async() => {
    const resp = await handleGetOutStockStatus(values.id, {})
    const data = resp?.data?.result

    if (values && data) {
      const data_grouped = groupData(values.detail, data)
      setGroupedDetail(data_grouped)
    }
  }

  const onCloseScanModal = () => {
    setShowScanModal(false)
    loadAlreadyScanned()
  }

  const onCloseLotNumberModal = async (data) => {
    try {
      if (!data) {
        return
      }

      const transfer = {
        qty: +data.qty,
        lot_number: data.lot_number,
        expiration_date: data.expiration_date,
        gtin: data.gtin,
        product_var_id: data.product_var_id,
        serial_number: data.serial_number ? data.serial_number : undefined,
        storage_space_id: data.storage_space_id ? data.storage_space_id : undefined,
        storage_space_id_destino: data.storage_space_id_destino ? data.storage_space_id_destino : undefined
      }

      const resp = await handleLotDetail(values.id, transfer)

      loadAlreadyScanned()
      toast.success("Registro actualizado correctamente")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setShowLotNumberModal(false)
    }
  }

  const updateOrder = async (new_de_blister_value) => {
    try {
      let data = {
        id: values.id,
        de_blister: new_de_blister_value,
      }
      const response = await handleUpdateOutOrder(values.id, data)
      toast.success("Orden actualizada correctamente")
    } catch (error) {
      toast.error(error.message)
    } finally {
      getOutOrder()
    }
  }

  const editarArticulo = async (row) => {
    setRowForEdit({ ...row, out_order_id: values.id })
    setShowDetailEditModal(true)
  }

  const onHideDetailEditModal = () => {
    setShowDetailEditModal(false)
    loadAlreadyScanned();
  }

  const columns = [
    {
      Header: 'Cód.',
      accessor: 'product_var_id',
    },
    {
      Header: 'Artículo',
      accessor: 'product_var_name',
    },
    {
      Header: 'GTIN',
      accessor: 'gtin',
    },
    {
      Header: 'Pedido',
      accessor: 'qty',
    },
    {
      Header: 'Pickeado',
      accessor: 'stock',
    },
    {
      Header: 'Pendiente',
      accessor: 'pending',
    },
    {
      Header: '',
      id:'acciones',
      accessor: (row) => (
        <>
          {row.stock > 0 &&
            <span>
              <i className='bi bi-pencil text-primary cursor-pointer me-3' onClick={() => editarArticulo(row)} />
            </span>
          }

          {Boolean(row.returned_qty > 0) &&
            <Tooltip text={`Tiene devoluciones (cant: ${row.returned_qty})`}>
              <span><i className='bi bi-arrow-counterclockwise text-danger'/></span>
            </Tooltip>
          }
        </>
      ),
    },
  ]

  const enrichData = async () => {
    const updatedData = await Promise.all(groupedDetail.map(async (obj) => {
      try {
        const params = {
          product_var_id:obj.product_var_id,
          customer_supplier_id:
            (values.order_type_id == OrderTypeI.PEDIDO_VENTA || values.order_type_id == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO)
              ? 1
              : values.customer_supplier_id,
          business_unit_id:values.business_unit_id
        }

        const response = await handleGetStockDetail(params)
        setStockArticles(stockArticles => ([...stockArticles, ...response.result]))

        return {
          ...obj,
          options_lot_number: response.result
            .filter(d => d.stock_available_without_reserved && +d.stock_available_without_reserved > 0)
            .map(d => ({
              value:d.lot_number,
              label: d.lot_number + ' - Vto:' + (d.expiration_date ? moment(d.expiration_date).format("DD/MM/YYYY") : ""), // + ' - ' + d.storage_space_name,
              storage_space_inventory_status_id: d.storage_space_inventory_status_id,
              available: d.stock_available_without_reserved,
              expiration_date: d.expiration_date,
              serial_number: d.serial_number,
              storage_space_id: d.storage_space_id,
              storage_space_name: d.storage_space_name
            })),
        }
      } catch (error) {
        // Hacer algo con el error
      }
    }))

    setDetailArticles(updatedData)
  }

  useEffect(() => {
    if (groupedDetail?.length > 0) {
      enrichData()
    }
  }, [groupedDetail])

  useEffect(() => {
    loadAlreadyScanned()
    setRowForEdit(null)
  }, [values])

  useEffect(() => {
    if (outOrder) {
      fetchAuxData()

      if (groupedDetail?.length > 0) {
        enrichData()
      }
    }
  }, [outOrder.id])

  return (
    <>
      <ArticleScanModal
        show={showScanModal}
        onHide={onCloseScanModal}
        getOutOrder={getOutOrder}
        stockArticles={stockArticles}
        salesOrderTypeId={salesOrderTypeId}
        clientStorageSpacesOptions={clientStorageSpacesOptions}
      />

      <ArticleLotNumberModal
        loading={loading}
        show={showLotNumberModal}
        onHide={onCloseLotNumberModal}
        requestArticles={
          detailArticles
            ?.filter(d => d.pending > 0)
            .map(a => ({
              ...a,
              value: a.product_var_id,
              label: a.product_var_name
            }))
        }
        salesOrderTypeId={salesOrderTypeId}
        clientStorageSpacesOptions={clientStorageSpacesOptions}
      />

      <DetailEditModal
        show={showDetailEditModal}
        onHide={onHideDetailEditModal}
        rowForEdit={rowForEdit}
        canEdit={!outOrder.remito_id}
        salesOrderTypeId={salesOrderTypeId}
      />

      <div className="card-body pt-0">
        <div className="row mb-7">
          <div className="col-2">
            <SelectField
              id='de_blister'
              name='de_blister'
              label='Destroquelar'
              options={[
                { value: true, label: 'Si' },
                { value: false, label: 'No' },
              ]}
              handleChange={(e) => updateOrder(e.value)}
              defaultValue={values.de_blister}
              placeholder='¿Destroquelar?'
              disabled={true}
            />
          </div>

          {groupedDetail.reduce((acc,el) => +acc + +el.pending,0) > 0 && outOrder?.wave_id != null
            ?
              <div className="col text-end mt-5">
                <Button variant='primary' onClick={() => setShowScanModal(true)} className='me-4'>
                  <i className='bi bi-qr-code-scan me-2 mb-1' />
                  GS1
                </Button>

                <Button variant='primary' onClick={() => setShowLotNumberModal(true)}>
                  <i className='bi bi-hand-index-thumb me-2 mb-1' />
                  Carga manual
                </Button>
              </div>
            : <></>
          }
        </div>

        <CustomTable columns={columns} data={groupedDetail || []} />
      </div>
    </>
  )
}

export default PackagingTab