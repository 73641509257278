import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'

import { useOutOrders } from 'hooks'

import { InputBS } from 'components'
import { toast } from 'react-toastify'

const initialValues = {
  out_order_detail_id: '',
  product_var_id: '',
  product_var_desc: '',
  qty: 0,
  cantidad_liberar: 0,
}

const LiberarReservaArticuloModal = ({ show, onClose, articulo }) => {
  const { id } = useParams()
  const [data, setData] = useState(initialValues)

  const { handleLiberarReservaArticulo } = useOutOrders()

  const liberarReserva = async (values) => {
    try {
      console.log(`Detalle #${values.out_order_detail_id}`, values)

      const response = await handleLiberarReservaArticulo(id, values.out_order_detail_id, values)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (show) {
      setData({
        out_order_detail_id: articulo.id,
        product_var_id: articulo.product_var_id,
        product_var_desc: articulo.product_var_desc,
        qty: articulo.qty,
        cantidad_liberar: articulo.qty,
      })
    } else {
      setData(initialValues)
    }
  }, [show, articulo])

  if (!show) {
    return null
  }

  return (
    <Modal show={show} onHide={onClose} size='xl' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Liberar reserva del artículo [{articulo.product_var_id}] {articulo.product_var_desc}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={data}
          onSubmit={liberarReserva}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-2'>
                    <InputBS id='product_var_id' name='product_var_id' label='ID' readOnly />
                  </div>

                  <div className='col-10'>
                    <InputBS id='product_var_desc' name='product_var_desc' label='Artículo' readOnly />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-11'></div>

                  <div className='col-1'>
                    <InputBS
                      id='cantidad_liberar'
                      name='cantidad_liberar'
                      type='number'
                      label='Cantidad'
                      onChange={e => {
                        let value = e.target.value
                        setFieldValue('cantidad_liberar', value)
                      }}
                      min={0}
                      max={values.qty}
                    />
                  </div>
                </div>

                <div className='separator my-8' />

                <div className='row'>
                  <div className='col text-end'>
                    <Button type='button' variant='secondary' onClick={onClose} className='me-3'>
                      <i className='bi bi-slash-circle' />
                      Cerrar
                    </Button>

                    <Button type='submit' variant='primary'>
                      <i className='bi bi-box-arrow-up-right me-1' />
                      Liberar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default LiberarReservaArticuloModal