import { useState, useCallback } from 'react';

import { Logistic } from 'services';



const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useIncomeOrder = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);
  
  const handleGetIncomeOrders = useCallback(async (params) => {
    try {
      setLoading(true);

      const response = await Logistic.getIncomeOrders(params)

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetIncomeOrderDetailsWithErrors = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Logistic.getIncomeOrderDetailsWithErrors(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetIncomeOrder = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Logistic.getIncomeOrder(id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateIncomeOrder = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.createIncomeOrder(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateIncomeOrder = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.updateIncomeOrder(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateIncomeOrderDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.createIncomeOrderDetail(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateIncomeOrderDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      console.log(data)
      const response = await Logistic.updateIncomeOrderDetail(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleIncomeOrderDetailManualTraced = useCallback(async (in_order_id, in_order_detail_id) => {
    try {
      setLoading(true)
      const response = await Logistic.incomeOrderDetailManualTraced(in_order_id, in_order_detail_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDetailStorageSpace = useCallback(async (data) => {
    try {
      setLoading(true);
      console.log(data)
      const response = await Logistic.updateDetailStorageSpace(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);
  


  const handleDeleteIncomeOrderDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.deleteIncomeOrderDetail(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteSerialIncomeOrderDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.deleteSerialIncomeOrderDetail(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);
  
  const handleConfirmIncomeOrder = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Logistic.confirmIncomeOrder(id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCancelarOrdenIngreso = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Logistic.cancelarOrdenIngreso(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleConfirmIncomeOrderForced = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Logistic.confirmIncomeOrderForced(id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDuplicateIncomeOrderDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.duplicateIncomeOrderDetail(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetTracers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Logistic.getTracers();
      return response?.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSetInvoice = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.setInvoice(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSetWaybill = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Logistic.setWaybill(data)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleReintentarTraza = useCallback(async (in_order_id) => {
    try {
      setLoading(true);
      const response = await Logistic.reintentarTraza(in_order_id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateLotNumberInTraceError = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Logistic.updateLotNumberInTraceError(data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetTransaccionesNoConfirmadas = useCallback(async (in_order_id) => {
    try {
      setLoading(true);
      const response = await Logistic.transaccionesNoConfirmadas(in_order_id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);
  


  return {
    loading,
    queryString,
    setQueryString,
    handleGetTracers,
    handleGetIncomeOrders,
    handleGetIncomeOrder,
    handleGetIncomeOrderDetailsWithErrors,
    handleCreateIncomeOrder,
    handleCreateIncomeOrderDetail,
    handleUpdateIncomeOrderDetail,
    handleDeleteIncomeOrderDetail,
    handleIncomeOrderDetailManualTraced,
    handleDuplicateIncomeOrderDetail,
    handleDeleteSerialIncomeOrderDetail,
    handleUpdateDetailStorageSpace,
    handleConfirmIncomeOrder,
    handleCancelarOrdenIngreso,
    handleConfirmIncomeOrderForced,
    handleSetInvoice,
    handleSetWaybill,
    handleUpdateIncomeOrder,
    handleReintentarTraza,
    handleUpdateLotNumberInTraceError,
    handleGetTransaccionesNoConfirmadas
  }
}

export default useIncomeOrder;