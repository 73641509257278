import { createContext, useContext, useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useBusinessUnits, useSellers, usePaymentConditions, useBillingTypes, usePriceUpdate } from 'hooks'

const GeneralDataContext = createContext()

export const GeneralDataProvider = ({ children }) => {
  const [unidadesNegocio, setUnidadesNegocio] = useState([])
  const [vendedores, setVendedores] = useState([])
  const [condicionesPago, setCondicionesPago] = useState([])
  const [tiposFacturacion, setTiposFacturacion] = useState([])
  const [actualizacionPrecios, setActualizacionPrecios] = useState([])

  const [hasFetched, setHasFetched] = useState(false)

  const { loading: loadingBU, handleGetBusinessUnits } = useBusinessUnits()
  const { loading: loadingS, handleGetSellers } = useSellers()
  const { loading: loadingPC, handleGetPaymentConditions } = usePaymentConditions()
  const { loading: loadingBT, handleGetBillingTypes } = useBillingTypes()
  const { loading: loadingPU, handleGetPriceUpdates } = usePriceUpdate()

  const memoizedGetBusinessUnits = useCallback(handleGetBusinessUnits, [])
  const memoizedGetSellers = useCallback(handleGetSellers, [])
  const memoizedGetPaymentConditions = useCallback(handleGetPaymentConditions, [])
  const memoizedGetBillingTypes = useCallback(handleGetBillingTypes, [])
  const memoizedGetPriceUpdates = useCallback(handleGetPriceUpdates, [])

  useEffect(() => {
    if (hasFetched) return // Evita llamadas redundantes

    const fetchData = async () => {
      try {
        const [businessUnitsResponse, sellersResponse, paymentConditionsResponse, billingTypesResponse, priceUpdateResponse] = await Promise.all([
          memoizedGetBusinessUnits({ order_by: 'ASC' }),
          memoizedGetSellers(null, '', { is_active: 1 }),
          memoizedGetPaymentConditions({}),
          memoizedGetBillingTypes({ is_active: 1 }),
          memoizedGetPriceUpdates({}),
        ])

        setUnidadesNegocio(
          businessUnitsResponse.data.result.map(d => ({
            value: d.id,
            label: d.name,
          }))
        )

        setVendedores(
          sellersResponse.data.result.map(d => ({
            value: d.id,
            label: `[${d.id}] ${d.first_name} ${d.last_name}`,
          }))
        )

        setCondicionesPago(
          paymentConditionsResponse.result.map(d => ({
            value: d.id,
            label: d.label,
          }))
        )

        setTiposFacturacion(
          billingTypesResponse.data.result.map(d => ({
            value: d.id,
            label: d.label,
          }))
        )

        setActualizacionPrecios(
          priceUpdateResponse.data.result.map(d => ({
            value: d.id,
            label: d.label,
          }))
        )

        setHasFetched(true) // Marca que ya se han obtenido los datos
      } catch (error) {
        toast.error(error.message)
      }
    }

    fetchData()
  }, [memoizedGetBusinessUnits, memoizedGetSellers, memoizedGetPaymentConditions, memoizedGetBillingTypes, memoizedGetPriceUpdates, hasFetched])

  return (
    <GeneralDataContext.Provider
      value={{
        unidadesNegocio,
        loadingBU,
        vendedores,
        loadingS,
        condicionesPago,
        loadingPC,
        tiposFacturacion,
        loadingBT,
        actualizacionPrecios,
        loadingPU,
      }}
    >
      {children}
    </GeneralDataContext.Provider>
  )
}

export const useGeneralData = () => useContext(GeneralDataContext)