import { Link } from 'react-router-dom'
import moment from 'moment';

import { Tooltip } from 'components'

import routes from 'app/routing/routes';

export const tipoOrden = (orderType) => {
  switch (orderType) {
    case 'PEDIDO_COMPRA':
      return 'PEDIDO COMPRA'
    case 'PEDIDO_DEVOLUCION':
      return 'PEDIDO DEVOLUCIÓN'
    case 'PEDIDO_INGRESO':
      return 'PEDIDO INGRESO'
    default:
      return orderType
  }
}

export const colorTipoOrden = (orderType) => {
  switch (orderType) {
    case 'PEDIDO_COMPRA':
      return 'text-success'
    case 'PEDIDO_DEVOLUCION':
      return 'text-warning'
    case 'PEDIDO_INGRESO':
      return 'text-primary'
    default:
      return ''
  }
}

const COLUMNS =  [
  {
    Header: <div className="text-center">Orden Ingreso</div>,
    id: 'nro_orden_ingreso',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text='Ver ingreso'>
          <Link to={`${routes.LOGISTIC_INCOME}/${row.nro_orden_ingreso}`} state={{ in_request_id: row.pedido_de_compra }}>
            {row.nro_orden_ingreso}
          </Link>
        </Tooltip>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <p className="m-0 p-0 text-center">Tipo</p>,
    id: 'tipo_orden',
    accessor: (row) => (
      <p className={`m-0 p-0 text-center ${colorTipoOrden(row.tipo_orden)} fw-bold`}>
        {tipoOrden(row.tipo_orden)}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <div className="text-center">F. creación</div>,
    id: 'fecha_creacion',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.fecha_creacion).format("DD/MM/YYYY")}
      </div>
    )
  },
  {
    Header: <div className="text-center">F. ingreso</div>,
    id: 'fecha_de_ingreso',
    accessor: (row) => (
      <div className='text-center'>
        {row?.fecha_de_ingreso ? moment(row.fecha_de_ingreso).format("DD/MM/YYYY") : '-'}
      </div>
    )
  },
  {
    Header: <div className="text-center">Pedido Compra</div>,
    id: 'pedido_de_compra',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text='Ver pedido'>
          <Link to={`${routes.PURCHASE_REQUEST_DETAIL}/${row.pedido_de_compra}`}>
            {row.pedido_de_compra}
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: <div className="">Proveedor</div>,
    id: 'proveedor',
    accessor: (row) => (
      <div className=''>
        <strong className='m-0 p-0'>[{row.id_proveedor}] {row.proveedor}</strong>
        <p className='m-0 p-0 mt-2'>Documento: {row.proveedor_documento}</p>
      </div>
    ),
  },
  {
    Header: <div className="">Distribuidor</div>,
    id: 'distribuidor',
    accessor: (row) => (
      <div className=''>
        {row?.distribuidor
          ?
            <>
              <strong className='m-0 p-0'>[{row.id_distribuidor}] {row.distribuidor}</strong>
              <p className='m-0 p-0 mt-2'>Documento: {row.distribuidor_documento}</p>
            </>
          : <i>Sin distribuidor</i>
        }
      </div>
    ),
  },
  {
    Header: <div className="">Artículo</div>,
    id: 'articulo',
    accessor: (row) => (
      <div>
        <strong className='m-0'>[{row.nro_articulo}] {row.articulo}</strong>
        <p className='m-0 mt-4'><strong>Ppio:</strong> {row.droga}</p>
        <p className='m-0'><strong>GTIN:</strong> {row.gtin}</p>
      </div>
    ),
  },
  {
    Header: <div className="text-center">Cantidad</div>,
    id: 'cantidad',
    accessor: (row) => (
      <div className='text-center'>
        {row.cantidad}
      </div>
    ),
  },
  {
    Header: <div className="text-center">Estado</div>,
    id: 'estado',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.estado}
      </span>
    ),
  },
  {
    Header: <div className="text-center">Lote/Vto</div>,
    id: 'lote',
    accessor: (row) => (
      <div className='text-center'>
        <strong className='text-center'>{row.lote}</strong>
        <p className='m-0 fs-8'>{row.vencimiento ? moment(row.vencimiento).format("DD/MM/YYYY") : ''}</p>
      </div>
    ),
  },
]

export {
  COLUMNS
}