import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

import { labelColor } from '..'
import { SalesOrderStatusI } from 'interfaces'

export const useGetColumns = (selectedRows, handleRowSelection) => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: '',
      id: 'selected',
      accessor: (row) => (
        row.PedidoEstadoId === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA
          ?
            <input
              name="selected"
              type="checkbox"
              value={row.PedidoId}
              onChange={() => handleRowSelection(row.PedidoId, row.PedidoEstadoId)}
              checked={selectedRows.some(i => i.PedidoId === row.PedidoId)}
              // disabled={row.PedidoEstadoId !== SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA}
            />
          : <></>
      )
    },
    {
      Header:  <div className="text-center">ID Pedido</div>,
      id: 'PedidoId',
      accessor: (row) => (
        <div className="text-center">
          <Link to={`${routes.SALES_ORDERS}/${row.PedidoId}`}>
            {row.PedidoId}
          </Link>
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>F. Envío Aut.</div>,
      id: 'FechaEnvioAutorizacion',
      accessor: (row) => (
        <>
          <p className='text-center m-0'>{row?.FechaEnvioAutorizacion ? moment(row.FechaEnvioAutorizacion).format('DD/MM/YYYY') : '-'}</p>
          <p className='text-center m-0'>{row?.FechaEnvioAutorizacion ? moment(row.FechaEnvioAutorizacion).format('HH:mm') : ''}</p>
        </>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-left'>Cliente</div>,
      id: 'ClienteNombre',
      accessor: (row) => (
        <div className='text-left'>
          {row.ClienteNombre}
        </div>
      )
    },
    {
      Header: <div className='text-left'>Cond. Cliente</div>,
      id: 'CondicionPagoCliente',
      accessor: (row) => (
        <div className='text-left'>
          {row.CondicionPagoCliente}
        </div>
      )
    },
    {
      Header: <div className='text-left'>Cond. Venta</div>,
      id: 'CondicionPagoVenta',
      accessor: (row) => (
        <div className='text-left'>
          {row.CondicionPagoVenta}
        </div>
      )
    },
    {
      Header: <div className='text-end'>Total</div>,
      id: 'total',
      accessor: (row) => (
        <div className='text-end'>
          {currencyFormat.format(parseFloat(row.Total).toFixed(2))}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Renta</div>,
      id: 'RentaListaPedido',
      accessor: (row) => (
        <div className='text-center'>
          {row.RentaListaPedido}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id: 'PedidoEstadoId',
      accessor: (row) => (
        <>
          <span className={`badge ${labelColor(row.PedidoEstadoId)} w-100 justify-content-center fw-normal`}>
            {row.EstadoLabel}
          </span>
        </>
      ),
    },
  ]

  return {
    COLUMNS
  }
}