import { useState, useCallback, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useBusinessUnits, useCustomerSuppliers, useSellers, usePaymentConditions, useBillingTypes } from 'hooks'

import { InputBS, Label, SelectField, SwitchField, Error } from 'components'

import 'react-bootstrap-typeahead/css/Typeahead.css';

const TabGeneral = ({ loading }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const { loading: loadingBU, handleGetBusinessUnits } = useBusinessUnits()
  const [unidadesNegocio, setUnidadesNegocio] = useState([])

  const { loading: loadingCS, handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [cargandoClientes, setCargandoClientes] = useState(false)
  const [clientes, setClientes] = useState([])

  const { loading: loadingS, handleGetSellers } = useSellers()
  const [vendedores, setVendedores] = useState([])

  const { loading: loadingPC, handleGetPaymentConditions } = usePaymentConditions()
  const [condicionesPago, setCondicionesPago] = useState([])

  const { loading: loadingBT, handleGetBillingTypes } = useBillingTypes()
  const [tiposFacturacion, setTiposFacturacion] = useState([])

  const getUnidadesNegocio = useCallback(async () => {
    try {
      let params = {
        order_by: 'ASC'
      }
      const response = await handleGetBusinessUnits(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.name,
      }))

      setUnidadesNegocio(data)
    } catch (error) {
      setUnidadesNegocio([])
      toast.error(error.message)
    }
  }, [handleGetBusinessUnits])

  const buscarClientes = async (query) => {
    setCargandoClientes(true)

    try {
      let params = {
        cliente: query,
        sortField: 'id',
        sortOrder: 'ASC',
      }
      const response = await handleGetCustomerSuppliers(null, null, params)
      const data = response.data.result.map(d => {
        const {
          id,
          business_name,
          documentation_type_name,
          documentation,
          seller_id,
          seller,
          payment_condition_id,
          billing_type_id,
          price_update_id,
          de_blister
        } = d

        const dataCliente = {
          id,
          business_name,
          seller_id,
          seller,
          payment_condition_id,
          billing_type_id,
          price_update_id,
          de_blister,
          label: `[${id}] ${business_name} | ${documentation_type_name}: ${documentation}`,
        }

        return dataCliente
      })

      setClientes(data)
    } catch (error) {
      setClientes([])
      toast.error(error.message)
    } finally {
      setCargandoClientes(false)
    }
  }

  const getVendedores = useCallback(async () => {
    try {
      let params = {
        is_active: 1,
      }
      const response = await handleGetSellers(null, '', params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `[${d.id}] ${d.first_name} ${d.last_name}`
      }))

      setVendedores(data)
    } catch (error) {
      setVendedores([])
      toast.error(error.message)
    }
  }, [handleGetSellers])

  const getCondicionesPago = useCallback(async () => {
    try {
      let params = {
        // is_enabled: 1,
      }
      const response = await handleGetPaymentConditions(params)
      const data = response.result.map(d => ({
        value: d.id,
        label: d.label,
      }))

      setCondicionesPago(data)
    } catch (error) {
      setCondicionesPago([])
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  const getTiposFacturacion = useCallback(async () => {
    try {
      let params = {
        is_active: 1,
      }
      const response = await handleGetBillingTypes(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label,
      }))

      setTiposFacturacion(data)
    } catch (error) {
      setTiposFacturacion([])
      toast.error(error.message)
    }
  }, [handleGetBillingTypes])

  useEffect(() => {
    getUnidadesNegocio()
    getVendedores()
    getCondicionesPago()
    getTiposFacturacion()
  }, [getUnidadesNegocio, getVendedores, getCondicionesPago, getTiposFacturacion])

  return (
    <>
      <div className='row my-8'>
        <div className='col-2'>
          <InputBS id='issue_date' name='issue_date' type='date' label='F. emisión' disabled />
        </div>

        <div className='col-2'>
          <SelectField
            id='business_unit_id'
            name='business_unit_id'
            label='Unidad de negocio'
            options={unidadesNegocio}
            placeholder='Seleccionar'
            disabled={loading || loadingBU}
          />
        </div>

        <div className='col-8'>
          <Label label='Cliente' htmlFor='customer_supplier_id' />

          <AsyncTypeahead
            id='customer-supplier-typeahead'
            className='z-index-2'
            isLoading={cargandoClientes}
            onSearch={(query) => {
              if (query.length >= 1) {
                buscarClientes(query)
              }
            }}
            options={clientes}
            labelKey={opt => opt.label}
            onChange={selected => {
              if (!selected || selected.length === 0) {
                setFieldValue('customer_supplier_id', '')
                setFieldValue('customer_supplier_name', '')
                setFieldValue('seller_id', '')
                setFieldValue('payment_condition_id', '')
                setFieldValue('billing_type_id', '')
                setFieldValue('price_update_id', '')
                setFieldValue('patient_id', '')
                setFieldValue('address_id_traza', '')
                setFieldValue('address_id_ship', '')
                setFieldValue('de_blister', '')
              } else {
                const { id, business_name, seller_id, seller, payment_condition_id, billing_type_id, price_update_id, de_blister } = selected[0]

                setFieldValue('customer_supplier_id', id)
                setFieldValue('customer_supplier_name', `[${id}] ${business_name}`)
                setFieldValue('seller_id', seller_id && Boolean(seller?.is_enabled) ? seller_id : '')
                setFieldValue('payment_condition_id', payment_condition_id)
                setFieldValue('billing_type_id', billing_type_id ? billing_type_id : '')
                setFieldValue('price_update_id', price_update_id ? price_update_id : '')
                setFieldValue('de_blister', de_blister ? de_blister : '')
              }
            }}
            placeholder='Buscar cliente (ID, Razón social y/o Documento)'
            disabled={loading}
          />

          <ErrorMessage className='text-danger' name='customer_supplier_id' render={msg => <Error message={msg} />} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-6'>
          <SelectField
            id='seller_id'
            name='seller_id'
            label='Vendedor'
            options={vendedores}
            placeholder='Seleccionar'
            disabled={loading || loadingS}
          />
        </div>

        <div className='col-6'>
          <SelectField
            id='payment_condition_id'
            name='payment_condition_id'
            label='Condición de pago'
            options={condicionesPago}
            placeholder='Seleccionar'
            disabled={loading || loadingPC}
          />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-4'>
          <InputBS
            id='purchase_order'
            name='purchase_order'
            label='Nro. Orden de compra'
            placeholder='Orden de compra'
            disabled={loading}
          />
        </div>

        <div className='col-2'>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label='Facturación'
            options={tiposFacturacion}
            placeholder='Seleccionar'
            disabled={loading || loadingBT}
          />
        </div>

        <div className="col-2 d-flex justify-content-start align-items-center"
        style={{ paddingTop: "20px" }}
        >
          <div className="d-flex">
            <SwitchField id='bidding' name='bidding' disabled={loading} />
            <span style={{ fontSize: '1.1rem' }}>
              Licitación
            </span>
          </div>
        </div>

        {Boolean(values.bidding) &&
          <div className='col-3'>
            <InputBS id='bidding_value' name='bidding_value' label='Nro. Licitación' placeholder='Licitación' disabled={loading} />
          </div>
        }
      </div>
    </>
  )
}

export default TabGeneral