import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';

import { useAuth } from 'app/modules/auth'
import { useReports } from 'hooks'

import { Input, SelectField } from 'components'

import { statusDisplay } from 'pages/Administrative/Documents/List'
import { Reports } from '../../../services/models/Reports';

const optionsComprobantes = [
  { value: 1, label: "Factura" },
  { value: 2, label: "Nota de Débito" },
  { value: 3, label: "Nota de Crédito" },
]

const optionsBusinessUnits = [
  { value: 'riga_farma', label: 'Farma' },
  { value: 'mi_test', label: 'Mi test' }
]

const initialValues = {
  id: '',
  sistema: [],
  sales_order_id: '',
  out_order_id: '',
  transactional_document_type_id: [],
  date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD'),
  razon_social: '',
  documento: '',
  status_filter: [],
  comprobante: '',
  article_text: '',
}

const Filter = ({ setData, queryMD, setQueryMD, setTotalCount, getExport, setConDetalle }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetTransactionalDocumentsReport } = useReports()

  const filterData = async (values) => {
    let params = {
      ...queryMD,
      ...values,
      id: values?.id ? values.id : undefined,
      sistema: values?.sistema ? values.sistema : undefined,
      sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
      out_order_id: values?.out_order_id ? values.out_order_id : undefined,
      transactional_document_type_id: values?.transactional_document_type_id ? values.transactional_document_type_id : undefined,
      date_from: values?.date_from ? values.date_from : undefined,
      date_to: values?.date_to ? values.date_to : undefined,
      razon_social: values?.razon_social ? values.razon_social : undefined,
      documento: values?.documento ? values.documento : undefined,
      status_filter: values?.status_filter ? values.status_filter : undefined,
      comprobante: values?.comprobante ? values.comprobante : undefined,
    }

    setCurrentUser((prevCurrentUser) => ({
      ...prevCurrentUser,
      reporte_comprobantes: { ...params }
    }))

    setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
  }

  const exportData = async (values) => {
    try {
      const params = {
        ...values,
        id: values?.id ? values.id : undefined,
        sistema: values?.sistema ? values.sistema : undefined,
        sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
        out_order_id: values?.out_order_id ? values.out_order_id : undefined,
        transactional_document_type_id: values?.transactional_document_type_id ? values.transactional_document_type_id : undefined,
        date_from: values?.date_from ? values.date_from : undefined,
        date_to: values?.date_to ? values.date_to : undefined,
        razon_social: values?.razon_social ? values.razon_social : undefined,
        documento: values?.documento ? values.documento : undefined,
        status_filter: values?.status_filter ? values.status_filter : undefined,
        comprobante: values?.comprobante ? values.comprobante : undefined,
        pageNumber: 1,
        pageSize: undefined,
      }

      const response = await getExport(params)

      if (response) {
        const datos = response.map(d => {
          const data = {
            id_comprobante: d.id_comprobante,
            estado: statusDisplay(d.estado),
            sistema: d.sistema,
            fecha_emision: d.fecha_emision ? moment(d.fecha_emision).format("DD/MM/YYYY") : "",
            letra: d.letra,
            cae: d.cae,
            nro_completo: d.nro_completo,
            ids_pedidos: d.ids_pedidos,
            ids_ordenes: d.ids_ordenes,
            ids_remitos: d.ids_remitos,
            cliente: d.cliente,
            tipo_comprobante_afip: d.tipo_comprobante_afip,
            tipo_comprobante_agrupado: d.tipo_comprobante_agrupado,
            tipo_comprobante_agrupado_desc: d.tipo_comprobante_agrupado_desc,
            importe_gravado: d.importe_gravado,
            importe_no_gravado: d.importe_no_gravado,
            importe_exento: d.importe_exento,
            importe_subtotal: d.importe_subtotal,
            importe_iva: d.importe_iva,
            importe_otros_tributos: d.importe_otros_tributos,
            importe_total: d.importe_total,
            forma_pago: d.forma_pago,
            id_comprobante_cancelacion: d.comprobante_cancelacion?.id,
            comprobante_cancelacion_fecha: d.comprobante_cancelacion?.fecha ? moment(d.comprobante_cancelacion.fecha).format('DD/MM/YYYY') : '',
            comprobante_cancelacion_nro_completo: d.comprobante_cancelacion?.nro_completo,
            comprobante_cancelacion_tipo_comprobante_afip: d.comprobante_cancelacion?.tipo_comprobante_afip,
            comprobante_cancelacion_tipo_comprobante_agrupado: d.comprobante_cancelacion?.tipo_comprobante_agrupado,
            comprobante_cancelacion_tipo_comprobante_agrupado_desc: d.comprobante_cancelacion?.tipo_comprobante_agrupado_desc,
            comprobante_cancelacion_motivo: d.comprobante_cancelacion?.motivo,
          }

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Comprobantes")
        XLSX.writeFile(wb, `Comprobantes ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const exportDetailData = async (values) => {
    try {
      const params = {
        ...values,
        id: values?.id ? values.id : undefined,
        sistema: values?.sistema ? values.sistema : undefined,
        sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
        out_order_id: values?.out_order_id ? values.out_order_id : undefined,
        transactional_document_type_id: values?.transactional_document_type_id ? values.transactional_document_type_id : undefined,
        date_from: values?.date_from ? values.date_from : undefined,
        date_to: values?.date_to ? values.date_to : undefined,
        razon_social: values?.razon_social ? values.razon_social : undefined,
        documento: values?.documento ? values.documento : undefined,
        status_filter: values?.status_filter ? values.status_filter : undefined,
        comprobante: values?.comprobante ? values.comprobante : undefined,
        pageNumber: 1,
        pageSize: undefined,
        addDetail: 1
      }

      const response = await getExport(params)

      if (response) {
        const datos = response.map(d => {
          const data = {
            // ...d,
            id_comprobante: d.id_comprobante,
            estado: statusDisplay(d.estado),
            sistema: d.sistema,
            fecha_emision: d.fecha_emision ? moment(d.fecha_emision).format("DD/MM/YYYY") : "",
            letra: d.letra,
            cae: d.cae,
            nro_completo: d.nro_completo,
            ids_pedidos: d.ids_pedidos,
            ids_ordenes: d.ids_ordenes,
            ids_remitos: d.ids_remitos,
            cliente: d.cliente,
            tipo_comprobante_afip: d.tipo_comprobante_afip,
            tipo_comprobante_agrupado: d.tipo_comprobante_agrupado,
            tipo_comprobante_agrupado_desc: d.tipo_comprobante_agrupado_desc,
            importe_gravado: d.importe_gravado,
            importe_no_gravado: d.importe_no_gravado,
            importe_exento: d.importe_exento,
            importe_subtotal: d.importe_subtotal,
            importe_iva: d.importe_iva,
            importe_otros_tributos: d.importe_otros_tributos,
            importe_total: d.importe_total,
            forma_pago: d.forma_pago,
            id_comprobante_cancelacion: d.comprobante_cancelacion?.id,
            comprobante_cancelacion_fecha: d.comprobante_cancelacion?.fecha ? moment(d.comprobante_cancelacion.fecha).format('DD/MM/YYYY') : '',
            comprobante_cancelacion_nro_completo: d.comprobante_cancelacion?.nro_completo,
            comprobante_cancelacion_tipo_comprobante_afip: d.comprobante_cancelacion?.tipo_comprobante_afip,
            comprobante_cancelacion_tipo_comprobante_agrupado: d.comprobante_cancelacion?.tipo_comprobante_agrupado,
            comprobante_cancelacion_tipo_comprobante_agrupado_desc: d.comprobante_cancelacion?.tipo_comprobante_agrupado_desc,
            comprobante_cancelacion_motivo: d.comprobante_cancelacion?.motivo,
            item_nro_linea: d.item_nro_linea,
            item_codigo: d.item_codigo,
            item_codigo_mtx: d.item_codigo_mtx,
            item_descripcion: d.item_descripcion,
            item_cantidad: d.item_cantidad,
            item_precio_unitario: d.item_precio_unitario,
            item_importe_iva: d.item_importe_iva,
            item_importe: d.tipo_comprobante_agrupado == 3 ? d.item_importe * -1 : d.item_importe,
          }

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Comprobantes - Detalle")
        XLSX.writeFile(wb, `Comprobantes - Detalle ${moment().format("YYYYMMDD-HHmm")}.xlsx` )
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const txtFacturasMedife = async (values) => {
    //console.log("txtFacturasMedife-values ",values);
    try {
      const facturasIds = await fetchFacturasIds(values);
      if (facturasIds && facturasIds.length > 0) {
        //console.log("IDs de remitos:", facturasIds);
        await sendFacturasTxtMedife(facturasIds);
        toast.success('¡Archivo TXT de facturas generado exitosamente!');
      } else {
        toast.error('No se encontraron IDs de facturas.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  

  const fetchFacturasIds = async (values) => {
    try {
      const params = {
        ...values,
        id: values?.id ? values.id : undefined,
        sistema: values?.sistema ? values.sistema : undefined,
        sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
        out_order_id: values?.out_order_id ? values.out_order_id : undefined,
        transactional_document_type_id: values?.transactional_document_type_id ? values.transactional_document_type_id : undefined,
        date_from: values?.date_from ? values.date_from : undefined,
        date_to: values?.date_to ? values.date_to : undefined,
        razon_social: values?.razon_social ? values.razon_social : undefined,
        documento: values?.documento ? values.documento : undefined,
        status_filter: values?.status_filter ? values.status_filter : undefined,
        comprobante: values?.comprobante ? values.comprobante : undefined,
        pageNumber: 1,
        pageSize: undefined
      }
  
      const response = await getExport(params);
  
      if (response) {
        //console.log('IDs de comprobantes:', response.map(d => d.id_comprobante));
        // Extraer los IDs de los comprobantes
        return response.map(d => d.id_comprobante);
       
      }
    } catch (error) {
      toast.error(error.message);
      throw error; // Propagar el error para manejarlo más arriba si es necesario
    }
  }
  

  const sendFacturasTxtMedife = async (ids) => {
    try {

        //console.log("IDs para enviar:", ids);
        
        const response = await Reports.getReporteFacturasMedife(ids);

        // Verifica si la respuesta fue correcta
        if (!response) {
            throw new Error('Error al obtener el archivo desde el backend.');
        }

        //console.log("Cadena devuelta por getReporteFacturasMedife:", response);

        // Asegúrate de que la respuesta sea una cadena y no tenga el prefijo de un ZIP
        if (!response.startsWith('data:text/plain;base64,' )) {
            throw new Error('El formato de la respuesta no es válido.');
        }

        // Extrae la parte Base64 de la cadena
        const base64String = response.split(',')[1];

        // Decodifica la cadena Base64 a texto
        const decodedString = atob(base64String);

        // A continuación, decodificamos el string una vez más
        const finalDecodedString = atob(decodedString); // Primera decodificación
        const ultimateDecodedString = atob(finalDecodedString); // Segunda decodificación a texto legible
        
        // Crea un Blob con el contenido de texto final
        const txtBlob = new Blob([ultimateDecodedString], { type: 'text/plain;charset=utf-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(txtBlob);
        link.download = 'facturas.txt'; // Nombre del archivo descargado
        link.click();

        return response; // Retorna la cadena Base64 (opcional)
    } catch (error) {
        console.error("Error en sendRemitostxtOsprera:", error);
        toast.error(`Error: ${error.message}`);
    }
};
  
  
  const cambiaSwitchDetalle = (ev) => {
    setConDetalle(ev?.target?.checked)
  }

  useEffect(() => {
    if (currentUser && currentUser?.user_id) {
      const { reporte_comprobantes } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id: reporte_comprobantes?.id || '',
        sistema: reporte_comprobantes?.sistema || '',
        sales_order_id: reporte_comprobantes?.sales_order_id || '',
        out_order_id: reporte_comprobantes?.out_order_id || '',
        transactional_document_type_id: reporte_comprobantes?.transactional_document_type_id || '',
        date_from: reporte_comprobantes?.date_from ? reporte_comprobantes.date_from : initialValues.date_from,
        date_to: reporte_comprobantes?.date_to ? reporte_comprobantes.date_to : initialValues.date_to,
        razon_social: reporte_comprobantes?.razon_social || '',
        documento: reporte_comprobantes?.documento || '',
        status_filter: reporte_comprobantes?.status_filter || '',
        comprobante: reporte_comprobantes?.comprobante || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-1'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='sistema'
                name='sistema'
                options={optionsBusinessUnits || []}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Sistema</p>
            </div>

            <div className='col-1'>
              <Input id='sales_order_id' name='sales_order_id' placeholder='Ej: 1234, 4321' disabled={loading} />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className='col-1'>
              <Input id='out_order_id' name='out_order_id' placeholder='Ej: 1234, 4321' disabled={loading} />
              <p className='text-muted'>ID O. egreso</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='transactional_document_type_id'
                name='transactional_document_type_id'
                options={optionsComprobantes || []}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Tipo de comprobante</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='razon_social' name='razon_social' disabled={loading} />
              <p className='text-muted'>Razón social</p>
            </div>

            <div className='col-2'>
              <Input id='documento' name='documento' disabled={loading}/>
              <p className='text-muted'>Documento</p>
            </div>

            {/* <div className='col-4'>
              <SelectField
                id='status_filter'
                name='status_filter'
                options={[]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div> */}

            <div className='col-4'>
              <Input id='comprobante' name='comprobante' disabled={loading} />
              <p className='text-muted'>Letra / Punto de venta / Comprobante / CAE</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>ID / Artículo / Droga</p>
            </div>
          </div>

          <div className='col'>
            <div className='col-12 text-end'>
              <Dropdown className='d-inline me-3'>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic"><i className="bi bi-file-excel pe-3"/>Exportar</Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "200px" }}>
                  <Dropdown.Item onClick={() => exportData(values)} disabled={false}>Cabecera</Dropdown.Item>
                  <Dropdown.Item onClick={() => exportDetailData(values)} disabled={false}>Detalle</Dropdown.Item>
                  <Dropdown.Item onClick={() => txtFacturasMedife(values)} disabled={false}>Txt-Fc-Medife</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search me-2" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter