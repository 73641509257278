import { useState, useEffect} from 'react'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import axios from 'axios';
import { Reports } from '../../../services/models/Reports';

import { useAuth } from 'app/modules/auth'
import { useReports } from 'hooks'

import { Input, SelectField, SwitchField } from 'components'

import { labelColor, statusDisplay, labelColorSinComprobantes } from './constants'

import * as XLSX from 'xlsx';

const initialValues = {
  id: '',
  id_text: '',
  orden_compra: '',
  from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  to_date: moment().format('YYYY-MM-DD'),
  text: '',
  paciente: '',
  paciente_documento: '',
  remito: '',
  comprobante: '',
  sin_comprobantes: 0,
  verifarma: '',
  direccion: '',
  localidad: '' ,
  // provincia: '',
  articulo: '',
  laboratorio: '',
}

const Filter = ({ query, setQuery, exportData, exportDataDetail, handleExportVerifarma, conComprobantes = false, handleConComprobantes, data }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetReporteRemitos } = useReports()

  const filterValues = (values) => {
    let params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      id_text: values?.id_text || undefined,
      orden_compra: values?.orden_compra || undefined,
      from_date: values?.from_date || undefined,
      to_date: values?.to_date || undefined,
      text: values?.text || undefined,
      paciente: values?.paciente || undefined,
      paciente_documento: values?.paciente_documento || undefined,
      remito: values?.remito || undefined,
      comprobante: values?.comprobante || undefined,
      sin_comprobantes: values?.sin_comprobantes || undefined,
      verifarma: values?.verifarma || undefined,
      direccion: values?.direccion || undefined,
      localidad: values?.localidad || undefined,
      // provincia: values?.provincia || undefined,
      articulo: values?.articulo || undefined,
      laboratorio: values?.laboratorio || undefined,
    }

    // if (values?.sin_comprobantes) {
    //   handleConComprobantes(true)
    // } else {
    //   handleConComprobantes(false)
    // }

    return params
  }

  const handleSubmit = (values) => {
    const params = {
      ...filterValues(values),
      pageNumber: 1,
      pageSize: 10,
    }

    setCurrentUser((prevCurrentUser) => ({
      ...prevCurrentUser,
      reporte_remitos: { ...params }
    }))

    setQuery({ ...params, pageNumber: 1, pageSize: 10 })
  }

  const txtRemitosOsprera = async () => {
    try {
      const params = {
        ...query,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteRemitos(params)
      var remitoIds = response.data.result.map(remito => remito.RemitoId);
      //console.log(remitoIds);
      //const remitoIds = data.map(row => row.RemitoId);
      if (remitoIds && remitoIds.length > 0) {
        //console.log("IDs de remitos:", remitoIds);
        await sendRemitostxtOsprera(remitoIds);
        toast.success('¡Archivo TXT de remitos OSPRERA generado exitosamente!');
      } else {
        toast.error('No se encontraron IDs de remitos.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const sendRemitostxtOsprera = async (ids) => {
    try {
      const response = await Reports.getReporteRemitosOsprera(ids)

      // Verifica si la respuesta fue correcta
      if (!response) {
        throw new Error('Error al obtener el archivo desde el backend.')
      }

      // Asegúrate de que la respuesta sea una cadena y no tenga el prefijo de un ZIP
      if (!response.startsWith('data:text/plain;base64,' )) {
        throw new Error('El formato de la respuesta no es válido.')
      }

      // Extrae la parte Base64 de la cadena
      const base64String = response.split(',')[1]

      // Decodifica la cadena Base64 a texto
      const decodedString = atob(base64String)

      // A continuación, decodificamos el string una vez más
      const finalDecodedString = atob(decodedString) // Primera decodificación
      const ultimateDecodedString = atob(finalDecodedString) // Segunda decodificación a texto legible

      // Crea un Blob con el contenido de texto final
      const txtBlob = new Blob([ultimateDecodedString], { type: 'text/plain;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(txtBlob)
      link.download = 'remitos.txt' // Nombre del archivo descargado
      link.click()

      return response; // Retorna la cadena Base64 (opcional)
    } catch (error) {
      console.error("Error en sendRemitostxtOsprera:", error)
      toast.error(`Error: ${error.message}`)
    }
  }

  const txtRemitosUnionPersonal = async () => {
    try {
      const params = {
        ...query,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteRemitos(params)

      let remitoIds = response.data.result.map(remito => remito.RemitoId);

      if (remitoIds && remitoIds.length > 0) {
        await sendRemitostxtUnionPersonal(remitoIds)
        toast.success('¡Archivo TXT de remitos Union Personal generado exitosamente!')
      } else {
        toast.error('No se encontraron IDs de remitos.')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const sendRemitostxtUnionPersonal = async (ids) => {
    try {
      const response = await Reports.getReporteRemitosUnionPersonal(ids)

      if (!response) {
        throw new Error('Error al obtener el archivo desde el backend.')
      }
      if (!response.startsWith('data:text/plain;base64,' )) {
        throw new Error('El formato de la respuesta no es válido.')
      }
      const base64String = response.split(',')[1]

      const decodedString = atob(base64String)

      const finalDecodedString = atob(decodedString);
      const ultimateDecodedString = atob(finalDecodedString)

      const txtBlob = new Blob([ultimateDecodedString], { type: 'text/plain;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(txtBlob)
      link.download = 'remitos.txt';
      link.click()

      return response
    } catch (error) {
      console.error("Error en sendRemitostxtUnionPersonal:", error)
      toast.error(`Error: ${error.message}`)
    }
  }


  const csvRemitosCsvStaCruz = async () => {
    try {
      const params = {
        ...query,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteRemitos(params)
      var remitoIds = response.data.result.map(remito => remito.RemitoId);

      if (remitoIds && remitoIds.length > 0) {
        await sendXlsxRemitosCsvStaCruz(remitoIds);
        toast.success('¡Archivo CSV de remitos StaCruz generado exitosamente!');
      } else {
        toast.error('No se encontraron IDs de remitos.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  const sendXlsxRemitosCsvStaCruz = async (ids) => {
  try {
    const response = await Reports.getReporteRemitosCsvStaCruz(ids);

    if (!response) {
      throw new Error('Error al obtener el archivo desde el backend.');
    }

    if (!response.startsWith('data:text/csv;base64,')) {
      throw new Error('El formato de la respuesta no es válido.');
    }

    const base64String = response.split(',')[1];
    const decodedString = atob(base64String);

    const rows = [];
    let regex = /"([^"]*)"|([^,]+)/g;
    decodedString.split('\n').forEach(line => {
      const row = [];
      let match;
      while ((match = regex.exec(line)) !== null) {
        const value = match[1] || match[2];

        if (/^\d+(\.\d+)*,\d+$/.test(value)) {
          const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
          row.push(numericValue);
        } else {
          row.push(value);
        }
      }
      rows.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(rows);

    
    const numericColumns = [12, 13, 14]; 
    rows.forEach((row, rowIndex) => {
      if (rowIndex === 0) return; 
      numericColumns.forEach((colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
        const cell = ws[cellAddress];
        if (cell) {
          cell.t = 'n'; 
          cell.z = '#,##0.00'; 
        }
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Remitos');

    const xlsxBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([xlsxBlob], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'remitosStaCruz.xlsx';
    link.click();
  } catch (error) {
    console.error("Error en sendXlsxRemitosCsvStaCruz:", error);
    toast.error(`Error: ${error.message}`);
  }
};

  
  

  useEffect(() => {
    if (currentUser?.user_id) {
      const { reporte_remitos } = currentUser

      setInitialFormikValues((prevValues) => ({
        ...prevValues,
        id: reporte_remitos?.id || '',
        id_text: reporte_remitos?.id_text || '',
        orden_compra: reporte_remitos?.orden_compra || '',
        from_date: reporte_remitos?.from_date || (initialFormikValues?.from_date || ''),
        to_date: reporte_remitos?.to_date || (initialFormikValues?.to_date || ''),
        text: reporte_remitos?.text || '',
        paciente: reporte_remitos?.paciente || '',
        paciente_documento: reporte_remitos?.paciente_documento || '',
        remito: reporte_remitos?.remito || '',
        comprobante: reporte_remitos?.comprobante || '',
        sin_comprobantes: conComprobantes,
        verifarma: reporte_remitos?.verifarma || '',
        direccion: reporte_remitos?.direccion || '',
        localidad: reporte_remitos?.localidad || '',
        // provincia: reporte_remitos?.provincia || '',
        articulo: reporte_remitos?.articulo || '',
        laboratorio: reporte_remitos?.laboratorio || undefined,
      }))
    }
  }, [conComprobantes, currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-2'>
                <Input
                  id='id'
                  name='id'
                  disabled={loading}
                />
                <p className='text-muted'>Nro.</p>
              </div>

              <div className='col-3'>
                <Input
                  id='id_text'
                  name='id_text'
                  disabled={loading}
                />
                <p className='text-muted'>ID Pedido / ID O. Egreso / ID O. Envío</p>
              </div>

              <div className='col-3'>
                <Input
                  id='orden_compra'
                  name='orden_compra'
                  disabled={loading}
                />
                <p className='text-muted'>Orden de compra</p>
              </div>

              <div className='col-2'>
                <Input
                  id='from_date'
                  name='from_date'
                  type='date'
                  disabled={loading}
                />
                <p className='text-muted'>Desde F. remito</p>
              </div>

              <div className='col-2'>
                <Input id='to_date' name='to_date' type='date' disabled={loading} />
                <p className='text-muted'>Hasta F. remito</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <Input id='text' name='text' disabled={loading} />
                <p className='text-muted'>ID / Razón social</p>
              </div>

              <div className='col-3'>
                <Input id='paciente' name='paciente' disabled={loading} />
                <p className='text-muted'>ID / Paciente</p>
              </div>

              <div className='col-3'>
                <Input id='paciente_documento' name='paciente_documento' disabled={loading} />
                <p className='text-muted'>Documento / Nro. afiliado</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <Input id='remito' name='remito' disabled={loading} />
                <p className='text-muted'>Remito</p>
              </div>

              {conComprobantes &&
                <div className='col-4'>
                  <Input id='comprobante' name='comprobante' disabled={loading} />
                  <p className='text-muted'>Comprobante</p>
                </div>
              }

              <div className='col-4'>
                <div className="d-flex mt-3">
                  <SwitchField
                    id='sin_comprobantes'
                    name='sin_comprobantes'
                    handleChange={e => {
                      let value = e.target.checked
                      console.log(value)
                      handleConComprobantes(value)
                      setFieldValue('sin_comprobantes', value)
                      submitForm()
                    }}
                    disabled={loading}
                  />
                  <span style={{ fontSize: '1.1rem' }}>{conComprobantes ? 'Con' : 'Sin'} comprobantes</span>
                </div>
              </div>

              <div className='col-4'>
                <SelectField
                  id='verifarma'
                  name='verifarma'
                  options={[
                    { value: '' , label: 'Todos' },
                    { value: '1', label: 'Sí' },
                    { value: '0', label: 'No' },
                  ]}
                  marginTop={'-20px'}
                  placeholder='Seleccionar'
                  disabled={loading}
                />
                <p className='text-muted'>Verifarma</p>
              </div>

              <div className='col-4'>
                <Input id='direccion' name='direccion' disabled={loading} />
                <p className='text-muted'>Nombre / Domicilio / GLN</p>
              </div>

              <div className='col-4'>
                <Input id='localidad' name='localidad' disabled={loading} />
                <p className='text-muted'>Localidad / Provincia</p>
              </div>
            </div>

            {/* <div className='row'>
              <div className='col-4'>
                <Input id='provincia' name='provincia' disabled={loading} />
                <p className='text-muted'>Provincia</p>
              </div>
            </div> */}

            <div className='row'>
              <div className='col-8'>
                <Input id='articulo' name='articulo' />
                <p className='text-muted'>ID / Artículo / Droga</p>
              </div>

              <div className='col-4'>
                <Input id='laboratorio' name='laboratorio' />
                <p className='text-muted'>Laboratorio</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-end'>
                <Dropdown className='d-inline me-3' disabled={loading}>
                  <Dropdown.Toggle id="dropdown-remitos" variant="secondary" disabled={loading}>
                    <i className="bi bi-download pe-3" />
                    Exportar
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "200px" }}>
                    <Dropdown.Item onClick={() => exportData(filterValues(values))} disabled={loading}>Excel</Dropdown.Item>
                    {!conComprobantes && <Dropdown.Item onClick={() => exportDataDetail(filterValues(values))} disabled={loading}>Excel c/ Detalle</Dropdown.Item>}
                    <Dropdown.Item onClick={() => handleExportVerifarma(filterValues(values))} disabled={loading}>Verifarma</Dropdown.Item>
                    <Dropdown.Item onClick={() => txtRemitosOsprera(values)} disabled={false}>Txt Osprera</Dropdown.Item>
                    <Dropdown.Item onClick={() => txtRemitosUnionPersonal(values)} disabled={false}>Txt UnionPersonal</Dropdown.Item>
                    <Dropdown.Item onClick={() => csvRemitosCsvStaCruz(values)} disabled={false}>Excel Sta-Cruz</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  type='button'
                  variant="secondary"
                  onClick={() => {
                    resetForm({ values: initialValues })
                    submitForm()
                  }}
                  className="me-3"
                  disabled={loading}
                >
                  <i className="bi bi-zoom-out mb-1" />
                  Limpiar
                </Button>

                <Button type='submit' variant='primary' disabled={loading}>
                  <i className='bi bi-search mb-1' />
                  {loading ? 'Cargando...' : 'Buscar'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter