export const detailDataAdapter = (data, por_unidades = false) => {
  const detail = data.map(article => {
    return {
      id: article.id,
      product_var_id: article.product_var_id,
      product_var_desc: article.name,
      line: article?.line ? article.line : '',
      actual_pvp: por_unidades
        ? article.pvp / article.units
        : article.pvp,
      list_cost: por_unidades
        ? article.list_cost / article.units
        : article.list_cost,
      qty: article.qty,
      units: article.units,
      units_total: article.qty * article.units,
      discount: Number(article.discount),
      price: por_unidades
        ? article.price / article.units
        : article.price,
      profitability: article.profitability,
      profitability_rate: article.profitability_rate,
      vat: article.vat,
      vat_rate: article.vat_rate,
      vat_amount: article.vat_amount,
      total: article.total ,
      subtotal: article.subtotal,
      outdated_pvp: Boolean(article.outdated_pvp),
      pvp_original: article.pvp_original,
      por_unidades: por_unidades,
    }
  })

  return detail
}

export const filterNewArticles = (detail, newArticles) => {
  const ids = detail.map(d => d.product_var_id)

  const newFilteredArticles = newArticles.filter((newArticle) => !ids.includes(newArticle.id))

  return newFilteredArticles
}

export const initialValues = {
  id: '',
  line: '',
  product_var_desc: '',
  actual_pvp: 0,
  list_cost: 0,
  qty: 0,
  units: 0,
  discount: 0,
  vat: 1,
  vat_rate: 0,
  price: 0,
  profitability: 0,
  profitability_rate: 0,
  profitability_total: 0,
  total: 0,
  pvp_original: 0,
  list_cost: 0,
  price: 0,
  total: 0,
}

export const inputStateInitialValues = {
  qty: '',
  discount: '',
  precio_base:'',
  profitability_rate:'',
  unit_price:'',
  list_cost:'',
  total:''
}

export const opcionesIVA = [
  { value: 1, label: 'No Grav.', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]