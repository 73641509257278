import { Checkbox, Label } from 'components'

const CheckboxV2 = ({ id, htmlFor, label, disabled }) => {
  return (
    <div className='d-flex flex-row justify-content-between align-items-center'>
      <Checkbox id={id} disabled={disabled} />
      <Label htmlFor={htmlFor} label={label} styles='mb-0 ms-4' />
    </div>
  )
}

export default CheckboxV2