import { Field, useField, useFormikContext } from 'formik'
import Select from 'react-select'
import { FormLabel } from 'react-bootstrap'

import { customStyles, labelStyle } from './styles'

const SelectV2 = ({ col, label, name, options, marginTop, isMulti = false, disabled = false, handleChange, noLabel = false, ...props }) => {
  const [field, meta] = useField(name)
  const showError = meta.touched && meta.error
  const { setFieldValue } = useFormikContext()

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0 )
        : options.find(option => option.value === field.value) || null;
    } else {
      return isMulti ? [] : ""
    }
  }

  const onChange = (option) => {
    setFieldValue(
      field.name,
      isMulti
        ? option.map(item => item.value)
        : option.value
    )

    if (handleChange) {
      handleChange(option)
    }
  }

  return (
    <div className={col}>
      <div className='selectfield-wrapper'  style={marginTop && {marginTop: `${marginTop}`}}>
        <Field name={name}>
          {({ field, form }) => (
            <>
              {!noLabel && <FormLabel style={labelStyle}>{label}</FormLabel>}
              <Select
                {...field}
                {...props}
                isMulti={isMulti}
                options={options}
                value={getValue()}
                onChange={onChange}
                styles={customStyles}
                isDisabled={disabled}
              />
            </>
          )}
        </Field>

        {showError && <div className='text-danger'>{meta.error}</div>}
      </div>
    </div>
  )
}

export default SelectV2