import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { SwitchField } from 'components'

const initialValues = {
  only_available: 0,
}

const Filter = ({ loading, queryMD, setQueryMD }) => {
  const handleSubmit = (values) => {
    let params = {
      ...queryMD,
      ...values,
      only_available: values?.only_available || undefined,
      pageNumber: 1,
      // pageSize: 10,
    }

    setQueryMD(params)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-8'></div>

            <div className='col-4'>
                <div className="d-flex flex-end mb-3">
                  <SwitchField
                    id='only_available'
                    name='only_available'
                    disabled={loading}
                  />
                  <span style={{ fontSize: '1.1rem' }}>Con stock</span>
                </div>
            </div>
          </div>

          <div className='row my-6'>
            <div className='col text-end'>
              <Button type='submit' text='Buscar'>
                <i className='bi bi-search me-1' />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter