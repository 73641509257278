/*
    En detalle viene:
        actual_pvp          -> pvp del articulo
        qty                 -> cantidad de unidades en la linea de pedido
        vat_rate            -> porcentaje de IVA (valor numerico -> 10.5, 21, 27, etc)
        list_cost           -> costo de lista del producto
        discount            -> descuento en porcentaje
        rentabilidad        -> true/false para saber si se eligió descuento o rentabilidad
        profitability_rate  -> rentabilidad en porcentaje
*/

export const calculosVenta = (actual_pvp, qty, vat_rate, list_cost, discount, precio_unitario = null, rentabilidad = false, profitability_rate) => {
    let _precio_unitario = +precio_unitario;

    // Precio unitario: pvp menos el descuento, tiene el IVA incluído
    if (!precio_unitario) {
        _precio_unitario = +actual_pvp;

        if (discount) {
            _precio_unitario = +actual_pvp - (+actual_pvp * +discount / 100)
        }
    }

    // Total de la línea, es el precio unitario por cantidad, tiene el IVA incluído
    const _total = +_precio_unitario * +qty;
    let _monto_iva = 0;
    let _monto_iva_unitario = 0;
    let _subtotal = null;

    // Si tiene tasa de IVA, el subtotal es el total menos el IVA, ej 21% -> total = subtotal * 1.21 -> subtotal=total / 1.21 = total*100/121
    if (vat_rate && vat_rate>0) {
        _subtotal = +_total * 100 / (100 + +vat_rate);
        _monto_iva = +_total - +_subtotal;
        _monto_iva_unitario =  +_precio_unitario * +vat_rate / (100 + +vat_rate);
    } else {
        _subtotal = + _total;
    }

    // Rentabilidad (valor y porcentaje)
    let _profitability = 0 ;
    let _profitability_percent = 0;

    if (list_cost && +list_cost > 0 && _precio_unitario && +_precio_unitario > 0) {
        if (rentabilidad) {
            _profitability_percent = profitability_rate
            _profitability = +_profitability_percent * +_subtotal / 100
        } else {
            _profitability_percent = (1 - (+list_cost / +_precio_unitario)) * 100;
            _profitability = +_profitability_percent * +_subtotal / 100
        }
    }

    return {
        unit_price: parseFloat(_precio_unitario).toFixed(2),
        subtotal: parseFloat(_subtotal).toFixed(2),
        vat_amount: parseFloat(_monto_iva).toFixed(2), // valor absoluto del IVA de la línea
        unit_vat_amount: parseFloat(_monto_iva_unitario).toFixed(2), // valor absoluto del IVA de cada unidad de la linea
        total: parseFloat(_total).toFixed(2),
        profitability: parseFloat(_profitability).toFixed(2),
        profitability_rate: parseFloat(_profitability_percent).toFixed(5)
    }

}