import { useState, useCallback } from 'react'
import { Waves } from 'services'

const useWaves = () => {
  const [loading, setLoading] = useState(false)

  const handleGetWaves = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waves.getWaves(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetWave = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Waves.getWave(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerDetalleOla = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Waves.obtenerDetalleOla(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateWave = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Waves.createWave(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateWave = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Waves.updateWave(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateWaveStatus = useCallback(async (id, status_id) => {
    try {
      setLoading(true)
      const response = await Waves.updateStatus(id, status_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelWave = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Waves.cancelWave(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddWaveOrders = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Waves.addWaveOrders(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteWaveOrder = useCallback(async (id, wave_order_id, data) => {
    try {
      setLoading(true)
      const response = await Waves.deleteWaveOrder(id, wave_order_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShippingCarriers = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waves.getShippingCarriers(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShippingCarrierServices = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waves.getShippingCarrierServices(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddShippingCarrierService = useCallback(async (id, wave_order_id, data) => {
    try {
      setLoading(true)
      const response = await Waves.addShippingCarrierService(id, wave_order_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateOrderDelivery = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Waves.createOrderDelivery(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintConsolidado = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Waves.printConsolidado(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetWaves,
    handleGetWave,
    handleObtenerDetalleOla,
    handleCreateWave,
    handleUpdateWave,
    handleUpdateWaveStatus,
    handleCancelWave,
    handleAddWaveOrders,
    handleDeleteWaveOrder,
    handleGetShippingCarriers,
    handleGetShippingCarrierServices,
    handleAddShippingCarrierService,
    handleCreateOrderDelivery,
    handlePrintConsolidado,
  }
}

export default useWaves
