import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useWaves } from 'hooks'

import { FormTable, SelectField } from 'components'

const CreateOrderDeliveryModal = ({ show, onHide, waveId, orders, handleOrders, handleGetWave, obtenerDetalle }) => {
  const [items, setItems] = useState([])

  const { loading, handleCreateOrderDelivery, handleGetShippingCarrierServices } = useWaves()
  const [shippingCarrierServices, setShippingCarrierServices] = useState([])

  const getShippingCarrierServices = useCallback(async () => {
    try {
      const response = await handleGetShippingCarrierServices()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `${d.shipping_carrier_name} - ${d.shipping_carrier_service}${d?.shipping_code ? ` (${d.shipping_code})` : ''}`,
      }))

      setShippingCarrierServices(data)
    } catch (error) {
      setShippingCarrierServices([])
      toast.error(error.message)
    }
  }, [handleGetShippingCarrierServices])

  const createOrderDelivery = async (values) => {
    try {
      const data = {
        orders: values.orders.map(i => ({ id: i.id, out_order_id: i.out_order_id })),
        shipping_carrier_service_id: values.shipping_carrier_service_id,
      }

      const response = await handleCreateOrderDelivery(waveId, data)
      toast.success("Orden de envío creada correctamente")
    } catch (error) {
      toast.error(error.message)
    } finally {
      onHide()
      setItems([])
      handleOrders([])
      handleGetWave()
      obtenerDetalle()
    }
  }

  useEffect(() => {
    setItems(orders)
  }, [orders])

  useEffect(() => {
    if (show) {
      getShippingCarrierServices()
    }
  }, [show, getShippingCarrierServices])

  if (!show) return null

  return (
    <Modal show={show} onHide={onHide} backdrop='static' centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Crear orden de envío</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            shipping_carrier_service_id: '',
            orders: items,
          }}
          onSubmit={createOrderDelivery}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='mb-8'>
                  <div className='col-6'>
                    <SelectField
                      id='shipping_carrier_service_id'
                      name='shipping_carrier_service_id'
                      label='Transportista'
                      options={shippingCarrierServices}
                      placeholder='Seleccione un servicio de transporte'
                      required
                    />
                  </div>
                </div>

                <FormTable
                  columns={[
                    { header: '', },
                    { header: 'O. Egreso' },
                    { header: 'Cliente' },
                    { header: 'Dirección de trazabilidad' },
                    { header: 'Dirección de envío' },
                  ]}
                >
                  {values.orders.map((order, i) => (
                    <tr key={order.id}>
                      <td className='align-middle'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            id={`orders.${i}.delivery.selected`}
                            name={`orders.${i}.delivery.selected`}
                            type='checkbox'
                            className='form-check-input'
                            defaultChecked={orders.some(i => i.id === order.id)}
                            // onChange={() => handleOrderSelection(order)}
                          />
                          <label className='form-check-label' htmlFor={`orders.${i}.delivery.selected`} />
                        </div>
                      </td>
                      <td className='align-middle'>{order?.out_order_id}</td>
                      <td className='align-middle fw-bold'>[{order?.customer_supplier_id}] {order?.customer_supplier_business_name}</td>
                      <td className='align-middle'>
                        <strong>{order?.traza_nombre}</strong> - {order?.traza_domicilio?.trim()}, {order?.traza_localidad?.trim()}
                      </td>
                      <td className='align-middle'>
                        {order?.address_id_ship
                          ?
                            <>
                              <strong>{order?.ship_nombre}</strong> - {order?.ship_domicilio?.trim()}, {order?.ship_localidad?.trim()}
                            </>
                          : '-'
                        }
                      </td>
                    </tr>
                  ))}
                </FormTable>

                <div className='row mt-8'>
                  <div className='col text-end'>
                    <Button
                      type='button'
                      variant='secondary'
                      className='me-3'
                      onClick={onHide}
                      disabled={loading}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button
                      type='submit'
                      variant='primary'
                      disabled={loading}
                    >
                      <i className='bi bi-save me-2' />
                      Crear
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreateOrderDeliveryModal