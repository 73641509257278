import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useAuth } from 'app/modules/auth'

import { useSalesOrders, useSellers, useUsers, useCustomerSuppliers, usePatients } from 'hooks'
import { Input, SelectField } from 'components'

import { SalesOrderStatusI } from 'interfaces'

const optionStatus = [
  { value: 1, label: "Pendiente" },
  { value: 2, label: "Operaciones" },
  { value: 3, label: "Finalizado" },
]

const opcionesTipoOrden = [
  { value: 3, label: 'Pedido de venta' },
  { value: 4, label: 'Pedido de egreso' },
  { value: 6, label: 'Pedido de venta con almacenamiento propio' },
]

const formikInitialValues = {
  id_text: '',
  order_type_id_filter: [],
  purchase_order: '',
  out_order_id: '',
  from_date: moment().subtract(1, 'M').format('YYYY-MM-DD'),
  to_date: moment().format('YYYY-MM-DD'),
  nombre: '',
  documento: '',
  customer_supplier_id: [],
  from_auth_date: '',
  to_auth_date: '',
  paciente: '',
  paciente_documento: '',
  patient_id: [],
  seller_id_filter: [],
  article_text: '',
  user_id_filter: [],
  grouped_status_id: [],
  sales_order_status_id_filter: [],
}

const Filter = ({ loading, query, handleQuery }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const { loading: loadingSO, handleGetSalesOrderStatus } = useSalesOrders()
  const [status, setStatus] = useState([])

  const { handleGetSellers } = useSellers()
  const [sellers, setSellers] = useState([])

  const { handleGetUsers } = useUsers()
  const [users, setUsers] = useState([])

  const { handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [cargandoClientes, setCargandoClientes] = useState(false)
  const [clientes, setClientes] = useState([])

  const { handleGetPatients } = usePatients()
  const [cargandoPacientes, setCargandoPacientes] = useState(false)
  const [pacientes, setPacientes] = useState([])

  const buscarClientes = async (query) => {
    setCargandoClientes(true)

    try {
      let params = {
        cliente: query,
        sortField: 'id',
        sortOrder: 'ASC',
      }
      const response = await handleGetCustomerSuppliers(null, null, params)
      const data = response.data.result.map(d => {
        const { id, business_name, documentation_type_name, documentation, ...rest } = d

        const dataCliente = {
          id: id,
          business_name: business_name,
          documentation: documentation,
          label: `[${id}] ${business_name} | ${documentation_type_name}: ${documentation}`
        }

        return dataCliente
      })

      setClientes(data)
    } catch (error) {
      setClientes([])
      toast.error(error.message)
    } finally {
      setCargandoClientes(false)
    }
  }

  const buscarPacientes = async (query) => {
    setCargandoPacientes(true)

    try {
      let params = {
        paciente: query,
        sortField: 'id',
        sortOrder: 'ASC',
      }
      const response = await handleGetPatients(null, null, params)
      const data = response.data.result.map(d => {
        const { id, first_name, last_name, documentation_type_name, documentation, health_insurance, ...rest } = d
        let fullname = `${first_name} ${last_name}`

        const dataPaciente = {
          id: id,
          first_name: first_name,
          last_name: last_name,
          fullname: fullname,
          documentation: documentation,
          affiliate_number: health_insurance?.affiliate_number,
          label: `[${id}] ${fullname}${documentation ? ` | ${documentation_type_name}: ${documentation}` : ''} ${health_insurance?.affiliate_number ? ` | Nro. afiliado: ${health_insurance.affiliate_number}` : ''}`,
        }

        return dataPaciente
      })

      setPacientes(data)
    } catch (error) {
      setPacientes([])
      toast.error(error.message)
    } finally {
      setCargandoPacientes(false)
    }
  }

  const filterData = (values) => {
    let params = {
      ...query,
      ...values,
      id_text: values?.id_text ? values.id_text : undefined,
      order_type_id_filter: values?.order_type_id_filter || undefined,
      purchase_order: values?.purchase_order ? values.purchase_order : undefined,
      out_order_id: values?.out_order_id ? values.out_order_id : undefined,
      text: values?.text ? values.text : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : undefined,
      user_id_filter: values?.user_id_filter ? values.user_id_filter : undefined,
      grouped_status_id: values?.grouped_status_id ? values.grouped_status_id : undefined,
      getDetail: true,
    }

    return params
  }

  const handleSubmit = (values) => {
    const params = {
      ...filterData(values),
      pageNumber: 1,
      pageSize: 10,
    }

    setCurrentUser((prevCurrentUser) => ({
      ...prevCurrentUser,
      sales_orders: { ...params },
    }))

    handleQuery({ ...params })
  }

  const getSalesOrderStatus = useCallback(async () => {
    let params = {}

    try {
      const response = await handleGetSalesOrderStatus(params, null)
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setStatus(data)
    } catch (error) {
      setStatus([])
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  const getSellers = useCallback(async () => {
    try {
      const response = await handleGetSellers('', '');
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `${d.first_name} ${d.last_name}`,
      }))

      setSellers(data)
    } catch (error) {
      setSellers([])
      toast.error(error.message)
    }
  }, [handleGetSellers])

  const getUsers = useCallback(async () => {
    try {
      const params = {
        //
      }
      const response = await handleGetUsers(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.name }))
      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])

  useEffect(() => {
    getSalesOrderStatus()
    getSellers()
    getUsers()
  }, [getSalesOrderStatus, getSellers, getUsers])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { sales_orders } = currentUser

      setInitialValues({
        ...formikInitialValues,
        id_text: sales_orders?.id_text || '',
        order_type_id_filter: sales_orders?.order_type_id_filter || [],
        purchase_order: sales_orders?.purchase_order || '',
        out_order_id: sales_orders?.out_order_id || '',
        from_date: sales_orders?.from_date || moment().subtract(1, 'M').format('YYYY-MM-DD'),
        to_date: sales_orders?.to_date || moment().format('YYYY-MM-DD'),
        nombre: sales_orders?.nombre || '',
        documento: sales_orders?.documento || '',
        customer_supplier_id: sales_orders?.customer_supplier_id || [],
        from_auth_date: sales_orders?.from_auth_date || '',
        to_auth_date: sales_orders?.to_auth_date || '',
        paciente: sales_orders?.paciente || '',
        paciente_documento: sales_orders?.paciente_documento || '',
        patient_id: sales_orders?.patient_id || [],
        seller_id_filter: sales_orders?.seller_id_filter || [],
        article_text: sales_orders?.article_text || '',
        user_id_filter: sales_orders?.user_id_filter ? sales_orders.user_id_filter : [currentUser.user_id],
        grouped_status_id: sales_orders?.grouped_status_id || [],
        sales_order_status_id_filter: sales_orders?.sales_order_status_id_filter || [SalesOrderStatusI.BORRADOR],
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' disabled={loading || loadingSO} />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='order_type_id_filter'
                name='order_type_id_filter'
                options={opcionesTipoOrden}
                placeholder='Seleccionar tipo(s)'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
                className='z-index-3'
              />
              <p className='text-muted'>Tipo</p>
            </div>

            <div className='col-2'>
              <Input id='purchase_order' name='purchase_order' disabled={loading || loadingSO} />
              <p className='text-muted'>Nro. Orden de compra</p>
            </div>

            <div className='col-4'>
              <Input id='out_order_id' name='out_order_id' placeholder='Ej: 1234, 4321' disabled={loading || loadingSO} />
              <p className='text-muted'>ID OE</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <AsyncTypeahead
                id='customer-suppliers-typeahead'
                className='z-index-2'
                isLoading={cargandoClientes}
                onSearch={(query) => {
                  if (query.length >= 1) {
                    buscarClientes(query)
                  }
                }}
                options={clientes}
                labelKey={opt => opt.label}
                onChange={selected => {
                  setFieldValue('customer_supplier_id', selected.map(i => i.id))
                }}
                placeholder='Buscar cliente (ID, Razón social y/o Documento)'
                multiple
                disabled={loading}
              />
              <p className='text-muted'>Cliente</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' disabled={loading || loadingSO} />
              <p className='text-muted'>F. desde</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' disabled={loading || loadingSO} />
              <p className='text-muted'>F. hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <AsyncTypeahead
                id='patients-typeahead'
                className='z-index-1'
                isLoading={cargandoPacientes}
                onSearch={(query) => {
                  if (query.length >= 1) {
                    buscarPacientes(query)
                  }
                }}
                options={pacientes}
                labelKey={opt => opt.label}
                onChange={selected => {
                  setFieldValue('patient_id', selected.map(i => i.id))
                }}
                placeholder='Buscar paciente (ID, Nombre, Documento y/o Nro. afiliado)'
                multiple
                disabled={loading}
              />
              <p className='text-muted'>Paciente</p>
            </div>

            <div className='col-2'>
              <Input id='from_auth_date' name='from_auth_date' type='date' disabled={loading || loadingSO} />
              <p className='text-muted'>Desde F. autorización</p>
            </div>

            <div className='col-2'>
              <Input id='to_auth_date' name='to_auth_date' type='date' disabled={loading || loadingSO} />
              <p className='text-muted'>Hasta F. autorización</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' disabled={loading || loadingSO} />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='seller_id_filter'
                name='seller_id_filter'
                options={sellers}
                marginTop={'-20px'}
                placeholder='Seleccionar'
                isMulti
                disabled={loading || loadingSO}
              />
              <p className='text-muted'>Vendedor</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='grouped_status_id'
                name='grouped_status_id'
                options = {optionStatus}
                isMulti
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
                disabled={loading || loadingSO}
              />
              <p className='text-muted'>Grupo de estados</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options = {status}
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
                isMulti
                disabled={loading || loadingSO}
              />
              <p className='text-muted'>Estados</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='user_id_filter'
                name='user_id_filter'
                options={users}
                placeholder='Seleccione'
                marginTop={'-20px'}
                isMulti
                disabled={loading || loadingSO}
              />
              <p className='text-muted'>Usuario creación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: formikInitialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading || loadingSO}
              >
                <i className='bi bi-zoom-out mb-1' />
                Limpiar
              </Button>

              <Button type='submit' variant='primary' disabled={loading || loadingSO}>
                <i className='bi bi-search mb-1' />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter