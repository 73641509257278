import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment'

import { Tooltip as TooltipC } from 'components'

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'
import { OrderTypeI } from 'interfaces';

const COLUMNS = [
  {
    Header: <div className='text-center'>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        <Link to={`${routes.SALES_ORDERS}/${row.id}`}>
          {row.id}
        </Link>
      </div>
    ),
  },
  // {
  //   Header: <p className='m-0 p-0 text-center'>Tipo</p>,
  //   id: 'order_type_id',
  //   accessor: (row) => (
  //     <span className={`badge ${row.order_type_color} w-100 justify-content-center fw-normal`}>
  //       {row.order_type_label}
  //     </span>
  //   ),
  // },
  // {
  //   Header: <div className=''>Tipo</div>,
  //   id: 'order_type_label',
  //   accessor: (row) => (
  //     <div className=''>
  //       {row?.order_type_label}
  //     </div>
  //   )
  // },
  {
    Header: <div className='text-center'>Creado</div>,
    id: 'created_at',
    accessor: (row) => (
      <div className='text-center'>
        <p className='m-0 p-0'>{moment(row.created_at).format("DD/MM/YYYY")}</p>
        <p className='m-0 p-0 mt-4'>{row.user_name}</p>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center' style={{minWidth:"80px"}}>Envío aut.</div>,
    id: 'profitability_auth_sent_date',
    accessor: (row) => (
      <div className='text-center'>
        {row.profitability_auth_sent_date ? moment(row.profitability_auth_sent_date).format("DD/MM/YYYY HH:mm") : "-"}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>O.C.</div>,
    id: 'purchase_order',
    accessor: (row) => (
      <div className='text-center'>
        {row?.purchase_order ? row.purchase_order : '-'}
      </div>
    )
  },
  {
    Header: <div className='text-center'>O.E</div>,
    id: 'out_orders_ids',
    accessor: (row) => (
      <div className='text-center'>
        {row?.out_orders_ids ? row.out_orders_ids : '-'}
      </div>
    )
  },
  {
    Header: <div>Cliente</div>,
    id: 'customer_supplier_business_name',
    accessor: (row) => (
      <>
        <p className='m-0 p-0 fw-bold'>
          [{row.customer_supplier_id}] {row.customer_supplier_business_name}
        </p>

        <p className='m-0 p-0'>Documento: {row.customer_supplier_documentation}</p>

        {Boolean(row.remito_para_terceros) && row?.id_tercero_para_remitir !== null &&
          <p className='m-0 p-0'>Remitir a: {row?.tercero_para_remitir}</p>
        }

        {row?.patient_id &&
          <ul className='list-style-none m-0 p-0 mt-4'>
            <li>Paciente: [{row.patient_id}] {row.patient_name}</li>
            <li>Documento: {row?.patient_documentation || '-'}</li>
            <li>Nro. afiliado: {row?.patient_affiliate_number || '-'}</li>
          </ul>
        }
      </>
    ),
    sortOrder: true,
  },
  {
    Header: <div>Vendedor</div>,
    id: 'seller_id',
    accessor: (row) => (
      <div>
        {row.seller_name}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Artículos</div>,
    id: 'lines',
    accessor: (row) => (
      <div className='text-center'>
        {row.lines === 0 ? '-' : row.lines}

        {row?.detail &&
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-${row.id}`}>
                <div>
                  {row.detail.split(',').map((item, index) => (
                    <div key={index}>{item.trim()}</div>
                  ))}
                </div>
              </Tooltip>
            }
          >
            <i className='bi bi-info-circle text-warning ms-2' />
          </OverlayTrigger>
        }
      </div>
    )
  },
  {
    Header: <div className='text-end'>Total</div>,
    id: 'total',
    accessor: (row) => (
      <div className='text-end'>
        {row.order_type_id!=OrderTypeI.PEDIDO_EGRESO ? (row.total === 0 ? '-' : currencyFormat.format(row.total)) : ''}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'sales_order_status_id',
    className:'col-status',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.sales_order_status_label}
      </span>
    )
  },
  {
    Header: '',
    id: 'cancelado_parcial',
    accessor: (row) => (
      <div style={{width:"70px"}}>
        {Boolean(row.cancelado_parcial) &&
          <TooltipC text='Pedido cancelado parcialmente'>
            <i className='bi bi-code-square text-danger me-2' />
          </TooltipC>
        }
        
        {Boolean(row.order_type_id == OrderTypeI.PEDIDO_EGRESO) &&
          <TooltipC text='Pedido de egreso'>
            <i className='bi bi-box-arrow-up text-primary me-2' />
          </TooltipC>
        }
        
        {Boolean(row.order_type_id == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO) &&
          <TooltipC text='Pedido de venta con almacenamiento propio'>
            <i className='bi bi-save text-primary me-2' />
          </TooltipC>
        }
        
        {Boolean(row.por_unidades==1) &&
          <TooltipC text='Pedido por unidades'>
            <i className='bi bi-1-square text-primary me-2' />
          </TooltipC>
        }
      </div>
    )
  }
]

export {
  COLUMNS
}