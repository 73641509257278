import { Label } from 'components'

const CheckboxContainer = ({ col, styles, htmlFor, label, children }) => {
  return (
    <div className={`${col} ${styles}`}>
      <div className='d-flex flex-column justify-content-between align-items-start'>
        <Label htmlFor={htmlFor} label={label} />

        {children}
      </div>
    </div>
  )
}

export default CheckboxContainer