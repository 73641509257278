
import {useEffect, useState} from 'react'
import { useFormikContext } from 'formik'
import { Button, FormLabel } from 'react-bootstrap'
import Select from 'react-select'

import { useCustomerSuppliers } from 'hooks'
import { SelectField, SwitchField, Input } from 'components'

import { getPSL } from 'utilities'

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '37.73px',
    height: '37.73',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
}

const optionsInitialCostType = [
  {value: 0, label: "Porcentual" },
  {value: 1, label: "Fijo"}
]

const ComercialForm = () => {
  const currentDate = new Date().toISOString().split('T')[0]
  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)
  tomorrowDate.setHours(0, 0, 0, 0)
  tomorrowDate = tomorrowDate.toISOString().split('T')[0]

  const { setFieldValue, values } = useFormikContext()
  const [suppliers, setSuppliers] = useState([])
  const [initialCost, setInitialCost] = useState(0)
  const [aditionalCost, setAditionalCost] = useState(0)
  const [sircrebValue, setSircrebValue] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const {handleGetCustomerSuppliers} = useCustomerSuppliers()

  const loadSuppliers = async () => {
    const data = await handleGetCustomerSuppliers();
    if (data && data.data &&data.data.result) {
      setSuppliers(data.data.result
        .filter(p => p.is_supplier == 1)
        .map(p=>({ ...p, label: p.business_name, value: p.id})))
    }
  }

  const cambiosEnPrecioManual = ()=>{
      let _pvp = 0;
      if (values.manual_price?.price && values.manual_price_on ) {
        _pvp = +values.manual_price.price;
      } 
      else if(values.ab_pvp){
        _pvp = +values.ab_pvp
      }
      
      setFieldValue('actual_pvp', _pvp)

      if(values.purchase_discount_base){
          const _psl = getPSL(_pvp, values.purchase_discount_base)    
          setFieldValue("psl", _psl)      
          if (+values.purchase_discount_extra) {
            const _bonus = +_psl * (1- (+values.purchase_discount_extra / 100))
            setInitialCost(parseFloat(_bonus).toFixed(2))
          }
      }
  }

  useEffect(() => {
      cambiosEnPrecioManual();
        // if (values.manual_price && values.manual_price.price) {
        //   setFieldValue('actual_pvp', values.manual_price.price)
        // } 
        // else {
        //   if (values.ab_pvp && values.purchase_discount_base) {
        //     const _pvp = values.ab_pvp
        //     const _psl = getPSL(_pvp, values.purchase_discount_base)
        //     setFieldValue("psl", _psl)
        //     setFieldValue('actual_pvp', values.ab_pvp)
        //     if (+values.purchase_discount_extra) {
        //       const _bonus = +_psl * (1- (+values.purchase_discount_extra / 100))
        //       setInitialCost(parseFloat(_bonus).toFixed(2))
        //     }
        //   }
        // }
  }, [values.manual_price])


  // useEffect(() => {
  //     if (values.manual_price && values.purchase_discount_base && values.manual_price.price) {
  //       const _pvp = values.manual_price_on?values.manual_price.price:values.ab_pvp
  //       const _psl = getPSL(_pvp, values.purchase_discount_base)
  //       setFieldValue("psl", _psl)

  //       const _bonus = +_psl * (1- (+values.purchase_discount_extra / 100))
  //       setInitialCost(parseFloat(_bonus).toFixed(2))
  //     }
  // }, [values.manual_price, values.purchase_discount_base])

  useEffect(() => {
      if (values.purchase_discount_base) {
        const _pvp = values.actual_pvp
        const _psl = getPSL(_pvp, values.purchase_discount_base)
        setFieldValue("psl", _psl)
        if (+values.purchase_discount_extra) {
          const _bonus = +_psl * (1- (+values.purchase_discount_extra / 100))
          setInitialCost(parseFloat(_bonus).toFixed(2))
        }
      }
  }, [values.purchase_discount_base])


  useEffect(() => {
    cambiosEnPrecioManual();
      // if (values.manual_price && values.purchase_discount_base && values.manual_price.price) {
      //   const _pvp = values.manual_price_on ? values.manual_price.price : values.ab_pvp
      //   const _psl = getPSL(_pvp, values.purchase_discount_base)
      //   setFieldValue("psl", _psl)

      //   const _bonus = +_psl * (1- (+values.purchase_discount_extra / 100))
      //   setInitialCost(parseFloat(_bonus).toFixed(2));
      // }
  }, [values.manual_price_on])

  useEffect(() => {
      let _sircreb=0;
      let _bonus=0;
      if (values.psl) {
        _bonus = +values.psl * (1- (+values.purchase_discount_extra / 100))
        setInitialCost(parseFloat(_bonus).toFixed(2))
        _sircreb = parseFloat(+_bonus * +values?.sircreb)
      }   
      setTotalCost(parseFloat(+_bonus + +aditionalCost + _sircreb).toFixed(2))
  }, [values.purchase_discount_extra])

  useEffect(() => {
      if (values.initial_cost_type && values.manual_list_cost.list_cost) {
        setInitialCost(values.manual_list_cost.list_cost)
      }
  }, [values.manual_list_cost])

  useEffect(() => {
      let iva = +values.purchase_vat_rate || 0;
      iva = parseFloat(iva);

      if (values.purchase_add_cost_type) {
        setAditionalCost(values.purchase_add_cost);
      } 
      else {
        if (values.psl) {
          const _val = (+values.psl / ( (100 + iva) / 100)) * +values.purchase_add_cost_percent / 100
          setAditionalCost(parseFloat(_val).toFixed(2))
        }
      }
  }, [ values.purchase_add_cost_type, values.purchase_add_cost, values.purchase_add_cost_percent])

  useEffect(() => {
      const _sircreb = parseFloat(+initialCost * +values?.sircreb)

      setTotalCost(parseFloat(+initialCost + +aditionalCost + _sircreb).toFixed(2))

      setSircrebValue(_sircreb.toFixed(2))

  }, [initialCost, aditionalCost])

  useEffect(() => {
      loadSuppliers()
  }, [])

  useEffect(() => {
    // console.log(values)
  }, [values])

  return (
    <>
      <div className="row mt-5">
        <div className="col-6">
          <SelectField isMulti={false} label={'Proveedor habitual'} name="customer_supplier_id" options={suppliers} marginTop='3px' />
        </div>

        <div className="col-3">
          <label className='form-label'>Descuento comercial (%)</label>
          <Input  id='commercial_discount' name='commercial_discount' />
        </div>

        <div className="col-3">
          <label className='form-label'>Rentabilidad mínima %</label>
          <Input  id='profitablity_min' name='profitablity_min' label="Rentabilidad mínima %"/>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
          <div className="d-flex">
            <SwitchField
              id='manual_price_on'
              name='manual_price_on'
              disabled={!values || !values.reg_id}
            />
            <span style={{ fontSize: '1.1rem' }}>Precio manual</span>
          </div>
        </div>

        {values.manual_price_on
          ?
            <>
              <div className="col">
                <label className='form-label'>PVP manual</label>
                <Input  id='manual_price.price' name='manual_price.price' label="Precio manual" />
              </div>

              <div className="col">
                <label className='form-label'>Vigencia PVP manual</label>
                <Input id='manual_price.date_from' name='manual_price.date_from' type='text' disabled />
              </div>

              <div className='col'></div>
            </>
          :
            <>
              <div className="col-3">
                <label className='form-label'>PVP Alfabeta</label>
                <Input  id='ab_pvp' name='ab_pvp' label="PVP Alfabeta" disabled />
              </div>

              <div className="col-3">
                <label className='form-label'>Vigencia PVP Alfabeta</label>
                <Input  id='ab_pvp_start' name='ab_pvp_start' label="Vigencia PVP Alfabeta" disabled />
              </div>
            </>
        }
      </div>

      <div className="row mt-6">
        <div className="col-3">
          <label className='form-label'>Descuento droguería %</label>
          <div className='d-flex'>
          <Input  id='purchase_discount_base' name='purchase_discount_base' label="Descuento droguería %" />
          <Button variant='primary' size='sm' onClick={()=>setFieldValue('purchase_discount_base', 31.03448)}>Std</Button>
          </div>
        </div>

        <div className="col-3">
          <label className='form-label'>Precio salida laboratorio</label>
          <Input  id='psl' name='psl' label="Precio salida laboratorio" disabled />
        </div>

        <div className="col-3"></div>
      </div>

      <div className="row mt-6">
        <div className="col-3">
          {/* <FormLabel style={labelStyle}>Costo inicial</FormLabel>
          <input type='text' className='form-control' value="Porcentual" disabled /> */}
          <FormLabel style={labelStyle}>Costo inicial</FormLabel>
          <Select
            options={optionsInitialCostType}
            defaultValue={optionsInitialCostType.filter(d => d.value == values.initial_cost_type)[0]}
            onChange={(e)=>{ 
              setFieldValue('initial_cost_type', optionsInitialCostType.filter(d => d.value == e.value)[0].value) 
              setFieldValue('manual_list_cost.list_cost', 0)
            }}
            styles={customStyles}
          />
        </div>

        {values.initial_cost_type == 1
          ?
            <>
              <div className="col-3">
                <label className='form-label'>Costo fijo</label>
                <Input  id='manual_list_cost.list_cost' name='manual_list_cost.list_cost' />
              </div>

              <div className='col-3'>
                <div className='row'>
                  {/* <div className='col-6'>
                    <label className='form-label'>Desde</label>
                    <Input id='manual_list_cost.date_from' name='manual_list_cost.date_from' type='date' min={currentDate} />
                  </div> */}

                  <div className='col'>
                    <label className='form-label'>Hasta</label>
                    <Input id='manual_list_cost.date_to' name='manual_list_cost.date_to' type='date'  min={tomorrowDate}/>
                  </div>
                </div>
              </div>
            </>
          :
            <>
              <div className="col-3">
                <label className='form-label'>Bonificación %</label>
                <Input  id='purchase_discount_extra' name='purchase_discount_extra' label="Bonificación compra %"/>
              </div>

              <div className='col-3'></div>
            </>
        }

        <div className="col-3">
          <label className='form-label'>Costo inicial</label>
          <input type="text" value={initialCost} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className="row mt-6">
        <div className="col-9"></div>
        <div className="col-3">
            <label className='form-label'>SIRCREB {values?.sircreb?'(' + values.sircreb + '%)':''}</label>
            <input type="text" value={sircrebValue} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className="row mt-6">
        <div className="col-3">
          <FormLabel style={labelStyle}>Costo asociado</FormLabel>
          <Select
            options={optionsInitialCostType}
            defaultValue={optionsInitialCostType.filter(d => d.value == values.purchase_add_cost_type)[0]}
            onChange={(e)=>{ setFieldValue('purchase_add_cost_type', optionsInitialCostType.filter(d => d.value == e.value)[0].value)}}
            styles={customStyles}
          />
        </div>

        {values.purchase_add_cost_type == 1
          ?
            <>
              <div className="col-3">
                <label className='form-label'>Costo asociado (fijo)</label>
                <Input  id='purchase_add_cost' name='purchase_add_cost' label="Costo asociado compra %" />
              </div>
            </>
          :
            <>
              <div className="col-3">
                <label className='form-label'>Costo asociado (%)</label>
                <Input  id='purchase_add_cost_percent' name='purchase_add_cost_percent' label="Costo asociado compra %" />
              </div>
            </>
        }

        <div className='col-3'></div>

        <div className='col-3'>
          <label htmlFor='manual_list_cost.list_cost' className='form-label'>Costo asociado</label>
          <input type="text" value={aditionalCost} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className='row mt-6 mb-20'>
        <div className='col'></div>
        <div className='col'></div>
        <div className='col'></div>
        <div className="col">
          <label htmlFor='manual_list_cost.list_cost' className='form-label'>Costo de lista total</label>
          <input type="text" value={totalCost} onChange={() => {}} className={`form-control  text-end ${totalCost > values.psl ? 'text-danger' : ''}`}  disabled />
        </div>
      </div>
    </>
  );
};

export default ComercialForm;