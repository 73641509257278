import { useFormikContext } from 'formik'

import { Table } from 'react-bootstrap'
import { currencyFormat } from 'utilities'
import './article.css'

const TotalSummaryTable = ({ editing = false, pedido }) => {
  const { values } = useFormikContext()

  return (
    <div className='mt-8'>
      <div className='row mb-8'>
        <div className="col-8" />

        <div className="col-4">
          <Table striped bordered hover>
            <thead></thead>
            <tbody>
                <tr>
                  <td colSpan={3} className='fw-bold p-2'>Subtotal</td>
                  <td className='text-end p-2'>{currencyFormat.format(pedido?.subtotal)}</td>
                </tr>

                <tr>
                  <td colSpan={3} className='fw-bold p-2'>IVA</td>
                  <td className='text-end p-2'>{currencyFormat.format(pedido?.iva)}</td>
                </tr>

                <tr>
                  <td colSpan={3} className='fw-bold p-2'>Rentabilidad</td>
                  <td className='text-end p-2'>{pedido?.total_profitability_rate ? `${parseFloat(pedido.total_profitability_rate).toFixed(2)}%` : ''}</td>
                </tr>

                <tr>
                  <td colSpan={3} className='p-2'>
                    <span className='fw-bold'>IIBB</span>
                    <span className='fst-italic ms-3'>{pedido?.address_id_traza ? '' : 'El pedido no tiene dirección cargada'}</span>
                  </td>

                  <td className='text-end p-2'>{pedido.iibb?.total >= 0 ? currencyFormat.format(pedido.iibb.total) : ''}</td>
                </tr>

                {pedido.iibb && pedido.iibb.percepcionesAfipRequest
                  ? pedido.iibb.percepcionesAfipRequest.map((val, idx) => (
                    <tr key={idx} className='p-0 m-0 no-striping' style={{ backgroundColor: '#f0f0f0' }}>
                      <td className='text-start p-1'>
                        <span className='ps-4'>{val.descripcion}</span>
                      </td>
                      <td className='text-end p-1'>${val.importe}</td>
                      <td className='text-end p-1'></td>
                    </tr>
                  ))
                  : <></>
                }

                <tr>
                  <td colSpan={3} className='fw-bold p-2'>Total</td>
                  <td className='text-end p-2 fw-bold'>{currencyFormat.format(pedido?.total_calc)}</td>
                </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default TotalSummaryTable