import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter } from './partials'
import { useGetColumns } from './constants'

import routes from 'app/routing/routes'
import { RoleI } from 'interfaces'
import { currencyFormat } from 'utilities'

const relacionesComercialesCliente = (cliente) => {
  const { is_customer, is_supplier, is_distributor, cliente_para_remitir } = cliente
  const relaciones = []

  if (is_customer) {
    relaciones.push('Cliente')
  }
  if (is_supplier) {
    relaciones.push('Proveedor')
  }
  if (is_distributor) {
    relaciones.push('Distribuidor')
  }
  if (cliente_para_remitir) {
    relaciones.push('Cliente para remitir')
  }

  return relaciones.join(' - ')
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const CustomerSuppliers = () => {
  const { currentUser } = useAuth()

  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading, handleGetCustomerSuppliers, handleUpdateCustomerSupplier, handleEnableCustomerSupplier, handleDisableCustomerSupplier } = useCustomerSuppliers()

  const getCustomerSuppliers = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetCustomerSuppliers('token', null, params)
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setData([])
    }
  }, [handleGetCustomerSuppliers, query])

  const exportarData = async () => {
    try {
      let params = {
        ...query,
        pageNumber: 1,
        pageSize: undefined,
      }

      const response = await handleGetCustomerSuppliers(null, null, params)
      const data = response.data.result.map(d => ({
        'ID': d.id,
        'Razón social': d.business_name,
        'Tipo documento': d.documentation_type_name,
        'Documento': d.documentation,
        'F. ingreso': moment(d.enrollment_date).format('DD/MM/YYYY'),
        'F. baja': d?.cancel_date ? moment(d.cancel_date).format('DD/MM/YYYY') : '',
        'Cond. IVA': d.vat_condition_label,
        'Tipo': d.type_name,
        'Cond. pago': d.payment_condition_name?.replaceAll('_', ' '),
        'Gran empresa': d?.company_system ? d?.company_system?.trim() : '',
        'Dirección': d.address.domicilio?.trim(),
        'Localidad': `${d.address?.sublocality_level_1?.trim()} ${d.address?.administrative_area_level_2?.trim()}`,
        'Provincia': d.address?.administrative_area_level_1?.trim(),
        'Habilitado': Boolean(d.is_enabled) ? 'Sí' : 'No',
        'Relación comercial': relacionesComercialesCliente(d),
        'Abrev': d?.cod_abrev,
        'Rnos': d?.rnos,
        'Vendedor': d?.seller_id ? d.seller?.fullname : '',
        'Lím. crédito': d?.credit || 0,
        'Comisión': d?.comission || 0,
      }))

      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Clientes");
      XLSX.writeFile(wb, `Clientes ${moment().format("YYYYMMDD-HHmm")}.xlsx` );
    } catch (error) {
      toast.error(error.message)
    }
  }

  const navigate = useNavigate();

  const enableDisableCustomerSupplier = async () => {
    try {
      let status = currentRow?.is_enabled ? 'deshabilitar' : 'habilitar'
      let msg = currentRow?.is_enabled ? 'deshabilitado' : 'habilitado'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} al cliente/proveedor ${currentRow?.business_name}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let data = {
          is_enabled: !Boolean(currentRow?.is_enabled)
        }

        if(data.is_enabled){
          await handleEnableCustomerSupplier(currentRow?.id)
        }
        else{
          await handleDisableCustomerSupplier(currentRow?.id)
        }
        //await handleUpdateCustomerSupplier(currentRow?.id, data)
        toast(`Cliente ${msg} correctamente`, { type: data.is_enabled ? 'success' : 'info' })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getCustomerSuppliers()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}`)
    })

    actions.push({
      label: "Ver impuestos",
      icon: "bi bi-bank text-primary",
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}/impuestos`)
    })

    actions.push({
      label: 'Ver descuentos',
      icon: 'bi bi-coin text-primary',
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}/descuentos`)
    })

    if (Boolean(currentRow?.is_customer)) {
      actions.push({
        label: 'Ver clientes para remitir',
        icon: 'bi bi-people text-primary',
        action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}/clientes-para-remitir`)
      })
    }

    actions.push({
      label: Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar',
      icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'} `,
      action: enableDisableCustomerSupplier
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS, getCustomerSuppliers])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getCustomerSuppliers()
  }, [getCustomerSuppliers])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Clientes / Proveedores'}
        goTo={currentUser.roles.some(r => r.id != RoleI.COORDINACION) && routes.CUSTOMER_SUPPLIERS_NEW}
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} handleExport={exportarData} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default CustomerSuppliers