import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { SelectField, TextareaBS, InputBS } from 'components';
import { useFormikContext } from 'formik';
import { useDepots, useGetProductDetail } from 'hooks';
import { toast } from 'react-toastify';

const CreateWarehouseModal = ({ show, onClose }) => {
  const { setFieldValue, values, handleSubmit, resetForm } = useFormikContext();
  const {
    handleGetCustomerSupplier,
    handleGetDepotName,
    handleGetBusinessUnitName,
    handleGetWarehouseName,
    saveDepot,
  } = useDepots();

  const { productFamilies, productMakers } = useGetProductDetail();

  const [customerSupplierOptions, setCustomerSupplierOptions] = useState([]);
  const [depotGralOptions, setDepotGralOptions] = useState([]);
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [depotOptions, setDepotOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const customerSupplier = await handleGetCustomerSupplier();
        const depots = await handleGetDepotName();
        const businessUnits = await handleGetBusinessUnitName();
        const warehouses = await handleGetWarehouseName();

        setCustomerSupplierOptions(
          customerSupplier?.map(({ id, business_name }) => ({
            label: business_name,
            value: id,
          })) || []
        );

        setDepotGralOptions(
          depots?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || []
        );

        setBusinessUnitOptions(
          businessUnits?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || []
        );

        setDepotOptions(
          warehouses?.map(({ warehouse_id, warehouse_name, cool_type_name }) => ({
            label: warehouse_name,
            value: warehouse_id,
            coolType: cool_type_name,
          })) || []
        );

        setLoading(false);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [
    handleGetCustomerSupplier,
    handleGetDepotName,
    handleGetBusinessUnitName,
    handleGetWarehouseName,
  ]);

  useEffect(() => {
    const selectedWarehouse = depotOptions.find(
      (option) => option.value === values.deposit
    );

    if (selectedWarehouse) {
      setFieldValue("temperature", selectedWarehouse.coolType || "");
    } else {
      setFieldValue("temperature", "");
    }
  }, [values.deposit, depotOptions, setFieldValue]);

  const handleSave = async () => {
    try {
      const response = await saveDepot(values);
      if (response.status === 200) {
        toast.success('Éxito al guardar depósito');
        resetForm();
        onClose();
      } else {
        toast.error('Error al guardar depósito');
      }
    } catch (error) {
      toast.error('Error al guardar depósito');
      console.error('Error al guardar depósito:', error);
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Nuevo depósito</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="depotGral"
                name="depotGral"
                options={depotGralOptions}
                label="Depósito General"
                placeholder="Seleccionar depósito general"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="deposit"
                name="deposit"
                options={depotOptions}
                label="Depósito"
                placeholder="Seleccionar depósito"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="u_negocio"
                name="u_negocio"
                options={businessUnitOptions}
                label="U. negocio"
                placeholder="Seleccionar U. negocio"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <InputBS
                id="temperature"
                name="temperature"
                label="Temperatura"
                placeholder="Temperatura"
                disabled={true} 
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <InputBS id="space" name="space" label="Espacio" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <SelectField
                id="owner"
                name="owner"
                options={customerSupplierOptions}
                label="Dueño"
                placeholder="Seleccionar dueño"
                disabled={loading}
              />
            </div>
          </div>

          <div className="separator my-8"></div>

          <div className="row">
            <div className="col-12 text-end">
              <Button
                type="button"
                variant="secondary"
                onClick={handleClose}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-slash-circle" />
                Cancelar
              </Button>

              <Button type="submit" variant="primary" disabled={loading}>
                <i className="bi bi-save me-2" />
                {loading ? 'Cargando...' : 'Guardar'}
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateWarehouseModal;