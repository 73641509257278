import service from 'services/service'

const PATH = {
  outOrders: '/out-orders',
  outOrdersStatus: '/out-orders/status',
}

export class OutOrders {
  static getOutOrders = async (params) => {
    const response = await service.get(PATH.outOrders, { params })
    return response
  }

  static getOutOrder = async (id, params) => {
    const response = await service.get(`${PATH.outOrders}/${id}`, { params })
    return response
  }

  static getOutOrderStatus = async (params) => {
    const response = await service.get(PATH.outOrdersStatus, { params })
    return response
  }

  static getOutOrderStockStatus = async (id, params) => {
    const response = await service.get(`${PATH.outOrders}/${id}/detail/stock`, { params })
    return response
  }

  static updateOutOrder = async (id, data) => {
    const response = await service.patch(`${PATH.outOrders}/${id}`, data)
    return response
  }

  static updateOutOrderCoordination = async (id, data) => {
    const response = await service.patch(`${PATH.outOrders}/${id}/coordination`, data)
    return response
  }

  static updateOutOrderStatus = async (id, new_status) => {
    const response = await service.patch(`${PATH.outOrders}/${id}/status/${new_status}`)
    return response
  }

  static markOrderAsReady = async (id) => {
    const response = await service.post(`${PATH.outOrders}/${id}/set-ready`)
    return response
  }

  static addDetail = async (id, data, token) => {
    const response = await service.post(`${PATH.outOrders}/${id}/detail`, data)
    return response
  }
  static lotDetail = async (id, data) => {
    const response = await service.put(`${PATH.outOrders}/${id}/detail`, data)
    return response
  }
  static updateDetail = async (id, detail_id, data, token) => {
    const response = await service.patch(`${PATH.outOrders}/${id}/detail/${detail_id}`, data)
    return response
  }

  static deleteDetail = async (id, detail_id, token) => {
    const response = await service.delete(`${PATH.outOrders}/${id}/detail/${detail_id}`)
    return response
  }

  static sendWaybill = async (id, params) => {
    const response = await service.post(`${PATH.outOrders}/${id}/confirm`, params);
    return response;
  };
  
  static regenerateWaybill = async (data) => {
    const {remito_id, ...rest} = data;
    const response = await service.post(`/transactional-documents/remito/${remito_id}/regenerar`, rest);
    return response;
  };

  static getTalonarios = async () => {
    const response = await service.get(`/transactional-documents/remito/talonario`)
    return response
  }

  static printWaybill = async (id, copias) => {
    const response = await service.get(`${PATH.outOrders}/${id}/print/remito?copias=${copias}`,{
      responseType: 'blob',
    });
    return response;
  };

  static printRemitoFa = async (data, copias) => {
    const response = await service.post(`${PATH.outOrders}/print/remitoFA`, {
      ...data,
      copias,
    }, {
      responseType: 'blob',
    });
    return response;
  };
  

  static printTraza = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/print/traza`,{
      responseType: 'blob',
    });
    return response;
  };

  static outOrderSimulation = async (id)=>{
    const response = await service.get(`${PATH.outOrders}/${id}/simulate-confirm`);
    return response;
  }

  static outOrderGenerateInvoice = async (id)=>{
    const response = await service.get(`${PATH.outOrders}/${id}/generate-invoice`);
    return response;
  }

  static getNotes = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/notes`)
    return response
  }
  static insertNotes = async (id, params) => {
    const response = await service.post(`${PATH.outOrders}/${id}/notes`, params)
    return response
  }

  static getSimulateCancel = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/simulate-cancel`)
    return response
  }
  static cancelOrder = async (id, params) => {
    const response = await service.post(`${PATH.outOrders}/${id}/cancel`, params)
    return response
  }
  static quitarSerialDelPicking = async (id, params) => {
    const response = await service.post(`${PATH.outOrders}/${id}/detail/stock/${params.out_order_detail_stock_id}/remove`)
    return response
  }

  static getComprobantesAsociados = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/comprobantes-asociados`)
    return response
  }

  static getCoordinationStatus = async (params) => {
    const response = await service.get(`${PATH.outOrders}/coordination-status`, {
      params,
    })
    return response
  }
  
  static anmatValidate= async (data) => {
    const {out_order_id, ...rest} = data
    const response = await service.get(`${PATH.outOrders}/${out_order_id}/anmat/validate`, {});
    return response;
  };
  static anmatValidateV2= async (data) => {
    const {out_order_id, ...rest} = data
    const response = await service.get(`${PATH.outOrders}/${out_order_id}/anmat/validate/v2`, {});
    return response;
  };
  
  static cancelarTraza= async (data) => {
    const {out_order_id, ...rest} = data
    const response = await service.post(`${PATH.outOrders}/${out_order_id}/cancelar-traza`, {});
    return response;
  };
  
  static retrazar= async (data) => {
    const {out_order_id, ...rest} = data
    const response = await service.post(`${PATH.outOrders}/${out_order_id}/retrazar`, {});
    return response;
  };

  static getCodigosEventos= async (data) => {
    const {out_order_id, ...rest} = data
    const response = await service.get(`${PATH.outOrders}/${out_order_id}/anmat/codigo-evento`, {});
    return response;
  };

  static getFacturacion = async (params) => {
    const response = await service.get(`${PATH.outOrders}/facturacion`, {params})
    return response
  }

  static liberarReserva = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/liberar-reserva`)
    return response
  }

  static liberarReservaArticulo = async (id, detail_id, data) => {
    const response = await service.post(`${PATH.outOrders}/${id}/detail/${detail_id}/liberar-reserva`, data)
    return response
  }

  static obtenerEventosOrdenEgreso = async (id) => {
    const response = await service.get(`${PATH.outOrders}/${id}/eventos`)
    return response
  }
}