import { FC } from 'react'
import { Formik, Form } from 'formik'
import { Input, FormButton, SelectField } from 'components'
import { Button } from 'react-bootstrap'
import useDepots from 'hooks/Inventory/useDepots'

const request_type = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Farma' },
  { value: 2, label: 'Shop' }
]

const initialValues = {
  id_filter: "",
  u_negocio: null,
  space: "",
  owner: "",
  deposit: "",
  warehouse: "",
  temperature: ""
}

const Filter = ({ setFilter, queryMD, setQueryMD }) => {
  const { loading } = useDepots()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let query = '?'
        for (const key in values) {
          if (values[key] !== '' && values[key] !== null) { 
            if (key === 'request_type_id') {
              if (values[key] && values[key].value) {
                query += `${key}=${values[key].value}&`
              }
            } else {
              query += `${key}=${values[key]}&`
            }
          }
        }
        query = query.slice(0, -1)
        setFilter(query)
        setQueryMD({ ...queryMD, pageNumber: 1 })
      }}
    >
      {({ resetForm, submitForm, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-2">
              <Input id="id_filter" name="id_filter" disabled={loading} />
              <p className="text-muted">ID</p>
            </div>

            <div className="col-2">
              <SelectField marginTop={"-20px"} id="u_negocio" name="u_negocio" options={request_type} />
              <p className="text-muted">U. negocio</p>
            </div>

            <div className="col-4">
              <Input 
                id="space" 
                name="space" 
                value={values.space}
                onChange={e => setFieldValue("space", e.target.value)} 
                disabled={loading} 
              />
              <p className="text-muted">Espacio</p>
            </div>

            <div className="col-4">
              <Input id="owner" name="owner" disabled={loading} />
              <p className="text-muted">Dueño</p>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <Input id="deposit" name="deposit" disabled={loading} />
              <p className="text-muted">Depósito</p>
            </div>

            <div className="col-2">
              <Input id="warehouse" name="warehouse" disabled={loading} />
              <p className="text-muted">Almacén</p>
            </div>

            <div className="col-2">
              <Input id="temperature" name="temperature" disabled={loading} />
              <p className="text-muted">Temperatura</p>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                resetForm({ values: initialValues })
                submitForm()
              }}
              className="me-3"
              disabled={loading}
            >
              <i className="bi bi-zoom-out mb-1" />
              Limpiar
            </Button>

            <Button type="submit" variant="primary" disabled={loading}>
              <i className="bi bi-search mb-1" />
              {loading ? 'Cargando...' : 'Buscar'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter