import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { usePedidos } from 'hooks'

export const usePendientes = () => {
  const { loading, handleObtenerPendientesPedido } = usePedidos()

  const obtenerPendientesPedido = useCallback(async (params = {}) => {
    try {
      const response = await handleObtenerPendientesPedido(params)
      return response
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleObtenerPendientesPedido])

  return {
    loading,
    obtenerPendientesPedido,
  }
}