import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useTransactionalDocuments, useClickOutside, useReports } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, AgrupacionModal, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './TransactionalDocumentsColumns'
import routes from 'app/routing/routes'

export const label = (status) => {
  if (!status) {
    return ''
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "DEFERRED":
      return "Factura Diferida";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    case "DISCARDED":
      return "Descartada";
    case "CANCELED":
    case "ANULADO":
      return "Anulado";
    case "ERROR_PROCESAR_COMPROBANTE":
      return 'Error comprobante'
    default:
      return '';
  }
}

export const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "estado-amarillo";
    case "SUCCESS":
      return "estado-verde";
    case "ERROR_NEGOCIO_AFIP":
    case "ERROR_PROCESAR_COMPROBANTE":
      return "estado-rojo";
    case "DEFERRED":
      return "estado-celeste";
    case "CANCELED":
    case "DISCARDED":
    case "ANULADO":
      return "estado-naranja";
    default:
      return '';
  }
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const TransactionalDocumentsTab = () => {
  const { id } = useParams()

  // const { loading, handleGetTransactionalDocumentsReport } = useReports()
  const { loading, handleGetDocuments, handlePrintFactura, handlePrintFacturaSimplificada, handlePrintRemito } = useTransactionalDocuments()
  const [documents, setDocuments] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [modalAgrupacion, setModalAgrupacion] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [idsAgrupados, setIdsAgrupados] = useState([])
  const [esPedido, setEsPedido] = useState(true)

  const getDocuments = useCallback(async () => {
    try {
      let params = {
        sales_order_id: id
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await handleGetDocuments(params)
      const data = response.data.result.map(d => {
        return {
          ...d,
          label_color: labelColor(d.estado),
          label: label(d.estado)
        }
      })

      setDocuments(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setDocuments([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetDocuments, query])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })


  const availableActions = () => {
    let actions = [];
  
    if (currentRow?.cae) {
      actions.push({
        label: 'Descargar comprobante',
        icon: 'bi bi-download text-primary',
        action: () => printFactura()
      });
    }
  
    if (
      currentRow?.tipo_comprobante_codigo_interno !== 4 &&
      currentRow?.estado?.toUpperCase() !== 'ERROR_NEGOCIO_AFIP' &&
      currentRow?.estado?.toUpperCase() !== 'DEFERRED' &&
      currentRow?.estado?.toUpperCase() !== 'ERROR_PROCESAR_COMPROBANTE'
    ) {
      if (currentRow?.texto_libre != null && currentRow?.texto_libre !== '') {
        actions.push({
          label: 'Descargar comprobante simplificado',
          icon: 'bi bi-download text-primary',
          action: () => printFacturaSimplificada()
        });
      }
    }
  
    if (currentRow?.tipo_comprobante_codigo_interno == 4) {
      actions.push({
        label: 'Descargar remito',
        icon: 'bi bi-download text-primary',
        action: printWaybill
      });
    }
  
    if (actions.length === 0) {
      actions.push({
        label: 'Sin opciones disponibles',
        icon: 'bi bi-dash-circle text-muted',
        action: () => console.log('No hay acciones disponibles.')
      });
    }
  
    return actions;
  };
  
 
  

  const printWaybill = async () => {
    try {
      let copias = 2
      const response = await handlePrintRemito(currentRow.id_real, copias)

      if (response.status === 200) {
        toast.success('¡Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${currentRow.id_real}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el remito')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(currentRow?.tipo_comprobante_codigo_interno, currentRow?.id_real)

      if (response.status === 200) {
        toast.success('¡Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `comprobante-${currentRow.id_real}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFacturaSimplificada = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintFacturaSimplificada(currentRow?.tipo_comprobante_codigo_interno, currentRow?.id_real)

        if (response.status === 200) {
          toast.success('¡Comprobante simplificado descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `comprobante-${currentRow.id_real}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el comprobante simplificado')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  /* Menu contextual */

  const columns = useMemo(() => [
    {
      Header: <p className="m-0 p-0 text-center">IDs O. egreso</p>,
      id: 'ids_pedidos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.out_order_ids && row.out_order_ids.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  setModalTitle('Órdenes de egreso')
                  setIdsAgrupados(row.out_order_ids.split(','))
                  setEsPedido(false)
                  setModalAgrupacion(true)
                }}
              >
                Órdenes agrupadas
              </span>
            : <Tooltip text='Ir a OE'><Link to={`${routes.OUT_ORDERS}/${row.out_order_ids}`}>{row.out_order_ids}</Link></Tooltip>
          }
        </p>
      ),
      hasOrder: true,
    },
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getDocuments()
  }, [getDocuments])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer>
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={documents} queryMD={query} setQueryMD={setQuery} paginationOptions={paginationOptions} />
      </TableContainer>

      <AgrupacionModal show={modalAgrupacion} onClose={() => setModalAgrupacion(false)} title={modalTitle} ids={idsAgrupados} pedido={esPedido} />

      <ContextMenu
        ref={contextRef}
        currentPos={currentPos}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
      />
    </>
  )
}

export default TransactionalDocumentsTab