import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment'

import { useAuth } from 'app/modules/auth';
import { useReports } from 'hooks'

import { Input, SelectField } from 'components'

import { labelColor } from './';
import { tipoOrden } from './columns';

const ESTADOS_ORDEN = Object.freeze({
  // GENERADO: 1,
  // PENDIENTE_INGRESO: 2,
  // INGRESO_PARCIAL: 3,
  // VENCIDO: 4,
  // COMPLETO: 6,
  BORRADOR: 7,
  CANCELADO: 5,
  // AUTORIZADO: 8,
  // CONFIRMADO: 9,
  // COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  // RECHAZADO: 12,
  ERROR_TRAZA: 13
})

const order_statuses = [
  // { value: ESTADOS_ORDEN.GENERADO, name: 'GENERADO', label: 'Pendiente de autorización' },
  // { value: ESTADOS_ORDEN.PENDIENTE_INGRESO, name: 'PENDIENTE_INGRESO', label: 'Pendiente de ingreso' },
  // { value: ESTADOS_ORDEN.INGRESO_PARCIAL, name: 'INGRESO_PARCIAL', label: 'Ingreso parcial' },
  // { value: ESTADOS_ORDEN.VENCIDO, name: 'VENCIDO', label: 'Vencido' },
  // { value: ESTADOS_ORDEN.COMPLETO, name: 'COMPLETO', label: 'Completo' },
  { value: ESTADOS_ORDEN.BORRADOR, name: 'BORRADOR', label: 'Borrador' },
  { value: ESTADOS_ORDEN.CANCELADO, name: 'CANCELADO', label: 'Cancelado' },
  // { value: ESTADOS_ORDEN.AUTORIZADO, name: 'AUTORIZADO', label: 'Autorizado' },
  // { value: ESTADOS_ORDEN.CONFIRMADO, name: 'CONFIRMADO', label: 'Confirmado' },
  // { value: ESTADOS_ORDEN.COMPLETO_PARCIAL, name: 'COMPLETO_PARCIAL', label: 'Completo parcial' },
  { value: ESTADOS_ORDEN.INGRESADO, name: 'INGRESADO', label: 'Ingresado' },
  // { value: ESTADOS_ORDEN.RECHAZADO, name: 'RECHAZADO', label: 'Rechazado' },
  { value: ESTADOS_ORDEN.ERROR_TRAZA, name: 'ERROR_TRAZA', label: 'Error traza' },
];

const initialValues = {
  id_orden_ingreso: '',
  fecha_desde: moment().subtract(1, 'M').format('YYYY-MM-DD'),
  fecha_hasta: moment().format('YYYY-MM-DD'),
  id_pedido_compra: '',
  id_estado_ingreso: [ESTADOS_ORDEN.INGRESADO],
  proveedor: '',
  proveedor_documento: '',
  distribuidor: '',
  distribuidor_documento: '',
  articulo: '',
  lote: '',
}

const Filter = ({ queryMD, setQueryMD }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetIncomesReport } = useReports()

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_orden_ingreso: values?.id_orden_ingreso || undefined,
      fecha_desde: values?.fecha_desde || undefined,
      fecha_hasta: values?.fecha_hasta || undefined,
      id_pedido_compra: values?.id_pedido_compra || undefined,
      id_estado_ingreso: values?.id_estado_ingreso || undefined,
      proveedor: values?.proveedor || undefined,
      proveedor_documento: values?.proveedor_documento || undefined,
      distribuidor: values?.distribuidor || undefined,
      distribuidor_documento: values?.distribuidor_documento || undefined,
      articulo: values?.articulo || undefined,
      lote: values?.lote || undefined,
    }

    return params
  }

  const handleSubmit = (values) => {
    const params = {
      ...filterData(values),
      pageNumber: 1,
      pageSize: 10,
    }

    setCurrentUser((prevCurrentUser) => ({
      ...prevCurrentUser,
      reporte_ingresos: { ...params }
    }))

    setQueryMD({ ...params })
  }

  const exportData = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: undefined,
      }

      const response = await handleGetIncomesReport(params)

      if (response) {
        const datos = response.result.map(d => {
          let data = {
            ...d,
            tipo_orden: tipoOrden(d.tipo_orden),
            'fecha_cancelacion': d?.fecha_cancelacion ? moment(d.fecha_cancelacion).format('DD/MM/YYYY') : '',
            'fecha_de_ingreso': moment(d.fecha_de_ingreso).format('DD/MM/YYYY'),
            'vencimiento': d?.vencimiento ? moment(d.vencimiento).format('DD/MM/YYYY') : '',
          }

          delete data?.id_estado

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Ingresos")
        XLSX.writeFile(wb, `Ingresos ${moment().format("YYYYMMDD-HHmm")}.xlsx` )
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { reporte_ingresos } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id_orden_ingreso: reporte_ingresos?.id_orden_ingreso || '',
        // fecha_desde: reporte_ingresos?.fecha_desde || '',
        // fecha_hasta: reporte_ingresos?.fecha_hasta || '',
        fecha_desde: reporte_ingresos?.fecha_desde || moment().subtract(1, 'M').format('YYYY-MM-DD'),
        fecha_hasta: reporte_ingresos?.fecha_hasta || moment().format('YYYY-MM-DD'),
        id_pedido_compra: reporte_ingresos?.id_pedido_compra || '',
        id_estado_ingreso: reporte_ingresos?.id_estado_ingreso || [ESTADOS_ORDEN.INGRESADO],
        proveedor: reporte_ingresos?.proveedor || '',
        proveedor_documento: reporte_ingresos?.proveedor_documento || '',
        distribuidor: reporte_ingresos?.distribuidor || '',
        distribuidor_documento: reporte_ingresos?.distribuidor_documento || '',
        articulo: reporte_ingresos?.articulo || '',
        lote: reporte_ingresos?.lote || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_orden_ingreso' name='id_orden_ingreso' disabled={loading} />
              <p className='text-muted'>Orden Ingreso</p>
            </div>

            <div className='col-2'>
              <Input id='fecha_desde' name='fecha_desde' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. ingreso</p>
            </div>

            <div className='col-2'>
              <Input id='fecha_hasta' name='fecha_hasta' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. ingreso</p>
            </div>

            <div className='col-2'>
              <Input id='id_pedido_compra' name='id_pedido_compra' disabled={loading} />
              <p className='text-muted'>Pedido Compra</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='id_estado_ingreso'
                name='id_estado_ingreso'
                options={order_statuses}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='proveedor' name='proveedor' disabled={loading} />
              <p className='text-muted'>ID / Proveedor</p>
            </div>

            <div className='col-2'>
              <Input id='proveedor_documento' name='proveedor_documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>

            <div className='col-4'>
              <Input id='distribuidor' name='distribuidor' disabled={loading} />
              <p className='text-muted'>ID / Distribuidor</p>
            </div>

            <div className='col-2'>
              <Input id='distribuidor_documento' name='distribuidor_documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='articulo' name='articulo' disabled={loading} />
              <p className='text-muted'>ID / Artículo / Droga / GTIN</p>
            </div>

            <div className='col-3'>
              <Input id='lote' name='lote' disabled={loading} />
              <p className='text-muted'>Lote</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => exportData(values)}
                disabled={loading}
              >
                <i className="bi bi-file-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button variant="primary" type='submit' disabled={loading}>
              <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>


        </Form>
      )}
    </Formik>
  )
}

export default Filter