import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useRequest } from 'hooks'
import { Input, SelectField, SwitchField } from 'components'
import { labelColor } from './'

const order_type = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Pedido de Compra' },
  { value: 2, label: 'Pedido de Ingreso' },
  { value: 5, label: 'Pedido de Devolución' },
]

const order_statuses = [
  { value: 1, name: 'GENERADO', label: 'Pendiente de autorización' },
  { value: 2, name: 'PENDIENTE_INGRESO', label:'Pendiente de ingreso' },
  { value: 6, name: 'COMPLETO', label:'Completo' },
  { value: 7, name: 'BORRADOR', label:'Borrador' },
  { value: 10, name: 'COMPLETO_PARCIAL', label:'Completo parcial' },
]

const initialValues = {
  id_filter: '',
  // order_type_id: 5,
  order_type_id_filter: [5],
  order_status_id_filter: [],
  date_from: '',
  date_to: '',
  proveedor: '',
  proveedor_documento: '',
  distribuidor: '',
  distribuidor_documento: '',
  estimated_in_date_from: '',
  estimated_in_date_to: '',
  in_date_from: '',
  in_date_to: '',
  article_text: '',
  non_compliant_in_requests: 0,
}

const Filter = ({ setData, queryMD, setQueryMD, setTotalCount, exportData }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues)

  const { loading, handleGetRequestsV2 } = useRequest()

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_filter: values?.id_filter || undefined,
      // order_type_id: values?.order_type_id || undefined,
      order_type_id_filter: values?.order_type_id_filter || undefined,
      order_status_id_filter: values?.order_status_id_filter || undefined,
      date_from: values?.date_from || undefined,
      date_to: values?.date_to || undefined,
      proveedor: values?.proveedor || undefined,
      proveedor_documento: values?.proveedor_documento || undefined,
      estimated_in_date_from: values?.estimated_in_date_from || undefined,
      estimated_in_date_to: values?.estimated_in_date_to || undefined,
      distribuidor: values?.distribuidor || undefined,
      distribuidor_documento: values?.distribuidor_documento || undefined,
      in_date_from: values?.in_date_from || undefined,
      in_date_to: values?.in_date_to || undefined,
      article_text: values?.article_text || undefined,
      non_compliant_in_requests: values?.non_compliant_in_requests || undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    return params
  }

  const handleSubmit = async (values) => {
    const params = filterData(values)

    try {
      const response = await handleGetRequestsV2(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.order_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        in_requests: { ...params },
      }))

      setQueryMD({ ...params })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { in_requests } = currentUser

      setFormikInitialValues({
        ...initialValues,
        id_filter: in_requests?.id_filter || '',
        order_type_id_filter: in_requests?.order_type_id_filter || [5],
        order_status_id_filter: in_requests?.order_status_id_filter || [],
        date_from: in_requests?.date_from || '',
        date_to: in_requests?.date_to || '',
        proveedor: in_requests?.proveedor || '',
        proveedor_documento: in_requests?.proveedor_documento || '',
        estimated_in_date_from: in_requests?.estimated_in_date_from || '',
        estimated_in_date_to: in_requests?.estimated_in_date_to || '',
        distribuidor: in_requests?.distribuidor || '',
        distribuidor_documento: in_requests?.distribuidor_documento || '',
        in_date_from: in_requests?.in_date_from || '',
        in_date_to: in_requests?.in_date_to || '',
        article_text: in_requests?.article_text || '',
        non_compliant_in_requests: in_requests?.non_compliant_in_requests || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_filter' name='id_filter' disabled={loading} />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='order_type_id_filter'
                name='order_type_id_filter'
                options={order_type}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Tipo</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='order_status_id_filter'
                name='order_status_id_filter'
                options={order_statuses}
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. creación</p>
            </div>
          </div>

          {/* <div className='row'>
            <div className='col-6'>
              <Input id='proveedor' name='proveedor' disabled={loading} />
              <p className='text-muted'>ID / Proveedor</p>
            </div>

            <div className='col-2'>
              <Input id='proveedor_documento' name='proveedor_documento' disabled={loading} />
              <p className='text-muted'>Documento Proveedor</p>
            </div>

            <div className='col-2'>
              <Input id='estimated_in_date_from' name='estimated_in_date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. ingreso est.</p>
            </div>

            <div className='col-2'>
              <Input id='estimated_in_date_to' name='estimated_in_date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. ingreso est.</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='distribuidor' name='distribuidor' disabled={loading} />
              <p className='text-muted'>ID / Distribuidor</p>
            </div>

            <div className='col-2'>
              <Input id='distribuidor_documento' name='distribuidor_documento' disabled={loading} />
              <p className='text-muted'>Documento Distribuidor</p>
            </div>

            <div className='col-2'>
              <Input id='in_date_from' name='in_date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. ingreso</p>
            </div>

            <div className='col-2'>
              <Input id='in_date_to' name='in_date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. ingreso</p>
            </div>
          </div> */}

          <div className='row'>
            <div className='col-5'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>ID / Artículo / Droga</p>
            </div>

          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()} className="me-3"
                disabled={loading}
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter