import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import moment from 'moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { AssignmentOutlined, Inventory2Outlined, InputOutlined } from '@mui/icons-material'
import 'react-toastify/dist/ReactToastify.css';
import {helix} from 'ldrs'

import { useRequest, useGetRequestDetail } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { PurchaseHistoryDialog } from '../List/PurchaseHistoryDialog'
import GeneralTab from './partials/general/GeneralTab'
import ArticlesTab from './partials/articles/ArticlesTab'
import IncomesTab from './partials/incomes/IncomesTab'

import { formikInitialValues, incomeSchema, purchaseSchema } from './constants'
import { PurchaseRequestStatusI } from '../constants'

import routes from 'app/routing/routes'
import { labelColor } from '../List';
import { useAuth } from 'app/modules/auth';
import { checkIsAllowed } from '_metronic/helpers';
import { OrderTypeI, RoleI } from 'interfaces';

const RequestDetail = ({ income = false }) => {
  const { id } = useParams()
  const { state } = useLocation()
  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [initialTab, setInitialTab] = useState('general')
  const [editEnable, setEditEnable] = useState(false)
  const {request, refreshRequest} = useGetRequestDetail(id)
  const {
    loading,
    handleCreateRequestDetail,
    handleDeleteRequestDetail,
    handleUpdateRequest,
    handleUpdateRequestDetail,
    handlePublishRequest,
    handleAuthorizeRequest,
    handleRejectRequest,
    handleCancelRequest,
    handlePrintRequest,
    handleCheckInRequestPVP   
  } = useRequest()
  const [showHistoryModal, setShowHistoryModal] = useState(false)

  const { auth, currentUser } = useAuth()

  const navigate = useNavigate()
  const tabRefered = state ? state.tab : 'general'

  const referer = state ? state.referer : 'pedidos'

  const tabsContent =  [
    <GeneralTab income={income} editEnable={editEnable} loading={loading} />,
    <ArticlesTab income={income} editEnable={editEnable} setInitialTab={setInitialTab} loading={loading}  />,
    <IncomesTab income={income} setInitialTab={setInitialTab} loading={loading}  />,
  ]

  const addArticle = async (data) => {
    try{
      const transport = { ...data, request_id: id }
      await handleCreateRequestDetail(transport)

      toast.success("Artículo guardado correctamente", { theme: "colored" })
      await refreshRequest()

    } catch (err) {
      toast.error(err.message, { theme: "colored" })
      console.log(err)
    }
  }

  const deleteArticle = async (art) => {
    try{
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Confirma la eliminación del detalle #${art.id} del pedido?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, quitar del pedido",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const resp = await handleDeleteRequestDetail(art)
        toast.success("Artículo quitado del pedido", { theme: "colored" })
        refreshRequest()
      }
    } catch (err) {
      toast.error(err.message, {theme: "colored"})
    }
  }

  const updateArticle = async (data) => {
    try{
      const transport = { data }
      const resp = await handleUpdateRequestDetail(transport)
      toast.success("Registro actualizado")
      refreshRequest()
    } catch (err) {
      toast.error(err.message)
    }
  }

  const handleSubmit = async (values) => {

      const {
        id,
        addArticle,
        deleteArticle,
        updateArticle,
        order_status_label,
        order_status_id,
        revocation_notes,
        revocation_user_name,
        revocation_date,
        con_distribuidor,
        distributor_id_aux,
        ...rest
      } = values

      rest.distributor_id = distributor_id_aux > 0 ? distributor_id_aux : null;
      rest.payment_condition_id = request.order_type_id==OrderTypeI.PEDIDO_INGRESO ? null :  rest.payment_condition_id;

      const modDetail = rest.detail.map(art => {
        const { id, in_request_id, product_var_id, ...resto } = art

        return { ...resto, detail_id: id, var_id: product_var_id }
      });

      const updData = { ...rest, detail: modDetail }


      try {

        if (order_status_id === 5 || order_status_id === 12) { // cancelado o rechazado
            const msg = `El pedido se encuentra en estado ${order_status_id === 5 ? 'Cancelado' : 'Rechazado'}, pasará a estado 'Borrador', ¿confirma?`

            const resp = await Swal.fire({
              title: "Advertencia",
              text: msg,
              icon: "warning",
              showDenyButton: true,
              denyButtonText: "No, cancelar",
              confirmButtonText: "Si, guardar",
              customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
              reverseButtons: true
            })

            if (resp.isConfirmed) {
              await handleUpdateRequest({ in_request_id: id, ...updData })
              toast.success("El pedido se guardó correctamente", { theme: "colored" })
              navigate(`${routes.PURCHASE_REQUESTS}`)
            }
        }

        if (order_status_id === 7) { // borrador
            await handleUpdateRequest({ in_request_id: id, ...updData })

            toast.success("El pedido se guardó correctamente", { theme: "colored" })
            navigate(`${routes.PURCHASE_REQUESTS}`)
        }
      } catch (err) {
          toast.error(err.message, { theme: "colored" })
      }
  }

  const publishRequest = async () => {
    try {
      const response = await handleCheckInRequestPVP(id)

      let text = "¿Enviar el pedido a autorizar? A partir de ese momento, no se podrán realizar modificaciones"
      if (response.data.result.length > 0) {
        let qty = response.data.result.length
        text = `El pedido #${id} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere enviarlo a autorizar?`;
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar a autorizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handlePublishRequest(id)
        toast.success("El pedido se envió a autorizar", { theme: "colored" })
      }
      refreshRequest()
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const actionPrint = async () => {
    try {
      const response = await handlePrintRequest(id)

      if (response.status === 200) {
        toast.success('Pedido de compra descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `pedido-compra-${id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el PDF')
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const actionViewEvents = () => {
    setShowHistoryModal(true)
  }
  const actionAuthorize = async () => {

      try {

        const response = await handleCheckInRequestPVP(id)

        let text = `¿Confirma el envío a operaciones del pedido #${id}?`

        // if (response.data.result.length > 0) {
        //   let qty = response.data.result.length
        //   text = `El pedido #${id} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere autorizarlo?`;
        // }

        const resp = await Swal.fire({
          title: "Advertencia",
          text: text,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, enviar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const resp = await handleAuthorizeRequest({ id })
          toast.success("Pedido enviado correctamente", { theme: "colored" })
        }
        refreshRequest()
        
      } catch(err) {
        toast.error(err.message, { theme: "colored" })
      }
  }
  const onCloseHistoryModal = (data) => {
    setShowHistoryModal(false)
  }

  
  const availableActions = () => {
    let actions = []

    if (request) {
      if (request.order_status_id === PurchaseRequestStatusI.BORRADOR && request.detail && request.detail.length>0) {
        if(request.order_type_id==OrderTypeI.PEDIDO_COMPRA){
          actions.push({
            label: "Enviar a autorizar",
            icon: "bi bi-box-arrow-in-down-right text-success",
            action: publishRequest
          })        
        }
        else if (request.order_type_id==OrderTypeI.PEDIDO_INGRESO){
          actions.push({
            label: "Enviar a operaciones",
            icon: "bi bi-clipboard-check text-success",
            action: actionAuthorize
          })
        }
      }

      if (request.order_type_id==OrderTypeI.PEDIDO_COMPRA &&
        request.order_status_id == PurchaseRequestStatusI.GENERADO &&
        request.detail &&
        request.detail.length > 0 &&
        currentUser.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.CONTROL_COMPRAS || r.id == RoleI.CONTROL_RENTAS)
      ) {
          actions.push({
            label: "Autorizar",
            icon: "bi bi-clipboard-check text-success",
            action: actionAuthorize
          })
      }
      if(request.order_type_id==OrderTypeI.PEDIDO_COMPRA){
        actions.push({
          label: "Imprimir",
          icon: "bi bi-printer text-primary",
          action: actionPrint
        })
      }
      actions.push({
        label: "Eventos de la Orden",
        icon: "bi bi-clock-history text-primary",
        action: actionViewEvents
      })
    }

    return actions
  }

  useEffect(() => {
    if (request) {
      const {
        id,
        order_status_label,
        order_status_name,
        order_status_id,
        supplier_id,
        distributor_id,
        business_unit_id,
        payment_condition_id,
        issue_date,
        estimated_in_date,
        notes,
        internal_notes,
        order_type_id,
        revocation_user_name,
        revocation_date,
        revocation_notes,
        detail,
        detail_grouped,
      } = request;
      /* En el detail, lo que puede venir en null, lo reemplaza por "" para que no tire error de react controlled vs uncontrolled */

      const det = detail
        .map(art => {
          const ob = {
            ...art,
            vat: art.vat ? art.vat : "",
            vat_rate: art.vat_rate ? art.vat_rate : ""
          }

          return ob;
      })

      if (request.order_status_id === 7 || request.order_status_id === 5 || request.order_status_id === 12) { // borrador, cancelado o rechazado
        setEditEnable(true)
      } else {
        setEditEnable(false)
      }

      const _initialValues = {
        id,
        order_status_label,
        order_status_name,
        order_status_id,
        supplier_id,
        distributor_id,
        distributor_id_aux:-1,
        business_unit_id,
        payment_condition_id: payment_condition_id || '',
        issue_date: issue_date ? moment(issue_date).format("YYYY-MM-DD") : "",
        estimated_in_date: estimated_in_date ? moment(estimated_in_date).format("YYYY-MM-DD") : "",
        notes,
        internal_notes,
        order_type_id,
        revocation_user_name,
        revocation_date,
        revocation_notes,
        detail: det,
        detail_grouped: detail_grouped,
        addArticle,
        deleteArticle,
        updateArticle,
        con_distribuidor: (distributor_id && distributor_id>0)? true:false
      }

      setInitialValues(_initialValues)
    }

  }, [request])

    
  useEffect(()=>{
    helix.register()
  }, [])


  return (
    <>
      <PurchaseHistoryDialog show={showHistoryModal} onClose={onCloseHistoryModal} currentRow={request} />

      <Formik
        initialValues={initialValues}
        validationSchema={income ? incomeSchema : purchaseSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <Form className="" >
            <DetailContainer
              statusV2={
                <>
                  <div className={`badge ${labelColor(values?.order_status_id)} justify-content-center ms-5 fw-normal`} style={{ width: "150px" }}>
                    {values.order_status_label}
                  </div>
                </>
              }
              title={income ? 'Pedido de ingreso #' + (id || '') : 'Pedido de compra #' + (id || '')}
              buttons={
                <div className='row text-end mt-5'>
                  <div className='col'>
                    <Button type='button' variant="secondary" onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
                  </div>

                  {values.order_status_id > 0 &&
                    (referer === 'pedidos' || referer === 'autorizaciones') &&
                      <div className='col'>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            Acciones
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ width:"200px" }}>
                            {availableActions().map((data, index) => (
                              <Dropdown.Item
                                disabled={loading}
                                key={index}
                                onClick={() => { data.action() }}
                              >
                                <i className={`${data.icon} pe-3`}/>
                                {data.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }
                </div>
              }
            >
              
            { loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", marginTop:"100px", left: "50%"}}></l-helix>}
      
              <TabsContainer
                initialValue={tabRefered || 'general'}
                tabs={[
                  { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true, disabled: !values?.order_status_id },
                  { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true, disabled: !values?.order_status_id },
                  { value: 'incomes', label: "Ingresos", icon: <InputOutlined />, show: true, disabled: !values?.order_status_id && (values.order_status_id != PurchaseRequestStatusI.COMPLETO || values.order_status_id != PurchaseRequestStatusI.COMPLETO_PARCIAL) }
                ]}
                tabsContent={tabsContent}
              />
            </DetailContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RequestDetail